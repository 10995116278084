import { IDataTableHeader } from '@/components'
import { ILockedLocation } from '@/types'

export const headers: IDataTableHeader<ILockedLocation>[] = [
  {
    key: 'location',
    label: 'Location name',
    sortable: false,
  },
  {
    key: 'crm',
    label: 'CRM',
    sortable: false,
  },
  {
    key: 'lockedBy',
    label: 'Locked by',
    sortable: false,
  },
  {
    key: 'reason',
    label: 'Reason',
    sortable: false,
  },
  {
    key: 'assignedUsers',
    label: 'Assigned users',
    sortable: false,
  },
  {
    key: 'lockedAt',
    label: 'Locket At',
    sortable: false,
  },
]
