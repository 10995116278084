import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import classNames from 'classnames'

import { useAccessControl, useHookForm } from '@/hooks'
import { Button, Form, TextInput } from '@/components'
import { TGrantAccessSchema } from '@/types'
import { grantAccessSchema } from '@/utils'

export const GrantAccess: React.FC = () => {
  const { setGrantAccess } = useAccessControl()
  const {
    handler: {
      reset,
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(grantAccessSchema)

  const onSubmit: SubmitHandler<TGrantAccessSchema> = useCallback(
    data => {
      setGrantAccess(data.email)
      reset()
    },
    [reset, setGrantAccess],
  )

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('grant-access', { error: !!errors.email?.message })}
    >
      <TextInput {...register('email')} />
      <Button className="btn-primary max-w-max">Grant Access</Button>
    </Form>
  )
}
