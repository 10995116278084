import React, { useCallback } from 'react'
import moment from 'moment'

import { Button, DatePicker, Select, TextInput } from '@/components'
import { IAIBotCustomerFilter, IFilterProps } from '@/types'
import { useDashboard } from '@/hooks'

export const Filters: React.FC<
  IFilterProps<IAIBotCustomerFilter> & { hideOrganization?: boolean }
> = ({ onReset, onUpdate, filters, hideOrganization = false }) => {
  const { organizations } = useDashboard()

  const onSelectValue = useCallback(
    (field: keyof IAIBotCustomerFilter) => (value: any) => {
      let _value = value
      if (['startDate', 'endDate'].includes(field) && _value) {
        _value = moment(_value).toISOString()
        if (field === 'startDate') {
          const el = document.querySelectorAll(
            '.popover-trigger',
          )[1] as HTMLElement
          el?.click()
        }
      }
      onUpdate({ [field]: _value })
    },
    [onUpdate],
  )

  return (
    <div className="flex flex-col xl:flex-row gap-x-8 gap-y-4 py-5 items-end justify-between">
      <div className="grid w-full gap-5 items-center md:grid-cols-4 grid-cols-1 sm:grid-cols-2">
        <DatePicker
          className="w-full"
          label="Start Date"
          placeholder="Enter start date"
          onChange={onSelectValue('startDate')}
          maxDate={filters?.endDate ? new Date(filters?.endDate) : undefined}
          value={filters?.startDate ? new Date(filters?.startDate) : undefined}
        />
        <DatePicker
          className="w-full"
          label="End Date"
          placeholder="Enter end date"
          onChange={onSelectValue('endDate')}
          value={filters?.endDate ? new Date(filters?.endDate) : undefined}
          minDate={
            filters?.startDate ? new Date(filters?.startDate) : undefined
          }
        />
        {!hideOrganization && (
          <Select
            className="w-full"
            label="Organizations"
            value={filters.organization}
            placeHolder="Select Organization..."
            onChange={value => onSelectValue('organization')(value.value)}
            options={organizations.map(_v => ({
              value: _v._id,
              label: _v.name,
            }))}
          />
        )}
        <TextInput
          label="Phone"
          className="w-full"
          value={filters.phoneNumber}
          placeholder="Enter phone number"
          onChange={e => onSelectValue('phoneNumber')(e.target.value)}
        />
      </div>
      <div className="lg:min-w-max">
        <Button className="btn-primary !py-[9px]" onClick={onReset}>
          Reset filters
        </Button>
      </div>
    </div>
  )
}
