import React, { useCallback } from 'react'
import { capitalize } from 'lodash'
import moment from 'moment'

import { Button, DatePicker, Select, TextInput } from '@/components'
import { IFilterProps, ILeadsFilter } from '@/types'
import { useDashboard } from '@/hooks'

export const Filters: React.FC<IFilterProps<ILeadsFilter>> = ({
  filters,
  onReset,
  onUpdate,
}) => {
  const { crms } = useDashboard()

  const onSelectValue = useCallback(
    (field: keyof ILeadsFilter) => (value: any) => {
      let _value = value
      if (['date'].includes(field) && _value) {
        _value = moment(_value).toISOString()
      }
      onUpdate({ [field]: _value })
    },
    [onUpdate],
  )

  return (
    <div className="flex flex-col xl:flex-row gap-x-8 gap-y-4 py-5 items-end justify-between">
      <div className="grid w-full gap-5 items-center md:grid-cols-4 grid-cols-1 sm:grid-cols-2">
        <DatePicker
          className="w-full"
          label="Start Date"
          placeholder="Enter start date"
          onChange={onSelectValue('date')}
          value={filters?.date ? new Date(filters?.date) : undefined}
        />
        <Select
          label="CRMs"
          className="w-full"
          value={filters.crm}
          placeHolder="Select CRM..."
          options={crms.map(_v => ({
            value: _v._id,
            label: capitalize(_v.name),
          }))}
          onChange={value => onSelectValue('crm')(value.value)}
        />
        <TextInput
          label="Phone"
          className="w-full"
          value={filters.phoneNumber}
          placeholder="Enter phone number"
          onChange={e => onSelectValue('phoneNumber')(e.target.value)}
        />
        <TextInput
          label="Name"
          className="w-full"
          placeholder="Enter name"
          onChange={e => onSelectValue('name')(e.target.value)}
        />
      </div>
      <div className="lg:min-w-max">
        <Button className="btn-primary !py-[9px]" onClick={onReset}>
          Reset filters
        </Button>
      </div>
    </div>
  )
}
