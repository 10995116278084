import React, { useCallback, useMemo } from 'react'

import { Accordion, Button, LoadingSkeleton, WithSkeleton } from '@/components'
import { useAuth, useDashboard, useLocation, useOrganization } from '@/hooks'
import { ILocation } from '@/types'

import { AssignUsersModal, Credentials } from './'
import { useParams, useSearchParams } from 'react-router-dom'

export const SettingsTab: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { accesses, userPermissions } = useAuth()
  const { id } = useParams()
  const [modalOpen, setModalOpen] = React.useState(false)
  const { locations } = useDashboard()
  const { organization } = useOrganization()
  const { lockedLocations } = useLocation()
  const hasAccess = useMemo(() => {
    if (accesses.ADMIN_WRITE || accesses.ADMIN_READ) return true
    if (
      !(
        (userPermissions?._organization_id === id ||
          userPermissions?.organizations.find(org => org._id === id)) &&
        (accesses.AUTOMATIONS_READ || accesses.AUTOMATIONS_WRITE)
      )
    ) {
      return false
    }
    return true
  }, [accesses, id, userPermissions])

  const extractLocation = useCallback(
    (locationId: string): ILocation => {
      const location = locations.find(_v => _v._id === locationId)
      return {
        _id: locationId,
        name: location?.name ?? '',
      }
    },
    [locations],
  )

  const activeIndex = useMemo(() => {
    const activeLocation = searchParams.get('location')
    return organization?.locations.findIndex(_v => _v._id === activeLocation)
  }, [organization, searchParams])

  const checkIsLocked = useCallback(
    (locationId: string) => {
      return !!lockedLocations.find(_v => _v.location._id === locationId)
    },
    [lockedLocations],
  )

  return (
    <div className="tab-content">
      <h3 className="font-bold text-3xl mb-5 flex justify-between">
        Settings{' '}
        {hasAccess && (
          <Button
            className="btn btn-primary max-w-max"
            onClick={() => setModalOpen(true)}
          >
            Assign users
          </Button>
        )}
      </h3>
      <h6 className="font-semibold text-2xl mb-2">Locations</h6>
      <Accordion
        reset={organization?._id}
        multiselect={false}
        defaultOpen={activeIndex}
        items={
          organization?.locations?.map(_location => {
            const isLocked = checkIsLocked(_location._id)
            return {
              header: (
                <WithSkeleton
                  loading={!locations.length}
                  skeleton={<LoadingSkeleton className="h-4" />}
                >
                  <span>
                    {extractLocation(_location._id)?.name}{' '}
                    {isLocked && (
                      <span className="ml-3 inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                        Locked
                      </span>
                    )}
                  </span>
                </WithSkeleton>
              ),
              body: (
                <Credentials
                  location={extractLocation(_location._id)}
                  credentials={organization?.credentials || []}
                  isLocked={isLocked}
                />
              ),
            }
          }) ?? []
        }
      />
      <AssignUsersModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  )
}
