import React, { useMemo } from 'react'
import { DayPicker } from 'react-day-picker'
import classNames from 'classnames'

import { formatDateTime, isDisableDate } from '@/utils'
import { FormError, Popover } from '../'

import { PickerClasses } from './constants'
import { IDatePickerProps } from './types'
import './styles.scss'

export const DatePicker: React.FC<IDatePickerProps> = ({
  label,
  className,
  placeholder,
  placement = 'bottom-center',
  positioning,
  value,
  maxDate,
  minDate,
  disabled,
  required,
  error,
  disabledDates,
  format = 'D MMM YYYY',
  onChange,
  ...rest
}) => {
  const [displayLabel, isPlaceholder]: [string | undefined, boolean] =
    useMemo(() => {
      if (!value) return [placeholder, true]

      return [formatDateTime(value, format || 'D MMM YYYY'), false]
    }, [format, placeholder, value])

  return (
    <div className={classNames('custom-date-picker', className)}>
      <Popover
        closeOnOutside
        className="date-picker-container"
        placement={placement}
        positioning={positioning}
        disabled={disabled}
        WrapEl="div"
        distance={10}
        button={
          <label
            className={classNames({
              error: !!error,
              disabled: !!disabled,
            })}
          >
            {!!label && (
              <span className="name">
                {label}
                {!!required && <sup> *</sup>}
              </span>
            )}
            <span className="field">
              <span className="fakeInput">{displayLabel}</span>
            </span>
            <FormError error={error} />
          </label>
        }
      >
        {onClose => (
          <div className="date-picker-panel">
            <div className="item-bg">
              <DayPicker
                {...rest}
                showOutsideDays
                mode="single"
                defaultMonth={value ?? new Date()}
                selected={value}
                onSelect={(val: Date | undefined) => {
                  onChange?.(val)
                  onClose()
                }}
                toDate={new Date()}
                numberOfMonths={1}
                disabled={(curDate: Date) =>
                  isDisableDate(curDate, disabledDates, maxDate, minDate)
                }
                className="custom-date-picker-container"
                modifiersClassNames={{ today: 'day-today' }}
                classNames={{ ...PickerClasses, ...classNames }}
              />
            </div>
          </div>
        )}
      </Popover>
    </div>
  )
}

export default DatePicker
