import React from 'react'
import Select, { GroupBase, Props } from 'react-select'
import clsx from 'clsx'
import { FormError } from '@/components'
import { IOption } from '@/types'

const controlStyles = {
  base: 'border border-gray-200 rounded bg-white hover:cursor-pointer',
  focus: 'border-blue-600',
  nonFocus: 'border-gray-200 hover:border-gray-300',
  disabled: 'bg-gray-100 cursor-not-allowed opacity-50',
}
const placeholderStyles = 'text-gray-500 pl-1 py-0.5'
const selectInputStyles = 'pl-1 py-0.5 h-8'
const valueContainerStyles = 'p-1 gap-2'
const singleValueStyles = 'leading-7 ml-1 text-sm'
const multiValueStyles =
  'bg-indigo-500 rounded items-center py-0.5 px-2 gap-1.5 text-sm'
const multiValueLabelStyles = 'leading-6 py-0.5 text-white'
const multiValueRemoveStyles =
  'border border-gray-100 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md'
const indicatorsContainerStyles = 'p-1 gap-1'
const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800'
const indicatorSeparatorStyles = 'bg-gray-300'
const dropdownIndicatorStyles =
  'p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black'
const menuStyles = 'p-1 mt-2 border border-gray-100 bg-white rounded-lg'
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm'
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded',
  focus: 'bg-indigo-100 active:bg-indigo-200',
  selected: 'text-white bg-indigo-500',
  disabled: 'bg-gray-100 cursor-not-allowed opacity-50',
}
const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm'
const SearchBar = <
  Option extends IOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  label,
  error,
  ...rest
}: Props<Option, IsMulti, Group> & { label?: string; error?: string }) => {
  return (
    <div className={className}>
      {label && <span className="name">{label}</span>}
      <Select
        unstyled
        styles={{
          input: base => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          // On mobile, the label will truncate automatically, so we want to
          // override that behaviour.
          multiValueLabel: base => ({
            ...base,
            whiteSpace: 'normal',
            overflow: 'visible',
          }),
          control: base => ({
            ...base,
            transition: 'none',
          }),
        }}
        classNames={{
          control: ({ isFocused, isDisabled }) =>
            clsx(
              isDisabled
                ? controlStyles.disabled
                : isFocused
                  ? controlStyles.focus
                  : controlStyles.nonFocus,
              controlStyles.base,
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected, isDisabled }) =>
            clsx(
              optionStyles.base,
              isSelected
                ? optionStyles.selected
                : isDisabled
                  ? optionStyles.disabled
                  : isFocused
                    ? optionStyles.focus
                    : '',
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        {...rest}
        isOptionDisabled={option => !!option.disabled}
      />
      {error && <FormError error={error} />}
    </div>
  )
}

export default SearchBar
