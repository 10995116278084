import * as yup from 'yup'
import { useCallback } from 'react'
export const loginFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
})

export const grantAccessSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const checkForDynamicFieldsValidity = (
  message: string,
  fields?: string[],
) => {
  const extractTags = (str: string) => {
    const regex = /{{(.*?)}}/g
    const matches = []
    let match
    while ((match = regex.exec(str)) !== null) {
      matches.push(match[1])
    }
    return matches
  }
  const tags = extractTags(message) || []

  for (const tag of tags) {
    if (!fields?.includes(tag)) {
      return false
    }
  }
  return true
}
