import React, { useEffect, useState } from 'react'

import * as OTPAuth from 'otpauth'

interface IOTPGenProps {
  otp_code: string
}

export const OTPGen: React.FC<IOTPGenProps> = ({ otp_code }) => {
  const [code, setCode] = useState<string>('')
  useEffect(() => {
    let intervalId: NodeJS.Timeout
    try {
      const get = () => {
        // Generate the TOTP
        const parsedTotp2 = OTPAuth.URI.parse(
          `otpauth://totp/matador.ai?secret=${otp_code}`,
        )
        setCode(parsedTotp2.generate())
      }

      intervalId = setInterval(get, 1000)
      get()
    } catch (err) {
      console.log(err)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [otp_code])

  return (
    <p className="font-medium text-sm mt-2">
      OTP Code Generated: <b>{code}</b>
    </p>
  )
}
