import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EyeIcon,
  XMarkIcon,
  TrashIcon,
  CheckIcon,
  ClockIcon,
} from '@heroicons/react/24/solid'
import moment from 'moment'

import { generatePath, organizationsPath } from '@/utils'
import { Button, Modal } from '@/components'
import { INotification } from '@/types'
import { useDashboard } from '@/hooks'

import './styles.scss'

export const Notifications: React.FC = () => {
  const { notifications } = useDashboard()
  const [isOpen, setIsOpen] = useState(false)

  const toggleNotifications = useCallback(() => {
    setIsOpen(prev => !prev)
  }, [])

  // @ts-ignore
  window.toggleNotifications = toggleNotifications

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton={false}
      onClose={toggleNotifications}
    >
      <div className="lg:w-[35vw] w-full bg-white h-lvh left-0 absolute top-0 py-5 px-5">
        <div className="flex justify-between items-center px-5">
          <div className="flex flex-row gap-5 items-center justify-center">
            <h1 className="font-semibold text-2xl">Notifications</h1>
            <Button className="btn-read-all">
              <CheckIcon className="w-5 h-5" />
              Mark all as read
            </Button>
          </div>
          <XMarkIcon
            className="h-7 w-7 cursor-pointer"
            onClick={toggleNotifications}
          />
        </div>
        <div className="py-4 max-h-[94vh] overflow-y-auto px-5">
          {notifications.map(notification => (
            <React.Fragment key={notification._id}>
              <NotificationCard {...notification} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export const NotificationCard: React.FC<INotification> = notification => {
  const navigate = useNavigate()

  const viewTask = useCallback(() => {
    navigate(
      generatePath(
        organizationsPath,
        { id: notification.meta.organizationId },
        [{ taskId: notification._id }],
      ),
    )
    // @ts-ignore
    window.toggleNotifications()
  }, [navigate, notification._id, notification.meta.organizationId])

  return (
    <div className="notification-card">
      <div className="p-4">
        <div className="flex items-start">
          <div className="ml-3 w-0 flex-1">
            <p className="title">{notification.title}</p>
            <p className="message">{notification.message}</p>
            <div className="mt-4 flex justify-between">
              <span className="flex gap-2 text-gray-500 text-xs items-center">
                <ClockIcon className="w-5 h-5" />
                {moment(notification.createdAt).fromNow(true)}
              </span>
              <div className="flex">
                <Button type="button" className="btn-view" onClick={viewTask}>
                  View
                  <EyeIcon className="w-4 h-4 ml-2" />
                </Button>
                <Button type="button" className="btn-acknowledged">
                  Acknowledge
                  <TrashIcon className="w-4 h-4 ml-2" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
