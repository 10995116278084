import React, { useCallback, useEffect, useState } from 'react'

import { ConditionalWrapper } from '@/components'
import { Paginator } from '../Paginator'

import { IWithPaginatorProps } from './types'

export const WithPaginator = <T,>({
  data,
  WrapEl,
  loading,
  disabled,
  className,
  initialPage,
  initialPageSize,
  onPageMetaChange,
  children,
  showVertical,
  ...rest
}: IWithPaginatorProps<T>) => {
  const [pageSize, setPageSize] = useState(initialPageSize ?? 10)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setPageSize(initialPageSize ?? 10)
  }, [initialPageSize])

  useEffect(() => {
    setCurrentPage(initialPage ?? 1)
  }, [initialPage])

  const onPageChange = useCallback((_v: number) => {
    setCurrentPage(_v)
    onPageMetaChange?.(_v, pageSize)
  }, [])

  const onPageSizeChange = useCallback((_v: number) => {
    setPageSize(_v)
    onPageMetaChange?.(currentPage, _v)
  }, [])

  return (
    <ConditionalWrapper WrapEl={WrapEl} className={className}>
      {children(data)}
      <Paginator
        {...rest}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
        disabled={loading || disabled}
        onPageSizeChange={onPageSizeChange}
        showVertical={showVertical}
      />
    </ConditionalWrapper>
  )
}
