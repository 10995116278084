import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { IOrganizationSettingsResponse, IResponse, IUser } from '@/types'
import {
  useAssignUsers,
  useOrganizationSettings,
  useUsersByPermissions,
} from '@/apis'
import { useParams } from 'react-router-dom'
import { Button, Form, LoadingSkeleton, Modal, Select } from '@/components'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { rem } from '@/utils'
import { useAuth, useLocation, useToast } from '@/hooks'
import { permissionType } from '@matador/automations-lib/src/types/permissions'
import { XMarkIcon } from '@heroicons/react/24/solid'

const Loader = () => {
  return (
    <div>
      <div className="flex gap-4 my-4 py-2">
        {Array(4)
          .fill(0)
          .map((_, idx) => (
            <span key={idx} className="w-full">
              <LoadingSkeleton height={rem(24)} width="100%" />
            </span>
          ))}
      </div>
      <div className="w-full">
        <LoadingSkeleton height={rem(24)} width="50%" />
      </div>
    </div>
  )
}
const AssignedUsers = () => {
  const { refetchLockedLocations } = useLocation()
  const { addToast } = useToast()
  const { id } = useParams()
  const { accesses } = useAuth()
  const [users, setUsers] = useState<IUser[]>([])
  const [assignedUsers, setAssignedUsers] = useState<string[]>([])
  const stateRef = React.useRef<string[]>([])
  const isAdmin = accesses.ADMIN_WRITE

  const onGetSettingsSuccess = useCallback(
    (data: IResponse<IOrganizationSettingsResponse>) => {
      setAssignedUsers(data.data.assignedUsers)
      stateRef.current = data.data.assignedUsers
    },
    [],
  )

  const { mutateAsync: assignUsers, isPending } = useAssignUsers()

  const { mutateAsync: getUsers, isPending: fetchingUsers } =
    useUsersByPermissions()

  const [getOrgSettings, { isLoading: fetchingSettings }] =
    useOrganizationSettings(onGetSettingsSuccess)

  useEffect(() => {
    if (!id) return
    getUsers({
      permissions: [permissionType.AUTOMATIONS_READ],
      organization: id as string,
    }).then(res => {
      setUsers(res.data.users)
    })

    getOrgSettings({ pathParams: { organization: id } })
  }, [getOrgSettings, getUsers, id])

  const userList = useMemo(() => {
    return users
      .map(user => {
        return {
          value: user._id,
          label: user.firstName + ' ' + user.lastName,
        }
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
  }, [users])

  const assignedUserList = useMemo(() => {
    return userList.filter(user => assignedUsers.includes(user.value))
  }, [userList, assignedUsers])

  const availableUserList = useMemo(() => {
    return userList.filter(user => !assignedUsers.includes(user.value))
  }, [userList, assignedUsers])

  const triggerClick = useCallback((id: string, assigned: boolean) => {
    setAssignedUsers(prev =>
      assigned ? prev.filter(_id => _id !== id) : [...prev, id],
    )
  }, [])

  const loading = useMemo(
    () => fetchingUsers || fetchingSettings,
    [fetchingSettings, fetchingUsers],
  )

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      assignUsers({
        assignedUsers,
        params: { organization: id as string },
      }).then(() => {
        // notify success
        addToast('success', 'List successfully updated.')
        refetchLockedLocations()
      })
      stateRef.current = assignedUsers
    },
    [addToast, assignUsers, assignedUsers, id, refetchLockedLocations],
  )

  const hasChanges = !_.isEqual(assignedUsers, stateRef.current)

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-wrap items-center gap-4 my-4 py-2">
            {assignedUserList.length ? (
              assignedUserList.map(({ value, label }) => (
                <Button
                  className="btn-primary max-w-max flex items-center"
                  key={value}
                  onClick={() => isAdmin && triggerClick(value, true)}
                >
                  {label}
                  {isAdmin && <XCircleIcon className="w-4 h-4 ml-2" />}
                </Button>
              ))
            ) : (
              <p className="text-green-900 text-xl font-medium">
                No users assigned
              </p>
            )}
          </div>
          {isAdmin && (
            <Form onSubmit={handleSubmit}>
              <div className="max-w-md">
                <Select
                  label="Assign user"
                  allowEmpty
                  placeHolder="Assign user"
                  options={availableUserList}
                  onChange={option => triggerClick(option.value, false)}
                />
              </div>
              {hasChanges && (
                <div className="mt-4">
                  <Button className="btn-primary max-w-max" loading={isPending}>
                    Save
                  </Button>
                </div>
              )}
            </Form>
          )}
        </div>
      )}
    </div>
  )
}

export const AssignUsersModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={true}>
      <div className="lg:w-[75vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10">
        <div className="flex justify-between items-center gap-8">
          <h3 className="font-bold text-xl mb-2">Assigned Users</h3>

          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <AssignedUsers />
      </div>
    </Modal>
  )
}
