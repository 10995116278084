import { permissionValueType } from '@/types'
import { permissionType } from '@matador/automations-lib/src/types/permissions'

export const groupPermissions = (permissions: permissionValueType[]) => {
  const groupedPermissions: Record<
    string,
    { label: string; value: permissionValueType }[]
  > = {}
  permissions.forEach(permission => {
    const [scope, accessLevel] = permission.split(':')
    if (!groupedPermissions[scope]) {
      groupedPermissions[scope] = []
    }
    groupedPermissions[scope].push({ label: accessLevel, value: permission })
  })
  return groupedPermissions
}

export const checkPermissions = (
  permissions: permissionValueType[],
  access: permissionType[],
) => {
  return access.some(access => permissions.includes(access))
}

export const checkSuperhuman = (permissions: permissionValueType[]) => {
  const hasSuperhumanAcces = [
    permissionType.SUPERHUMAN_READ,
    permissionType.SUPERHUMAN_WRITE,
  ].some(el => permissions.includes(el))

  const noAutomationAccess = [
    permissionType.AUTOMATIONS_WRITE,
    permissionType.AUTOMATIONS_READ,
  ].every(el => !permissions.includes(el))
  return hasSuperhumanAcces && noAutomationAccess
}
