import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

import {
  TLoginFormSchema,
  ICheckAuthResponse,
  IMenu,
  permissionValueType,
  IUserSettingsResponse,
} from '@/types'
import { useAuth, useCheckAuth, useGoogleAuth, useUserSettings } from '@/apis'
import {
  homePath,
  signInPath,
  setStorageValue,
  getStorageValue,
  removeStorageValue,
  superhumanPath,
  generateNavigationPathsByPermissions,
} from '@/utils'
import { AUTH_TOKEN_KEY } from '@/constants'
import { checkPermissions, checkSuperhuman } from '@/utils/permissions'
import { permissionType } from '@matador/automations-lib/src/types/permissions'
import { getResourceAndLevel } from '@matador/automations-lib/src/utils/permissions'

interface IAuthContext {
  authenticated: boolean
  isAuthLoading: boolean
  isGoogleAuthLoading: boolean
  userPermissions: ICheckAuthResponse & IUserSettingsResponse
  onSignIn: (_payload: TLoginFormSchema) => Promise<void>
  onGoogleSignIn: (_payload: { token: string }) => Promise<void>
  isNotPermitted: boolean
  setIsNotPermitted: React.Dispatch<React.SetStateAction<boolean>>
  availableRoutes: IMenu[]
  permissions: permissionValueType[]
  accesses: Record<keyof typeof permissionType, boolean>
}

export const AuthContext = createContext({} as IAuthContext)

const defaultState = {
  isAdmin: false,
  isSuperAdmin: false,
  _id: '',
  _organization_id: '',
  permissions: [],
  organizations: [],
}

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [userPermissions, setPermissions] = useState<
    ICheckAuthResponse & IUserSettingsResponse
  >(defaultState)
  const [isNotPermitted, setIsNotPermitted] = useState(false)
  const [availableRoutes, setAvailableRoutes] = useState<IMenu[]>([])
  const authToken = getStorageValue(AUTH_TOKEN_KEY)

  const { mutateAsync: checkAuthStatus, isPending: isCheckingLoading } =
    useCheckAuth()
  const { mutateAsync: singIn, isPending: isAuthLoading } = useAuth()
  const { mutateAsync: googleAuth, isPending: isGoogleAuthLoading } =
    useGoogleAuth()

  const onSettingsSuccess = useCallback(
    (data: IUserSettingsResponse) => {
      const isSuperhuman = checkSuperhuman(
        getResourceAndLevel(data.permissions) as permissionValueType[],
      )
      const paths = generateNavigationPathsByPermissions(
        getResourceAndLevel(data.permissions) as permissionValueType[],
      )

      if (isSuperhuman) {
        navigate(superhumanPath, { replace: true })
      }
      setAvailableRoutes(paths)

      const hasAccess = paths.some(
        ({ path, children }) =>
          pathname.replace('/', '').includes(path.replace('/', '')) ||
          children?.some(({ path }) => pathname.includes(path)),
      )
      if (!hasAccess) {
        paths.length
          ? navigate(paths[0].path, { replace: true })
          : setIsNotPermitted(true)
      }
      setPermissions(prevState => ({ ...prevState, ...data }))
    },
    [navigate, pathname],
  )
  const [getSettings, { isLoading: isSettingsLoading }] =
    useUserSettings(onSettingsSuccess)

  const hasAccess = useMemo(() => {
    return availableRoutes.some(({ path }) =>
      pathname.replace('/', '').includes(path.replace('/', '')),
    )
  }, [pathname, availableRoutes])

  const isLoading = useMemo(
    () => isAuthLoading || isCheckingLoading || isSettingsLoading,
    [isCheckingLoading, isAuthLoading, isSettingsLoading],
  )

  const onFetchDashboard = useCallback(async () => {
    const status = await checkAuthStatus()

    setPermissions(prevState => ({ ...prevState, ...status.data }))
  }, [checkAuthStatus])

  const onSignOut = useCallback(() => {
    removeStorageValue(AUTH_TOKEN_KEY)
    navigate(signInPath, { replace: true })
  }, [navigate])

  const onUnAuthenticated = useCallback(() => {
    setIsNotPermitted(true)
  }, [])

  // @ts-ignore
  window.onSignOut = onSignOut
  // @ts-ignore
  window.onUnAuthenticated = onUnAuthenticated

  const onAuthenticated = useCallback(
    async (_authenticated: boolean) => {
      setAuthenticated(_authenticated)
      if (_authenticated) {
        const to = pathname.includes(signInPath) ? homePath : pathname
        navigate(to, { replace: true })
        getSettings()
        await onFetchDashboard()
      } else {
        onSignOut()
      }
    },
    [navigate, pathname, onSignOut, onFetchDashboard, getSettings],
  )

  useEffect(() => {
    if (!authenticated) {
      try {
        if (authToken) {
          onAuthenticated(true)
        } else {
          onAuthenticated(false)
        }
      } catch {
        onAuthenticated(false)
      }
    }
  }, [onAuthenticated, authToken, authenticated])

  const onSignIn = useCallback(
    async (payload: TLoginFormSchema) => {
      try {
        const response = await singIn(payload)
        const { exp } = jwtDecode(response.data)
        const expires = new Date((exp || 0) * 1000)

        setStorageValue(AUTH_TOKEN_KEY, response.data, false, { expires })
        onAuthenticated(!!response.data)
      } catch (e) {}
    },
    [onAuthenticated, singIn],
  )

  const onGoogleSignIn = useCallback(
    async (payload: { token: string }) => {
      try {
        const response = await googleAuth(payload)
        const { exp } = jwtDecode(response.data)
        const expires = new Date((exp || 0) * 1000)

        setStorageValue(AUTH_TOKEN_KEY, response.data, false, { expires })
        onAuthenticated(!!response.data)
      } catch (e) {}
    },
    [googleAuth, onAuthenticated],
  )

  useEffect(() => {
    if (userPermissions) {
      if (!hasAccess) {
        setIsNotPermitted(true)
      } else {
        setIsNotPermitted(false)
      }
    }
  }, [hasAccess, userPermissions])

  const permissions = useMemo(
    () => userPermissions?.permissions || [],
    [userPermissions?.permissions],
  )

  const accesses = useMemo(() => {
    return Object.keys(permissionType).reduce(
      (acc, key) => {
        acc[key as keyof typeof permissionType] = checkPermissions(
          getResourceAndLevel(permissions) as permissionValueType[],
          [permissionType[key as keyof typeof permissionType]],
        )
        return acc
      },
      {} as Record<keyof typeof permissionType, boolean>,
    )
  }, [permissions])

  const value = useMemo(
    () => ({
      onSignIn,
      onGoogleSignIn,
      authenticated,
      isAuthLoading: isLoading,
      isGoogleAuthLoading,
      isNotPermitted,
      setIsNotPermitted,
      availableRoutes,
      permissions,
      accesses,
      userPermissions,
    }),
    [
      onSignIn,
      onGoogleSignIn,
      authenticated,
      isGoogleAuthLoading,
      isLoading,
      isNotPermitted,
      availableRoutes,
      permissions,
      accesses,
      userPermissions,
    ],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
