import React from 'react'
import classNames from 'classnames'

import { FormError } from '../FormError'
import { ITextAreaProps } from './types'

import './styles.scss'
import { rem } from '@/utils'

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      canResize = true,
      subLabel,
      label,
      error,
      required,
      className,
      onChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        className={classNames('textarea-input w-100', className, {
          error: !!error,
        })}
      >
        {!!label && (
          <span className="name form-label">
            {label}
            {!!required && <sup>*</sup>}
          </span>
        )}
        {!!subLabel && <span className="subtext">{subLabel}</span>}
        <textarea
          ref={ref}
          style={{
            height: rem((rest.rows || 1) * 42),
            ...(canResize ? {} : { resize: 'none' }),
          }}
          {...rest}
          onChange={onChange}
        />
        {!!error && <FormError error={error} />}
      </label>
    )
  },
)

export default TextArea
