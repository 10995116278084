import React from 'react'

import { ITableLoadingSkeletonProps } from './types'
import { LoadingSkeleton } from '@/components'
import { rem } from '@/utils'

export const TableLoadingSkeleton: React.FC<ITableLoadingSkeletonProps> = ({
  rows = 1,
  cols = 4,
  className,
}) => {
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, idx) => (
          <tr key={idx}>
            {Array(cols)
              .fill(0)
              .map((_, idx) => (
                <td key={idx} className={className}>
                  <LoadingSkeleton height={rem(24)} width="90%" />
                </td>
              ))}
          </tr>
        ))}
    </>
  )
}
