import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'

import {
  TFunc,
  ITask,
  IStats,
  TFilters,
  IResponse,
  IDataContext,
  INotification,
  IOrganization,
  IFilterResponse,
  ILocationsResponse,
} from '@/types'
import {
  useCRMs,
  useStats,
  useAllTasks,
  useLocations,
  useNotifications,
  useOrganizations,
} from '@/apis'
import { checkPermissions } from '@/utils/permissions'
import { useAuth } from '@/hooks'
import {
  permissionType,
  permissionValueType,
} from '@matador/automations-lib/src/types/permissions'
import { getResourceAndLevel } from '@matador/automations-lib/src/utils/permissions'
import { calculateStartEndDateWithTimezone } from '@/utils'

interface IDashboardContext
  extends Omit<IDataContext<Record<string, ITask[]>, TFilters>, 'isLoading'> {
  stats?: IStats
  isLoading: boolean
  crms: IFilterResponse[]
  isTasksLoading: boolean
  isStatsLoading: boolean
  refetchDashboard: TFunc
  locations: ILocationsResponse[]
  notifications: INotification[]
  organizations: IOrganization[]
  isOrganizationsLoading: boolean
  isNotificationsLoading: boolean
}

export const DashboardContext = createContext({} as IDashboardContext)

const userTimezone = moment.tz.guess()
export const dashboardDefaultFilters: Partial<TFilters> = {
  automationType: 'automation',
  startDate: moment().subtract(7, 'd').toString(),
  endDate: moment().endOf('day').toString(),
}

const cacheOrganizations = (data: IOrganization[]) => {
  localStorage.setItem('organizations', JSON.stringify(data))
}
const getOrganizations = (): IOrganization[] => {
  const orgList = localStorage.getItem('organizations')
  return orgList ? (JSON.parse(orgList) as IOrganization[]) : []
}
export const DashboardContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { permissions } = useAuth()
  const [stats, setStats] = useState<IStats>()
  const [refreshKey, onTasksRefresh] = useState<string>(`${Date.now()}`)
  const [crms, setCRMs] = useState<IFilterResponse[]>([])
  const [locations, setLocations] = useState<ILocationsResponse[]>([])
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [organizations, setOrganizations] =
    useState<IOrganization[]>(getOrganizations())
  const [filters, setFilters] = useState<Partial<TFilters>>(
    dashboardDefaultFilters,
  )

  const onStatsSuccess = useCallback((res: AxiosResponse<IStats>) => {
    setStats(res.data)
  }, [])

  const onOrganizationsSuccess = useCallback(
    (res: AxiosResponse<IOrganization[]>) => {
      setOrganizations(res.data)
      cacheOrganizations(res.data)
    },
    [],
  )

  const onNotificationsSuccess = useCallback(
    (res: AxiosResponse<INotification[]>) => {
      setNotifications(res.data)
    },
    [],
  )

  const onLoactionSuccess = useCallback(
    (res: IResponse<ILocationsResponse[]>) => {
      setLocations(
        res.data.sort((a, b) => {
          if (a.name.trim() < b.name.trim()) return -1
          if (a.name.trim() > b.name.trim()) return 1
          return 0
        }),
      )
    },
    [],
  )

  const onFiltersSuccess = useCallback(
    (type: 'location' | 'crm') => (res: IResponse<IFilterResponse[]>) => {
      if (type === 'location') {
      } else {
        setCRMs(
          res.data.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          }),
        )
      }
    },
    [],
  )

  useEffect(() => {
    onTasksRefresh(`${Date.now()}`)
  }, [filters])

  const [fetchLocations, { isLoading: isLocationsLoading }] =
    useLocations(onLoactionSuccess)
  const [fetchCRMs, { isLoading: isCrmsLoading }] = useCRMs(
    onFiltersSuccess('crm'),
  )

  const { mutate: fetchStats, isPending: isStatsLoading } =
    useStats(onStatsSuccess)

  const { mutate: fetchOrganizations, isPending: isOrganizationsLoading } =
    useOrganizations(onOrganizationsSuccess)
  const { mutate: fetchNotifications, isPending: isNotificationsLoading } =
    useNotifications(onNotificationsSuccess)

  const canFetch = useMemo(
    () =>
      checkPermissions(
        getResourceAndLevel(permissions) as permissionValueType[],
        [permissionType.AUTOMATIONS_READ],
      ),
    [permissions],
  )

  const taskOptions = useMemo(
    () => ({
      enabled: canFetch,
      refreshKey,
      ...filters,
      ...calculateStartEndDateWithTimezone({
        start: filters.startDate,
        end: filters.endDate,
        timezone: userTimezone,
      }),
    }),
    [canFetch, filters, refreshKey],
  )

  const {
    pageMeta,
    data: tasks,
    onPageMetaChange,
    total: totalTasks,
    loading: isTasksLoading,
  } = useAllTasks(taskOptions)

  const fetchDashboard = useCallback(() => {
    if (canFetch) {
      fetchStats()
      fetchCRMs()
      fetchLocations()
      fetchNotifications()
      fetchOrganizations()
    }
  }, [
    fetchCRMs,
    fetchStats,
    fetchLocations,
    fetchNotifications,
    fetchOrganizations,
    canFetch,
  ])

  useEffect(() => {
    fetchDashboard()
  }, [fetchDashboard])

  const refetchDashboard = useCallback(() => {
    fetchDashboard()
  }, [fetchDashboard])

  const onUpdateFilters = useCallback((_filter: Partial<TFilters>) => {
    setFilters(prev => ({ ...prev, ..._filter }))
  }, [])

  const isLoading = useMemo(
    () =>
      isCrmsLoading ||
      isStatsLoading ||
      isLocationsLoading ||
      isOrganizationsLoading ||
      isNotificationsLoading,
    [
      isCrmsLoading,
      isStatsLoading,
      isLocationsLoading,
      isOrganizationsLoading,
      isNotificationsLoading,
    ],
  )

  const onResetFilters = useCallback(() => {
    setFilters(dashboardDefaultFilters)
  }, [])

  const value = useMemo(
    () => ({
      crms,
      stats,
      filters,
      pageMeta,
      locations,
      isLoading,
      data: tasks,
      notifications,
      organizations,
      isStatsLoading,
      onResetFilters,
      onUpdateFilters,
      isTasksLoading,
      refetchDashboard,
      onPageMetaChange,
      totalItems: totalTasks,
      isOrganizationsLoading,
      isNotificationsLoading,
    }),
    [
      crms,
      stats,
      filters,
      pageMeta,
      locations,
      isLoading,
      tasks,
      notifications,
      organizations,
      isStatsLoading,
      onResetFilters,
      onUpdateFilters,
      isTasksLoading,
      refetchDashboard,
      onPageMetaChange,
      totalTasks,
      isOrganizationsLoading,
      isNotificationsLoading,
    ],
  )

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}
