import React, { useCallback, useMemo, useState } from 'react'

import { DataTable, TableLoadingSkeleton, WithPaginator } from '@/components'
import { useOrganization } from '@/hooks'
import { ITask } from '@/types'

import {
  Filters,
  headers,
  ListItem,
  TaskDetails,
  AutomationTypeFilter,
} from '@/pages/Dashboard/components'

export const TasksList: React.FC = () => {
  const {
    tasksFilter,
    onResetTasksFilter,
    onUpdateTasksFilter,
    tasksResponse,
  } = useOrganization()
  const {
    data,
    pageMeta,
    total,
    onPageMetaChange,
    loading: isTasksLoading,
  } = tasksResponse

  const [task, setTask] = useState<ITask>()

  const onClose = useCallback(() => {
    setTask(undefined)
  }, [])

  const onSelect = useCallback((task: ITask) => {
    setTask(task)
  }, [])

  const pageData: ITask[] = useMemo(() => {
    if (isTasksLoading) return [...new Array(10).fill(1)]
    return data[pageMeta.page] ?? []
  }, [data, isTasksLoading, pageMeta.page])

  return (
    <>
      <div className="flex justify-end">
        <AutomationTypeFilter
          filters={tasksFilter}
          onUpdate={onUpdateTasksFilter}
        />
      </div>
      <WithPaginator
        data={pageData}
        totalItems={total}
        loading={isTasksLoading}
        initialPage={pageMeta.page}
        initialPageSize={pageMeta.pageSize}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <>
            <Filters
              hideOrganization
              filters={tasksFilter}
              onReset={onResetTasksFilter}
              onUpdate={onUpdateTasksFilter}
            />
            <DataTable data={pagedData} headers={headers}>
              {data =>
                isTasksLoading ? (
                  <TableLoadingSkeleton className="py-4" rows={10} cols={4} />
                ) : (
                  data.map((datum, idx) => (
                    <ListItem onSelect={onSelect} key={idx} {...datum} />
                  ))
                )
              }
            </DataTable>
          </>
        )}
      </WithPaginator>
      <TaskDetails task={task} onClose={onClose} />
    </>
  )
}
