import React, { useCallback } from 'react'
import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap'
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic'
import { formatQuery, QueryBuilder } from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'

import './styles.scss'

import { defaultCombinator, initialQuery } from './constants'
import { useLocalWorkflow } from './'
import { useParams } from 'react-router-dom'

export const CombinatorQueryBuilder: React.FC = () => {
  const { workFlowId } = useParams()
  const isNewWorkflow = workFlowId === 'new'
  const { payload, onUpdatePayload } = useLocalWorkflow()
  const [query, setQuery] = React.useState(
    parseJsonLogic(
      isNewWorkflow
        ? (payload?.template?.combinator ?? defaultCombinator)
        : (payload.combinator ?? defaultCombinator),
    ) || initialQuery,
  )

  const onQueryChange = useCallback(
    (q: any) => {
      setQuery(q)
      // @ts-ignore
      onUpdatePayload({ combinator: formatQuery(q, 'jsonlogic') })
    },
    [onUpdatePayload],
  )

  return (
    <div className="combinator">
      <h5 className="font-semibold py-2">
        Add filters and criteria on which you want to base communications for
        this workflow.
      </h5>
      <QueryBuilderBootstrap>
        <QueryBuilder
          // @ts-ignore
          query={query}
          onQueryChange={onQueryChange}
          // @ts-ignore
          fields={payload?.template?.actions}
        />
      </QueryBuilderBootstrap>
    </div>
  )
}
