import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import { usePagination } from '@/hooks'

import { PageJumpButton } from './pageJumpButton'
import { PageButtons } from './pageButtons'
import { IPaginatorProps } from './types'
import './styles.scss'

export const Paginator: React.FC<IPaginatorProps> = ({
  className,
  totalItems,
  pageSize = 10,
  currentPage = 1,
  siblingCount = 2,
  disabled = false,
  renderOnOnePageCount = false,
  onPageChange,
  prevPageButton,
  nextPageButton,
  showVertical,
}) => {
  const [perPage, setPerPage] = useState(1)

  const paginationRange = usePagination({
    currentPage,
    totalItems,
    siblingCount: showVertical ? 1 : siblingCount,
    pageSize: perPage,
  })

  useEffect(() => {
    setPerPage(pageSize)
  }, [pageSize])

  const totalPages = useMemo(
    () => Math.ceil(totalItems / (perPage || 1)),
    [perPage, totalItems],
  )
  const lastPage = useMemo(
    () =>
      paginationRange.length > 1
        ? paginationRange[paginationRange.length - 1]
        : totalPages,
    [paginationRange, totalPages],
  )

  const onNext = useCallback(() => {
    if (onPageChange) onPageChange(Math.min(currentPage + 1, totalPages))
  }, [currentPage, onPageChange, totalPages])

  const onPrevious = useCallback(() => {
    if (onPageChange) onPageChange(Math.max(currentPage - 1, 1))
  }, [currentPage, onPageChange, totalPages])

  if (!paginationRange.length) return null
  if (paginationRange.length < 2 && !renderOnOnePageCount) return null

  return (
    <div
      className={classNames('table-summary w-full overflow-x-auto', {
        vertical: showVertical,
      })}
    >
      <div className="mb-4 sm:mb-0">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{currentPage}</span> to{' '}
          <span className="font-medium">{totalPages}</span> of
          <span className="font-medium"> {totalItems}</span> results
        </p>
      </div>
      <div
        className={classNames({
          [className || '']: !!className,
        })}
      >
        <ul>
          <PageJumpButton
            onClick={onPrevious}
            defaultBtn={prevPageButton}
            disabled={currentPage === 1 || disabled}
          />
          <PageButtons
            disabled={disabled}
            range={paginationRange}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
          <PageJumpButton
            toNext
            onClick={onNext}
            defaultBtn={nextPageButton}
            disabled={currentPage === lastPage || disabled}
          />
        </ul>
      </div>
    </div>
  )
}
