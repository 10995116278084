import { IDataTableHeader } from '@/components'
import { IImportJob, IInventoryAdmin, IInventoryEvent } from '@/types'

export const headers: IDataTableHeader<IInventoryAdmin>[] = [
  {
    key: 'organization',
    label: 'Organization',
    sortable: true,
  },
  {
    key: 'vendor',
    label: 'Vendor',
    sortable: false,
  },
  {
    key: 'dealer_id',
    label: 'Dealer',
    sortable: false,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
  },
  {
    key: 'synced_at',
    label: 'Last Synced',
    sortable: false,
  },
  {
    key: 'modifiedAt',
    label: 'Last Modified',
    sortable: false,
  },
  {
    key: 'dealer_id',
    label: '',
    sortable: false,
  },
]

export const importJobHeaders: IDataTableHeader<IImportJob>[] = [
  {
    key: 'status',
    label: 'Status',
    sortable: false,
  },
  {
    key: 'message',
    label: 'Message',
    sortable: false,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    sortable: false,
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
    sortable: false,
  },
]

export const inventoryEventHeaders: IDataTableHeader<IInventoryEvent>[] = [
  {
    key: 'user',
    label: 'User',
    sortable: false,
  },
  {
    key: 'newData',
    label: 'Data',
    sortable: false,
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
    sortable: false,
  },
]
