export const frequencyDefaultState = {
  days: [1],
  weekdays: [],
  increment: '',
  time: {
    hour: '',
    minute: '',
    isoTimezone: undefined,
  },
}
