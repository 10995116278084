import { AxiosResponse } from 'axios'

import {
  IStats,
  IResponse,
  IOrganization,
  INotification,
  IFilterResponse,
  ISingleOrganizationPayload,
  ILocationsResponse,
} from '@/types'
import { endpoints, useLazyGet, usePost } from '@/apis'

export const useStats = (
  onSuccess?: (_response: AxiosResponse<IStats>) => void,
) => {
  return usePost<ISingleOrganizationPayload, IStats>(
    endpoints.stats,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useNotifications = (
  onSuccess?: (_response: AxiosResponse<INotification[]>) => void,
) => {
  return usePost<undefined, INotification[]>(
    endpoints.notifications,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useOrganizations = (
  onSuccess?: (_response: AxiosResponse<IOrganization[]>) => void,
) => {
  return usePost<undefined, IOrganization[]>(
    endpoints.organizations,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useLocations = (
  onSuccess?: (_data: IResponse<ILocationsResponse[]>) => void,
) => {
  return useLazyGet<undefined, IResponse<ILocationsResponse[]>>(
    endpoints.locations,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useCRMs = (
  onSuccess?: (_data: IResponse<IFilterResponse[]>) => void,
) => {
  return useLazyGet<undefined, IResponse<IFilterResponse[]>>(
    endpoints.crms,
    undefined,
    {
      onSuccess,
    },
  )
}
