export enum permissionType {
	ADMIN_READ = 'admin:read',
	ADMIN_WRITE = 'admin:write',
	AUTOMATIONS_READ = 'automations:read',
	AUTOMATIONS_WRITE = 'automations:write',
	WORKFLOWS_READ = 'workflows:read',
	WORKFLOWS_WRITE = 'workflows:write',
	ONBOARDING_READ = 'onboarding:read',
	ONBOARDING_WRITE = 'onboarding:write',
	SUPERHUMAN_READ = 'superhuman:read',
	SUPERHUMAN_WRITE = 'superhuman:write',
	INVENTORY_ADMIN = 'inventory:admin',
}

export enum Resource {
	'superhuman' = 'superhuman',
	'admin' = 'admin',
	'automations' = 'automations',
	'workflows' = 'workflows',
	'onboarding' = 'onboarding',
	'inventory' = 'inventory',
}
export enum Level {
	'read' = 'read',
	'write' = 'write',
	'admin' = 'admin',
}

export type Organization = string;

export type ParsedPermission = {
	resource: Resource;
	level: Level;
	organizations: string[];
};

export type permissionValueType = `${permissionType}`;
export type PermissionValueTypeWithOrg = `${permissionType}/[${Organization}]`;
