import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useOrganizationWorkflow,
  useOrganizationWorkflowLocations,
} from '@/apis'
import {
  IWorkflow,
  IFilterResponse,
  IOrganizationWorkflowResponse,
  IOrganizationWorkflowLocationsResponse,
} from '@/types'

interface ILocationResponse extends IFilterResponse {
  crm?: string
}
interface IWorkflowContext {
  workflows: IWorkflow[]
  // @ts-ignore
  locations: ILocationResponse[]
  organization?: IFilterResponse
  updateWorkflows: (
    _workflow: Partial<IWorkflow>,
    _action?: 'delete' | 'update',
  ) => void
}
export const WorkflowContext = createContext({} as IWorkflowContext)

export const WorkflowContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [workflows, setWorkflows] = useState<IWorkflow[]>([])
  const [locations, setLocations] = useState<ILocationResponse[]>([])
  const [organization, setOrganization] = useState<IFilterResponse>()

  const onGetWorkflowsSuccess = useCallback(
    (data: IOrganizationWorkflowResponse) => {
      setWorkflows(data.workflows)
      setOrganization(data.organization)
    },
    [],
  )

  const onGetWorkflowLocationsSuccess = useCallback(
    (data: IOrganizationWorkflowLocationsResponse) => {
      setLocations(data.locations)
    },
    [],
  )

  const [getWorkflow] = useOrganizationWorkflow(onGetWorkflowsSuccess)
  const [getOrganizationWorkflowLocations] = useOrganizationWorkflowLocations(
    onGetWorkflowLocationsSuccess,
  )

  const updateWorkflows = useCallback(
    (workflow: Partial<IWorkflow>, action: 'delete' | 'update' = 'update') => {
      setWorkflows(prev => {
        let nState = prev
        if (action === 'delete') {
          nState = nState.filter(w => w._id !== workflow._id)
        } else {
          nState = nState.map(w => {
            if (w._id === workflow._id) return { ...w, ...workflow }
            return w
          })
        }
        return [...nState]
      })
    },
    [],
  )

  useEffect(() => {
    if (!id) {
      navigate(-1)
    } else {
      getWorkflow({
        pathParams: { id },
      })
      getOrganizationWorkflowLocations({
        pathParams: { id },
      })
    }
  }, [id, getWorkflow, navigate, getOrganizationWorkflowLocations])

  const value = useMemo(
    () => ({
      workflows,
      locations,
      organization,
      updateWorkflows,
    }),
    [workflows, locations, organization, updateWorkflows],
  )
  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  )
}
