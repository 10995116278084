import { IDataTableHeader } from '@/components'
import { IRepairOrder } from '@/types'

export const tableHeaders: IDataTableHeader<IRepairOrder>[] = [
  {
    key: 'externalId',
    label: 'RO number',
    sortable: false,
  },
  {
    sortable: false,
    key: 'crm',
    label: 'CRM',
  },
  {
    key: 'excludedWorkflows',
    label: 'Wokflows Used',
    sortable: false,
  },
  {
    sortable: false,
    key: 'createdAt',
    label: 'Created at',
  },
  {
    key: 'rawPayloads',
    label: 'Last Raw Payload',
    sortable: false,
  },
]
