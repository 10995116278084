import { IDataTableHeader } from '@/components'
import {
  IAutomationData,
  IAutomationForm,
  IOption,
  TAutomationType,
} from '@/types'

export const headers: IDataTableHeader<IAutomationData>[] = [
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'launchAt',
    label: 'Launch cycle',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'action',
    label: ' ',
  },
]

export const options: IOption<string>[] = [
  {
    label: 'days',
    value: 'days',
  },
  {
    label: 'months',
    value: 'months',
  },
]

export const soldOptions: IOption<string>[] = [
  ...new Array(500).fill(0).map((_, i) => ({
    value: `${i}`,
    label: `${i}`,
  })),
  ...new Array(500).fill(0).map((_, idx) => ({
    value: `${-idx - 1}`,
    label: `${idx + 1} (in the future)`,
  })),
]

export const defaultState: IAutomationForm = {
  edit: false,
  name: undefined,
  ruleId: undefined,
  locationId: undefined,
  automationId: undefined,
  meta: {
    start_2: '0',
    startUnit_2: 'days',
    end_2: '0',
    endUnit_2: 'days',
    deal_status: [],
  },
  message: undefined,
  start: '0',
  startUnit: 'days',
  end: '0',
  endUnit: 'days',

  image: null,
  media: null,
  imageError: null,
  frequencies: [],
  timezone: '',
  isReviewInvite: false,
  assignedUsers: [],
}

export const defaultWeekdays = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
}

export const hourOptions: IOption[] = Array(...new Array(24)).map((_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
  disabled: false,
}))

export const minuteOptions: IOption[] = Array(...new Array(12)).map((_, i) => ({
  value: `${i * 5}`,
  label: `${i * 5 < 10 ? '0' + i * 5 : i * 5}`,
  disabled: false,
}))

export const automationTypeColors: Record<TAutomationType, string> = {
  automation: 'bg-green-50 text-green-700 ring-green-600/20',
  appointment: 'bg-blue-50 text-blue-700 ring-blue-700/10',
  lead: 'bg-indigo-50 text-indigo-700 ring-indigo-700/10',
  aibot: 'bg-purple-50 text-purple-700 ring-purple-700/10',
  workflow: 'bg-red-50 text-red-700 ring-red-600/10',
  resurrection: 'bg-gray-50 text-gray-600 ring-gray-500/10',
  weeklyreport: 'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
}
