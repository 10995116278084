import React, { useMemo, useState } from 'react'
import {
  DataTable,
  PageLoadingWrapper,
  TableLoadingSkeleton,
  TextInput,
} from '@/components'

import { headers } from './components/constants'
import { useLocation, useSearch } from '@/hooks'
import ListItem from './components/listItem'

export const LockedLocation = () => {
  const { lockedLocations, isLoading } = useLocation()
  const [filter, setFilter] = useState<string>('')
  const { text, onChangeText } = useSearch(300, '', setFilter)

  const data = useMemo(() => {
    let newData = lockedLocations
    if (filter) {
      newData = lockedLocations.filter(loc =>
        loc.location.name.toLowerCase().includes(filter.toLowerCase()),
      )
    }
    return newData.sort((a, b) => {
      return a.location.name.localeCompare(b.location.name)
    })
  }, [lockedLocations, filter])
  return (
    <PageLoadingWrapper>
      <div className="container mx-auto  px-5">
        <div className="flex justify-end py-4 mb-2">
          <TextInput
            label="Location"
            className="w-full max-w-sm"
            value={text || ''}
            placeholder="Search by location name..."
            onChange={e => onChangeText(e.target.value)}
          />
        </div>
        <DataTable loading={isLoading} data={data} headers={headers}>
          {data =>
            isLoading ? (
              <TableLoadingSkeleton className="py-4" rows={10} cols={6} />
            ) : (
              data.map((datum, idx) => <ListItem key={idx} {...datum} />)
            )
          }
        </DataTable>
      </div>
    </PageLoadingWrapper>
  )
}
