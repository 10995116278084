import React, { useMemo } from 'react'
import SearchBar from '@/components/SearchBar'
import { useOrganization } from '@/hooks'

import _ from 'lodash'
import { TAutomationFilters } from '@/types'

interface FilterProps {
  filter: TAutomationFilters
  setFilter: React.Dispatch<React.SetStateAction<TAutomationFilters>>
}

const Filters = ({ filter, setFilter }: FilterProps) => {
  const { automations } = useOrganization()
  const locationOptions = useMemo(() => {
    const locations = automations.map(_v => ({
      label: _v.location.name,
      value: _v.location._id,
    }))
    return [{ label: 'All', value: '' }, ..._.uniqBy(locations, 'value')]
  }, [automations])

  const typeOptions = useMemo(() => {
    const types = automations.map(_v => ({
      label: _v.type,
      value: _v.type,
    }))
    return [{ label: 'All', value: '' }, ..._.uniqBy(types, 'value')]
  }, [automations])

  return (
    <div className="py-4 grid gap-8 grid-cols-3">
      <div className="w-full">
        <SearchBar
          isMulti
          label="Location"
          options={locationOptions}
          onChange={_v => {
            // @ts-ignore
            setFilter(prevState => ({ ...prevState, location: _v }))
          }}
          value={
            locationOptions.filter(_v =>
              filter.location.find(el => el.value === _v.value),
            ) || [locationOptions[0]]
          }
        />
      </div>
      <div className="w-full">
        <SearchBar
          isMulti
          label="Type"
          options={typeOptions}
          onChange={_v => {
            // @ts-ignore
            setFilter(prevState => ({ ...prevState, type: _v }))
          }}
          value={
            typeOptions.filter(_v =>
              filter.type.find(el => el.value === _v.value),
            ) || [typeOptions[0]]
          }
        />
      </div>
    </div>
  )
}

export default Filters
