import React from 'react'
import { Outlet } from 'react-router-dom'

import { AuthContextProvider } from '@/context'

export const TokenWrapper: React.FC = () => {
  return (
    <AuthContextProvider>
      <Outlet />
    </AuthContextProvider>
  )
}
