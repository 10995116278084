import { IDataTableHeader } from '@/components'
import { IAppointment } from '@/types'

export const headers: IDataTableHeader<IAppointment>[] = [
  {
    key: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    key: 'phoneNumber',
    label: 'Phone number',
    sortable: false,
  },
  {
    key: 'crm',
    label: 'CRM',
    sortable: false,
  },
  {
    sortable: false,
    key: 'automation',
    label: 'Automation ID',
  },
  {
    sortable: false,
    key: 'organizationId',
    label: 'Organization',
  },
  {
    sortable: false,
    key: 'locationId',
    label: 'Location',
  },
  {
    sortable: false,
    key: 'status',
    label: 'Status',
  },
  {
    sortable: false,
    key: 'appointmentTime',
    label: 'Appointment time',
  },
  {
    sortable: false,
    key: 'createdAt',
    label: 'Created at',
  },
]
