import { IDataTableHeader } from '@/components'
import { IServiceAppointment } from '@/types'

export const serviceAppointmentsTableHeaders: IDataTableHeader<IServiceAppointment>[] =
  [
    {
      key: 'externalId',
      label: 'Appointment ID',
      sortable: false,
    },
    {
      sortable: false,
      key: 'crm',
      label: 'CRM',
    },
    {
      key: 'excludedWorkflows',
      label: 'Wokflows Used',
      sortable: false,
    },
    {
      sortable: false,
      key: 'appointmentDate',
      label: 'Appointment time',
    },
    {
      sortable: false,
      key: 'createdAt',
      label: 'Created at',
    },
    {
      key: 'rawPayloads',
      label: 'Last Raw Payload',
      sortable: false,
    },
  ]
