import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CSVReader from 'react-csv-reader'
import { IInventory } from '@/types'
import {
  Button,
  DataTable,
  TableLoadingSkeleton,
  WithPaginator,
} from '@/components'

import noImage from '@/assets/images/image-not-available.png'
const FileViewer = ({
  url,
  defaultLoading = false,
}: {
  url: string | null
  defaultLoading?: boolean
}) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(defaultLoading)
  const [csvData, setCsvData] = useState<IInventory[] | null>(null)
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setError('')
  }, [url])

  useEffect(() => {
    if (defaultLoading) {
      setLoading(true)
    }
  }, [defaultLoading])

  const fetchCsvData = useCallback(async () => {
    if (url) {
      setLoading(true)
      try {
        const response = await fetch(url)
        if (response.status === 404) {
          setError('File not found')
          return
        }

        if (!response.ok) {
          setError('Error fetching CSV data')
          return
        }
        const csvText = await response.text()
        const blob = new Blob([csvText], { type: 'text/csv' })
        const file = new File([blob], 'data.csv', { type: 'text/csv' })
        setCsvFile(file)
      } catch (error) {
        console.error('Error fetching CSV data:', error)
      }
    }
  }, [url])
  useEffect(() => {
    if (url) fetchCsvData()
  }, [fetchCsvData, url])

  useEffect(() => {
    if (csvFile && inputRef.current) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(csvFile)
      inputRef.current.files = dataTransfer.files
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [csvFile])

  const handleCsvData = (data: IInventory[]) => {
    setCsvData(data)
    setLoading(false)
  }

  return (
    <div>
      <CSVReader
        onFileLoaded={handleCsvData}
        ref={inputRef}
        inputStyle={{ display: 'none' }}
        parserOptions={{ header: true }}
      />
      {error && <p className="text-center text-red-600">{error}</p>}
      {url && !error && (
        <InventoryTable data={csvData ?? []} loading={loading} url={url} />
      )}
    </div>
  )
}

const InventoryTable = ({
  url,
  data,
  loading,
}: {
  url: string
  data: IInventory[]
  loading: boolean
}) => {
  const [pageMeta, setPageMeta] = useState({
    page: 1,
    pageSize: 10,
  })

  const headers = useMemo(() => {
    let info = data.length
      ? Object.keys(data[0]).map(key => ({ key, label: key }))
      : []

    info = [
      { key: 'ImageList', label: 'Image' },
      ...info.filter(i => i.key !== 'ImageList'),
    ]
    return info
  }, [data])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const fakeAppointments = useMemo(() => [...new Array(10).fill(1)], [])
  const pageData: IInventory[] = useMemo(() => {
    if (loading) return fakeAppointments as IInventory[]
    const currentData = data.slice(
      pageMeta.pageSize * (pageMeta.page - 1),
      pageMeta.pageSize * pageMeta.page,
    )
    return currentData ?? []
  }, [data, fakeAppointments, loading, pageMeta])
  return (
    <WithPaginator
      data={pageData}
      loading={loading}
      totalItems={data.length}
      initialPage={pageMeta.page}
      onPageMetaChange={onPageMetaChange}
      initialPageSize={pageMeta.pageSize}
    >
      {pagedData => (
        <div className="w-full">
          <Button
            className="btn-outline btn-sm max-w-max"
            onClick={() => {
              window.open(url, '_blank')
            }}
          >
            Download
          </Button>
          <DataTable
            className="inventory-table"
            data={pagedData}
            headers={headers}
          >
            {data =>
              loading ? (
                <TableLoadingSkeleton rows={10} className="py-4" cols={10} />
              ) : (
                data.map((datum, index) => {
                  const keys = Object.keys(datum).filter(
                    el => el !== 'ImageList',
                  )
                  const data = { ...datum }
                  const images =
                    data['ImageList' as keyof IInventory]?.split(',')
                  const image = images?.[0]
                  delete data['ImageList' as keyof IInventory]
                  return (
                    <tr key={index}>
                      <td>
                        <img
                          className="w-16"
                          src={image ?? noImage}
                          alt="Inventory image"
                        />
                      </td>
                      {keys.map(key => (
                        <td key={key} title={datum[key as keyof IInventory]}>
                          <span className="max-w-[200px] block !truncate">
                            {data[key as keyof IInventory]}
                          </span>
                        </td>
                      ))}
                    </tr>
                  )
                })
              )
            }
          </DataTable>
        </div>
      )}
    </WithPaginator>
  )
}

export default FileViewer
