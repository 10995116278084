import React, { useMemo } from 'react'
import { ArrowLeftIcon, PencilIcon } from '@heroicons/react/24/solid'

import { Button, LoadingSkeleton, WithSkeleton } from '@/components'
import { joinArray } from '@/utils'

import { useLocalWorkflow, Confirmation, Combinators } from './'
import { dynamicFields } from './constants'

export const SelectOptions: React.FC = () => {
  const { toStep, payload, templates, workflow } = useLocalWorkflow()

  const templateName = useMemo(
    () =>
      templates?.find?.(
        _v =>
          (payload?.workflowTemplate ?? workflow?.workflowTemplate) === _v.id,
      )?.name,
    [payload, workflow, templates],
  )

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="w-1/2">
          <Button
            onClick={() => toStep('1')}
            className="flex gap-2 items-center mb-8"
          >
            <ArrowLeftIcon className="w-5 h-5" /> Edit workflow selection
          </Button>
        </div>
        {!!workflow && (
          <div className="flex w-1/2 items-center justify-center">
            <WithSkeleton
              loading={!templateName}
              skeleton={<LoadingSkeleton className="h-[28px] w-3/4 rounded" />}
            >
              <div
                className="font-medium text-lg"
                dangerouslySetInnerHTML={{
                  __html: joinArray(
                    ' ',
                    'Selected workflow template:  ',
                    '<span class="font-semibold">',
                    templateName,
                    '</span>',
                  ),
                }}
              />
              <PencilIcon
                onClick={() => toStep('1')}
                className="ml-2 w-5 h-5 text-indigo-600 cursor-pointer"
              />
            </WithSkeleton>
          </div>
        )}
      </div>
      {payload.template?.type === 'appointmentConfirmation' ? (
        <Confirmation />
      ) : (
        <Combinators />
      )}
      <hr className="my-4" />
      <p className="font-semibold text-lg">Available dynamic fields:</p>
      {dynamicFields.join(', ')}
    </div>
  )
}
