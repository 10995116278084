import React from 'react'
import classNames from 'classnames'

import { IPageButtonsProps } from './types'


export const PageButtons: React.FC<IPageButtonsProps> = ({
                                                           range,
                                                           disabled,
                                                           currentPage,
                                                           onPageChange,
                                                         }) => {

  return (
    <>
      {range.map((page, idx) => {
        const previousPage = range?.[idx - 1] || 0
        return (
          <React.Fragment key={idx}>
            {+page - +previousPage > 1 && <li><span className="empty">...</span></li>}
            <li
              className={classNames({
                disabled,
                active: currentPage === page,
              })}
              onClick={() => !isNaN(+page) && onPageChange && onPageChange?.(+page)}
            >
              {page}
            </li>
          </React.Fragment>
        )
      })}
    </>
  )
}