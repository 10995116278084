import { AxiosResponse } from 'axios'

import { endpoints, usePaginatedRequest, usePost } from '@/apis'
import {
  ITask,
  TFilters,
  IGetEventImagePayload,
  ITriggerAutomationActionPayload,
} from '@/types'

export const useAllTasks = ({
  enabled,
  refreshKey,
  ...rest
}: Partial<TFilters> & { enabled: boolean; refreshKey?: string }) => {
  const response = usePaginatedRequest<ITask>({
    enabled,
    params: {
      queryParams: Object.keys(rest)
        // @ts-ignore
        .filter(key => !!rest[key])
        // @ts-ignore
        .map(key => ({ [key]: rest[key] })),
    },
    refreshKey,
    path: endpoints.allTask,
  })
  return response
}

export const useGetTaskEventImg = (
  onSuccess?: (_data: AxiosResponse<string>) => void,
) => {
  return usePost<IGetEventImagePayload, string>(
    endpoints.eventImagePath,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useTaskAcknowledged = () => {
  return usePost<ITriggerAutomationActionPayload>(
    endpoints.acknowledged,
    undefined,
  )
}

export const useRetryAutomation = () => {
  return usePost<ITriggerAutomationActionPayload>(
    endpoints.automationsRetry,
    undefined,
  )
}
