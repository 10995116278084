import React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { WorkflowContextProvider } from '@/context'
import { Button, TextLink } from '@/components'
import { generatePath, homePath, organizationsPath } from '@/utils'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'

export const V2DashboardLayout: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <WorkflowContextProvider>
      <header className="bg-white shadow">
        <div className="container mx-auto flex justify-between p-5">
          <TextLink to={homePath} className="-m-1.5 p-1.5">
            <img
              alt=""
              className="h-6 w-auto"
              src="https://matador-crm.vercel.app/logo-01.png"
            />
          </TextLink>
          <Button
            className="btn-outline flex items-center"
            onClick={() => navigate(generatePath(organizationsPath, { id }))}
          >
            <ArrowUturnLeftIcon className="w-5 h-5 mr-2" />
            Back to v1
          </Button>
        </div>
      </header>
      <Outlet />
    </WorkflowContextProvider>
  )
}
