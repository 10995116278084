import React from 'react'
import classNames from 'classnames'

import { IDataTableProps } from './types'
import './styles.scss'

export const DataTable = <T extends object>({
  data,
  loading,
  headers,
  children,
  className,
  EmptyDataEl,
}: IDataTableProps<T>) => {
  return (
    <div className={classNames('data-table', className)}>
      <table>
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th
                key={idx}
                scope="col"
                className={classNames({
                  sortable: header.sortable,
                  [header.className ?? '']: !!header.className,
                })}
              >
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length || 1} className="py-4 no-data">
                {EmptyDataEl || (
                  <div className="empty-table">
                    <p>No record found</p>
                  </div>
                )}
              </td>
            </tr>
          ) : (
            children(data)
          )}
        </tbody>
      </table>
    </div>
  )
}

export * from './types'
