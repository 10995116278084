import React, { ReactNode, useCallback, useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Modal } from '@/components'
import { useUnusedFeeds } from '@/hooks'
import { InventoryType, IResponse } from '@/types'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import FileViewer from '@/pages/Inventory/components/fileViewer'
import { useDealerFile } from '@/apis/hooks/inventory'
import moment from 'moment'
import { DATE_FORMAT } from '@/constants'

interface FeedsModalProps {
  isOpen: boolean
  onClose: () => void
}

const Accordion = ({
  label,
  children,
  defaultOpen = false,
}: {
  label: string
  children: ReactNode
  defaultOpen?: boolean
}) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div>
          <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left text-base font-semibold leading-6 text-gray-900 rounded-lg bg-indigo-50">
            <span className="capitalize">{label}</span>
            <ChevronUpIcon
              className={`${
                !open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-700`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pb-2 px-3">{children}</Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

const UnusedFeedsModal = ({ isOpen, onClose }: FeedsModalProps) => {
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const { feeds } = useUnusedFeeds()
  const [feed, setFeed] = React.useState<{
    vendor: InventoryType
    dealer: string
  } | null>(null)

  const onFileUrlSuccess = useCallback(
    (response: IResponse<{ url: string }>) => {
      setFileUrl(response.data.url)
    },
    [],
  )

  const [getDealerFile, { isFetching }] = useDealerFile(onFileUrlSuccess)

  useEffect(() => {
    if (feed) {
      getDealerFile({
        pathParams: { dealer: feed.dealer, vendor: feed.vendor },
      })
    }
  }, [feed, getDealerFile])
  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[90vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10 flex flex-col">
        <div className="flex flex-row justify-between items-center mb-8">
          <h1 className="font-semibold text-2xl">Unused Feeds</h1>
          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <div className="py-6 flex gap-8">
          <div className="flex flex-col gap-4 w-1/4">
            {Object.keys(feeds).map((vendor, index) => {
              return (
                <Accordion label={vendor} key={index} defaultOpen={index === 0}>
                  <div className="divide-y divide-gray-100">
                    {feeds[vendor as InventoryType].map(
                      ({ dealer, modifiedAt }, idx) => (
                        <div
                          key={idx}
                          className="flex flex-row justify-between px-4 py-2"
                        >
                          <div className="flex justify-between items-center gap-2 w-full group">
                            <div>
                              <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                                {dealer}
                              </p>
                              <p className="whitespace-nowrap text-xs leading-5 text-gray-500 mt-1">
                                Last modified:{' '}
                                <span>
                                  {moment(modifiedAt).format(DATE_FORMAT)}
                                </span>
                              </p>
                            </div>

                            <button
                              className="text-indigo-500 hidden group-hover:block transition-all"
                              onClick={() =>
                                setFeed({
                                  vendor: vendor as InventoryType,
                                  dealer,
                                })
                              }
                            >
                              Details
                            </button>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </Accordion>
              )
            })}
          </div>
          <div className="w-3/4">
            <FileViewer url={fileUrl} defaultLoading={isFetching} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UnusedFeedsModal
