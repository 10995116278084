import React, { ReactNode, useEffect, useState } from 'react'
import { Card } from '@/pages/AIJobs/components/sectionCard'
import moment from 'moment-timezone'
import { GetSuperhumanJob } from '@/types'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import InventorySearch from '@/pages/AIJobs/components/inventorySearch'
import { useAIJobs } from '@/hooks/useAIJobs'
import classNames from 'classnames'
import { TextInput } from '@/components'

const TimeBox = ({ text, timezone }: { text: string; timezone: string }) => {
  const [time, setTime] = useState(moment().tz(timezone).format('hh:mm:ss A'))
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().tz(timezone).format('hh:mm:ss A'))
    }, 1000)

    return () => clearInterval(interval)
  }, [timezone])
  return (
    <div className="flex flex-col gap-2 bg-indigo-600 px-4 py-3 md:px-6">
      <div className="text-2xl font-semibold text-white">{time}</div>
      <div className="text-sm text-white">{text}</div>
    </div>
  )
}

const OrganizationIntentList = ({
  instructions,
}: {
  instructions: { [key: string]: string }
}) => {
  const [search, setSearch] = useState('')
  if (!Object.keys(instructions).length) {
    return null
  }
  const intents = Object.entries(instructions).sort((a, b) => {
    return a[0].localeCompare(b[0])
  })

  const filteredIntents = search
    ? intents.filter(([key]) => {
        return key.toLowerCase().includes(search.toLowerCase())
      })
    : intents
  return (
    <div className="mt-3">
      <Disclosure>
        {({ open }) => (
          <div>
            <Disclosure.Button className=" flex w-full justify-between items-center mb-3">
              <h4 className="font-semibold text-xl mt-2">
                Organization intents list
              </h4>
              <ChevronUpIcon
                className={`${!open ? 'rotate-180 transform' : ''} h-5 w-5 mr-4`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pb-2">
              <div className="flex flex-col gap-2">
                <TextInput
                  className="mb-3"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search intent"
                />
                {filteredIntents.map(([key, value]) => {
                  return (
                    <Accordion label={key}>
                      <div className="whitespace-break-spaces">
                        {value || 'No specials'}
                      </div>
                    </Accordion>
                  )
                })}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

const Accordion = ({
  label,
  children,
  isImportant,
}: {
  label: string
  children: ReactNode
  isImportant?: boolean
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <Disclosure.Button className=" flex w-full justify-between px-4 py-2 text-left text-base font-semibold leading-6 text-gray-900 rounded-lg bg-indigo-50">
            <span className="flex items-center gap-2">
              {label}
              {isImportant ? (
                <span className="w-2 h-2 bg-green-600 rounded-full"></span>
              ) : null}
            </span>
            <ChevronUpIcon
              className={`${
                !open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-700`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pb-2 px-3">{children}</Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
const Organization = ({ job }: { job: GetSuperhumanJob }) => {
  const { vendor } = useAIJobs()
  return (
    <React.Fragment>
      <Card
        title={job?.organization?.name}
        extraChildren={
          <>
            <TimeBox
              text={`Current time in ${job.timezone}`}
              timezone={job.timezone}
            />
            {vendor ? (
              <div className="flex flex-col gap-2 ml-5 mr-5 mt-5">
                <h4 className="font-semibold text-xl mb-2">Inventory Search</h4>
                <InventorySearch
                  organization={job?.organization?._id}
                  vendor={vendor}
                />
              </div>
            ) : null}
          </>
        }
      >
        {Object.entries(
          job?.organization?.instructions?.customized_intents,
        ).map(([key, value], i) => {
          return (
            <div key={key}>
              {i === 0 ? (
                <h4 className="font-semibold text-xl mt-2">
                  Intent instructions
                </h4>
              ) : null}
              <Accordion label={key} isImportant>
                <div>{value || 'No specials'}</div>
              </Accordion>
            </div>
          )
        })}
        <h4 className="font-semibold text-xl my-2">Global instructions</h4>
        <div className="flex flex-col gap-2">
          <Accordion label="General instructions">
            <div>
              {job?.organization?.instructions.general ||
                'No general instructions'}
            </div>
          </Accordion>
          <Accordion label="Promotions">
            <div>
              {job?.organization?.instructions.promotions || 'No specials'}
            </div>
          </Accordion>
          <Accordion label="Human instructions">
            <div>{job?.organization?.instructions.human || 'No specials'}</div>
          </Accordion>
          <OrganizationIntentList
            instructions={
              job?.organization?.instructions.organization_instructions
            }
          />
        </div>
      </Card>
      <Card>
        <h6 className="font-semibold pb-4 mb-4 border-b border-gray-300">
          Opening hours ({job?.timezone})
        </h6>
        {Object.keys(job?.organization.openingHours || {}).map(day => {
          return job?.organization.openingHours?.[day].isOpen ? (
            <div key={day}>
              <span className="font-semibold">{day}</span>{' '}
              {job?.organization.openingHours?.[day].from} -{' '}
              {job?.organization.openingHours?.[day].to}
            </div>
          ) : null
        })}
        {job?.organization?.customWorkingHours?.length ? (
          <div className="py-4 mt-4">
            <Accordion label="Custom working hours">
              <div className="flex flex-col gap-2 mt-4">
                {job?.organization?.customWorkingHours.map((hour, i) => {
                  return (
                    <div
                      key={i}
                      className={classNames('flex gap-2', {
                        'opacity-40': moment(hour.date, 'YYYY/MM/DD').isBefore(
                          moment(),
                        ),
                      })}
                    >
                      <span className="font-semibold">
                        {moment(hour.date).format('YYYY-MM-DD')}
                      </span>
                      {hour.from} - {hour.to}
                      <span
                        className={classNames(
                          'ml-auto',
                          hour.isOpen ? 'text-green-600' : 'text-red-500',
                        )}
                      >
                        {hour.isOpen ? 'Open' : 'Closed'}
                      </span>
                    </div>
                  )
                })}
              </div>
            </Accordion>
          </div>
        ) : null}
        {job?.location ? (
          <div className="py-4 mt-4 border-t border-gray-300">
            <h6>{job?.location?.name} address</h6>
            <p>{job?.location?.address}</p>
          </div>
        ) : null}
      </Card>
    </React.Fragment>
  )
}

export default Organization
