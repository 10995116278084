import React, { useCallback, useEffect, useState } from 'react'

import { Checkbox, Select } from '@/components'
import { ICustomField } from '@/types'

interface IActivixProps {
  current: string | boolean
  field: ICustomField
  onChange: (_value: string | boolean) => void
}
export const Activix: React.FC<IActivixProps> = ({
  current,
  field,
  onChange,
}) => {
  const [value, setValue] = useState<any>()
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(!!current)
    setValue(current)
  }, [current])

  const toggleActive = useCallback(() => {
    setActive(!active)
    onChange?.(!active ? value : undefined)
  }, [active, onChange, value])

  return (
    <div className="flex w-full justify-between mt-2">
      <Checkbox
        checked={active}
        label={field.viewName}
        onChange={toggleActive}
      />
      <Select
        className="w-1/2"
        required={!active}
        disabled={!active}
        value={current ?? ''}
        placeHolder="Select an option"
        options={(field.options || []).map(option => ({
          label: option,
          value: option,
        }))}
        onChange={_option => onChange?.(_option.value)}
      />
    </div>
  )
}
