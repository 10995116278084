import React, { useEffect, useState } from 'react'
import HTTPClientInternal from '../httpClient'
import { Checkbox, Form, Select, TextInput } from '@/components'
import { registerFormFields } from '@/pages/A2p/components/constants'
import { useToast } from '@/hooks'
import { onError } from '@/apis'

export default function A2PForm() {
  const { addToast } = useToast()
  const [messagingServices, setMessagingServices] = useState([])
  const [organizationProfiles, setOrganizationProfiles] = useState<any[]>([])
  const [twilioProfiles, setTwilioProfiles] = useState([])
  const [brandRegistrations, setBrandRegistrations] = useState([])

  const [useSameProfile, setUseSameProfile] = useState(false)

  useEffect(() => {
    HTTPClientInternal('/profiles', 'GET').then(response => {
      setOrganizationProfiles(
        response.data.sort(
          (
            a: { organizationName: string },
            b: { organizationName: string },
          ) => {
            if (a.organizationName < b.organizationName) {
              return -1
            }
            if (a.organizationName > b.organizationName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
    HTTPClientInternal('/twilio/services', 'GET').then(response => {
      setMessagingServices(
        response.data.sort(
          (a: { friendlyName: string }, b: { friendlyName: string }) => {
            if (a.friendlyName < b.friendlyName) {
              return -1
            }
            if (a.friendlyName > b.friendlyName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
    HTTPClientInternal('/twilio/profiles', 'GET').then(response => {
      setTwilioProfiles(
        response.data.sort(
          (a: { friendlyName: string }, b: { friendlyName: string }) => {
            if (a.friendlyName < b.friendlyName) {
              return -1
            }
            if (a.friendlyName > b.friendlyName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
    HTTPClientInternal('/twilio/brand-registrations', 'GET').then(response => {
      setBrandRegistrations(
        response.data.sort(
          (a: { friendlyName: string }, b: { friendlyName: string }) => {
            if (a.friendlyName < b.friendlyName) {
              return -1
            }
            if (a.friendlyName > b.friendlyName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
  }, [])

  const submitForm = () => {
    HTTPClientInternal('/workflows', 'POST', {
      name: 'a2p_registration',
      form: {
        ...formState,
        profile_id: undefined,
        skip_automatic_set_vet: true,
        website_opt_in_link: '[for next step]',
        screenshot_url: '[for next step]',
        '': undefined,
      },
      profile: formState.profile_id.split(' - ')[0].trim(),
    })
      .then(res => {
        addToast('success', 'Form submitted successfully')
        setFormState({})
        return res.data
      })
      .catch(error => {
        if (error?.message) addToast('error', error.message)
        onError(error)
      })
  }

  useEffect(() => {
    if (useSameProfile) {
      setFormState({
        ...formState,
        ...registerFormFields.reduce((acc, field) => {
          // @ts-ignore
          acc[field.slug] = field.isSelectedValue
          return acc
        }, {}),
      })
    } else {
      setFormState({
        ...formState,
        ...registerFormFields.reduce((acc, field) => {
          // @ts-ignore
          acc[field.slug] = ''
          return acc
        }, {}),
      })
    }
  }, [useSameProfile])

  const commonFields = (
    params: { profile_id: any },
    messagingServices: {
      sid: any
      friendlyName: any
    }[],
    twilioProfiles: { sid: any; friendlyName: any }[],
    brandRegistrations: any[],
  ) => [
    {
      slug: 'profile_id',
      allowedValues: organizationProfiles
        ? organizationProfiles.map(
            (organizationProfile: {
              profileId: any
              organizationName: any
            }) => {
              return `${organizationProfile.profileId} - ${organizationProfile.organizationName}`
            },
          )
        : [],
    },
    {
      slug: 'messaging_service_sid',
      allowedValues: messagingServices
        ? messagingServices.map(
            (messagingService: { sid: any; friendlyName: any }) => {
              return `${messagingService.sid} - ${messagingService.friendlyName}`
            },
          )
        : [],
    },
    {
      slug: 'profile_bundle_sid',
      allowedValues: twilioProfiles
        ? twilioProfiles.map(
            (twilioProfile: { sid: any; friendlyName: any }) => {
              return `${twilioProfile.sid} - ${twilioProfile.friendlyName}`
            },
          )
        : [],
    },
    {
      slug: 'brand_registration_sid',
      allowedValues: brandRegistrations
        ? brandRegistrations.map(brandRegistration => {
            return `${brandRegistration.sid} - ${brandRegistration.friendlyName}`
          })
        : [],
    },
    {
      slug: 'skip_automatic_set_vet',
      defaultValue: true,
    },
  ]

  const [formState, setFormState] = useState<any>({})

  return (
    <div className="pb-6">
      <div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            submitForm()
          }}
          className="flex flex-col gap-4"
        >
          <div className={'form-check'}>
            <Checkbox
              label="Use Same Profile"
              checked={useSameProfile}
              onChange={val => {
                setUseSameProfile(val)
              }}
            />
          </div>
          {commonFields(
            { profile_id: 'profile_id' },
            messagingServices,
            twilioProfiles,
            brandRegistrations,
          ).map(field => {
            return field.slug === 'skip_automatic_set_vet' ? (
              <div className={'form-check'} key={field.slug}>
                <Checkbox
                  label="Skip Automatic Set Vet"
                  name={field.slug}
                  defaultChecked={true}
                  disabled={true}
                  checked={formState[field.slug]}
                  onChange={val => {
                    setFormState({
                      ...formState,
                      [field.slug]: val,
                    })
                  }}
                />
              </div>
            ) : (
              <div className="form-group" key={field.slug}>
                <Select
                  placeHolder="Select"
                  className="w-full block"
                  allowEmpty
                  label={field.slug}
                  value={formState[field.slug]}
                  onChange={option =>
                    setFormState({
                      ...formState,
                      [field.slug]: option?.value,
                    })
                  }
                  options={
                    field.allowedValues?.map(_v => ({
                      value: _v,
                      label: _v,
                    })) || []
                  }
                />
              </div>
            )
          })}
          <hr />
          {registerFormFields
            .filter(field => {
              if (useSameProfile) {
                return true
              } else {
                return (
                  field.slug !== 'profile_bundle_sid' &&
                  field.slug !== 'brand_registration_sid'
                )
              }
            })

            .map(field => {
              return field.allowedValues ? (
                <div className={'form-group'} key={field.slug}>
                  <Select
                    placeHolder="Select"
                    className="w-full block"
                    disabled={useSameProfile}
                    allowEmpty
                    label={field.slug}
                    value={formState[field.slug]}
                    onChange={option =>
                      setFormState({
                        ...formState,
                        [field.slug]: option?.value,
                      })
                    }
                    options={
                      field.allowedValues?.map(_v => ({
                        value: _v,
                        label: _v,
                      })) || []
                    }
                  />
                </div>
              ) : (
                <div className="form-group" key={field.slug}>
                  <TextInput
                    type="text"
                    label={field.slug}
                    className="block w-full mt-2"
                    disabled={
                      (field.disabled || useSameProfile) &&
                      field.slug !== 'customer_profile_address_customer_name' &&
                      field.slug !== 'business_info_website_url'
                    }
                    onChange={e =>
                      setFormState({
                        ...formState,
                        [field.slug]: e.target.value,
                      })
                    }
                    value={formState[field.slug] || ''}
                  />
                </div>
              )
            })}

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </div>
    </div>
  )
}
