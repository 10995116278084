import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import {
  Badge,
  Button,
  TextLink,
  DataTable,
  WithPaginator,
  TableLoadingSkeleton,
} from '@/components'
import { ellipsizeMiddle, generatePath, organizationsPath } from '@/utils'
import { useRetryAutomation, useTaskAcknowledged } from '@/apis'
import { ITask, TTaskStatus } from '@/types'
import { DATE_FORMAT } from '@/constants'
import { useAuth, useDashboard, useToast } from '@/hooks'

import { Filters, TaskDetails, headers } from './'

import './styles.scss'

export const TasksList: React.FC = () => {
  const [task, setTask] = useState<ITask>()
  const {
    onResetFilters,
    onUpdateFilters,
    filters,
    onPageMetaChange,
    isTasksLoading,
    data,
    pageMeta,
    totalItems,
  } = useDashboard()

  const fakeTasks = useMemo(() => [...new Array(10).fill(1)], [])

  const pageData: ITask[] = useMemo(() => {
    if (isTasksLoading) return fakeTasks
    return data[`${pageMeta.page}`] ?? []
  }, [data, fakeTasks, isTasksLoading, pageMeta.page])

  const onClose = useCallback(() => {
    setTask(undefined)
  }, [])

  const onSelect = useCallback((task: ITask) => {
    setTask(task)
  }, [])

  return (
    <>
      <WithPaginator
        totalItems={totalItems}
        loading={isTasksLoading}
        data={pageData as ITask[]}
        initialPage={pageMeta.page}
        initialPageSize={pageMeta.pageSize}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <>
            <Filters
              filters={filters}
              onReset={onResetFilters}
              onUpdate={onUpdateFilters}
            />
            <DataTable data={pagedData} headers={headers}>
              {data =>
                isTasksLoading ? (
                  <TableLoadingSkeleton className="py-4" rows={10} cols={4} />
                ) : (
                  data.map((datum, idx) => (
                    <ListItem onSelect={onSelect} key={idx} {...datum} />
                  ))
                )
              }
            </DataTable>
          </>
        )}
      </WithPaginator>
      <TaskDetails task={task} onClose={onClose} />
    </>
  )
}

export const ListItem: React.FC<
  ITask & { onSelect: (_task: ITask) => void }
> = ({ onSelect, ...item }) => {
  const { id } = useParams()

  const name = useMemo(
    () => (id ? item.location.name : item.organization.name),
    [item, id],
  )
  return (
    <tr>
      <td>
        <div className="flex items-center gap-x-4">
          <TextLink
            aria-label={name}
            to={generatePath(organizationsPath, { id: item.organization._id })}
          >
            {ellipsizeMiddle(name, 40)}
          </TextLink>
        </div>
      </td>
      <td>
        <div className="flex gap-x-3">
          <div className="font-mono text-sm leading-6 text-black">
            {ellipsizeMiddle(item.automation?.name || '', 35)}
          </div>
        </div>
      </td>
      <td>{moment(item.createdAt).format(DATE_FORMAT)}</td>
      <td>
        <StatusItem {...item} onSelect={onSelect} />
      </td>
    </tr>
  )
}

const StatusItem: React.FC<ITask & { onSelect: (_payload: ITask) => void }> = ({
  onSelect,
  ...item
}) => {
  const { addToast } = useToast()
  const {
    _id: taskId,
    status,
    automation: { _id: automationId },
  } = item
  const [action, setAction] = useState<'retried' | 'acknowledged'>()
  const { accesses } = useAuth()
  const { mutateAsync: acknowledgedTask } = useTaskAcknowledged()
  const { mutateAsync: retryAutomation } = useRetryAutomation()

  const onAction = useCallback(
    (action: 'retried' | 'acknowledged') => async () => {
      const retry = action === 'retried'
      const request = retry ? retryAutomation : acknowledgedTask
      const response = await request({ id: retry ? automationId : taskId })
      if (response) {
        addToast('success', 'Action succeeded')
      }
      setAction(action)
    },
    [retryAutomation, acknowledgedTask, automationId, taskId, addToast],
  )

  const showExtraButtons = useMemo(
    () => status === TTaskStatus.FAILED && !action,
    [action, status],
  )

  const _status = useMemo(
    () =>
      action === 'retried'
        ? TTaskStatus.PENDING
        : action === 'acknowledged'
          ? TTaskStatus.ACKNOWLEDGED
          : status,
    [action, status],
  )

  return (
    <>
      <Button onClick={() => _status !== 'pending' && onSelect(item)}>
        {action === 'retried' && (
          <span className="text-xs text-orange-400 mr-3">(Prioritized)</span>
        )}
        <Badge status={_status} />
      </Button>
      {showExtraButtons && accesses.AUTOMATIONS_WRITE && (
        <>
          <Button className="btn-retry" onClick={onAction('retried')}>
            Retry
          </Button>
          <Button
            className="btn-acknowledged"
            onClick={onAction('acknowledged')}
          >
            Acknowledge
          </Button>
        </>
      )}
    </>
  )
}
