import React from 'react'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'

import { ISwitchProps } from './types'
import './styles.scss'

export const ToggleButton = React.forwardRef<HTMLButtonElement, ISwitchProps>(
  ({ onChange, checked, title, className, disabled }, ref) => {
    return (
      <div className={classNames('flex items-center gap-2', className)}>
        <Switch.Group>
          <Switch
            ref={ref}
            checked={checked}
            onChange={onChange}
            className={classNames('switch-select', {
              enabled: checked,
            })}
            disabled={disabled}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames('switch-thumb', {
                enabled: checked,
              })}
            />
          </Switch>
          {title && (
            <Switch.Label className="text-sm font-medium">{title}</Switch.Label>
          )}
        </Switch.Group>
      </div>
    )
  },
)
