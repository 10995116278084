import React, { ReactNode } from 'react'
import classNames from 'classnames'

interface CardProps {
  title?: string | ReactNode
  children: ReactNode
  headerExtra?: ReactNode
  footer?: ReactNode
  extraChildren?: ReactNode
  bodyClassName?: string
  headerClassName?: string
  className?: string
}
export const Card = ({
  title,
  children,
  extraChildren,
  headerExtra,
  footer,
  className,
  bodyClassName,
  headerClassName,
  ...rest
}: CardProps) => {
  return (
    <div
      className={classNames('bg-white rounded-lg shadow', className)}
      {...rest}
    >
      {title && (
        <div
          className={classNames(
            'border-b border-gray-200 px-4 py-5 md:px-6 bg-gray-100 sticky top-0 z-10',
            headerClassName,
          )}
        >
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between md:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="font-semibold text-xl">{title}</h3>
            </div>
            <div className="ml-4 mt-4 flex flex-shrink-0">
              {headerExtra && headerExtra}
            </div>
          </div>
        </div>
      )}
      {extraChildren && extraChildren}

      <div className={classNames('px-4 py-5 md:px-6 w-full', bodyClassName)}>
        {children}
      </div>
      {footer && footer}
    </div>
  )
}
