import React, { useCallback, useState } from 'react'

import { useRevealPassword } from '@/apis'
import { Button } from '@/components'

interface IPasswordRevealProps {
  locationId: string
}

export const PasswordReveal: React.FC<IPasswordRevealProps> = ({
  locationId,
}) => {
  const [password, setPassword] = useState<string>('')

  const { mutateAsync: revealPassword } = useRevealPassword(locationId)

  const onRevealPassword = useCallback(async () => {
    try {
      const res = await revealPassword()
      setPassword(res.data.password)
    } catch (err) {
      alert('Failed to reveal password')
    }
  }, [revealPassword])

  return (
    <div className="flex items-center gap-3 my-2">
      <Button
        type="button"
        onClick={onRevealPassword}
        className="bg-indigo-600 hover:bg-indigo-500 text-white text-sm font-semibold py-1 px-4 rounded"
      >
        Reveal Password
      </Button>
      <div className="ml-4">
        {password && <div>Password is: {password}</div>}
      </div>
    </div>
  )
}
