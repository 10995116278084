import React, { useCallback, useEffect } from 'react'
import classNames from 'classnames'

import { Button, TextInput } from '@/components'
import { useGetSampleResults } from '@/apis'
import { useWorkflow } from '@/hooks'

import { useLocalWorkflow } from './hook'

export const SelectLocation: React.FC = () => {
  const { locations, organization } = useWorkflow()
  const { payload, templates, onUpdatePayload, toStep, onUpdateSamples } =
    useLocalWorkflow()

  const onSelect = useCallback(
    (field: 'location' | 'template' | 'name') => (value: any) => {
      const _payload = {
        crm: undefined,
        type: undefined,
        message: undefined,
        template: undefined,
        combinator: undefined,
        actions: { assignMessage: [] },
        sendAfter: { value: 0, unit: 'minutes' },
      }
      onUpdatePayload({
        [field]: value,
        ...(field === 'location' ? _payload : {}),
      })
    },
    [onUpdatePayload],
  )

  const { mutateAsync: fetchSamples } = useGetSampleResults()

  const onFetchSamples = useCallback(async () => {
    if (!organization?._id || !payload.combinator || !payload?.template?.crm) {
      return
    }
    const response = await fetchSamples({
      params: { organization: organization._id },
      crm: payload.template?.crm,
      combinator: payload?.combinator,
    })
    onUpdateSamples(response.data)
  }, [
    fetchSamples,
    onUpdateSamples,
    organization?._id,
    payload?.combinator,
    payload?.template?.crm,
  ])

  // fetch samples on combinator change
  useEffect(() => {
    onFetchSamples()
  }, [onFetchSamples])

  return (
    <div className="flex flex-col">
      <TextInput
        label="Name your workflow"
        value={payload?.name ?? ''}
        onChange={e => onSelect('name')(e.target.value)}
      />
      <h3 className="font-semibold py-3">Select a location</h3>
      <div className="flex flex-col gap-2">
        {locations.map(location => (
          <SelectCard
            key={location._id}
            label={location.name}
            itemKey={location._id}
            active={payload?.location}
            onSelect={onSelect('location')}
            description={`<b>${location.crm?.toUpperCase()}</b> workflows available`}
          />
        ))}
      </div>
      {!!payload?.location && (
        <>
          <h3 className="font-semibold py-3">Select a workflow template</h3>
          <div className="flex flex-col gap-2">
            {templates.map(template => (
              <SelectCard
                key={template.id}
                label={template.name}
                itemKey={template?.id}
                active={payload?.template?.id}
                description={template.description}
                onSelect={id =>
                  onSelect('template')(templates.find(t => t.id === id))
                }
              />
            ))}
          </div>
        </>
      )}
      <Button
        disabled={!payload.location || !payload?.name}
        className="btn-primary mt-2"
        onClick={() => toStep('2')}
      >
        Continue
      </Button>
    </div>
  )
}

interface ISelectCardProps {
  label: string
  active?: string
  itemKey: string
  description?: string
  onSelect?: (_itemKey: string) => void
}
export const SelectCard: React.FC<ISelectCardProps> = ({
  label,
  active,
  itemKey,
  onSelect,
  description,
}) => {
  return (
    <div
      onClick={() => onSelect?.(itemKey)}
      className={classNames('relative shadow border rounded px-5', {
        'ring-1': active === itemKey,
      })}
    >
      <input
        type="radio"
        value={itemKey}
        checked={active === itemKey}
        id={`listGroupRadioGrid${itemKey}`}
        onChange={() => onSelect?.(itemKey)}
        className="absolute top-[42%] end-0 me-3 text-xl"
      />
      <label
        className="flex flex-col py-3 pe-5"
        htmlFor={`listGroupRadioGrid${itemKey}`}
      >
        <strong className="font-semibold">{label}</strong>
        {!!description && (
          <span
            className="opacity-75 mt-2 text-xs"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </label>
    </div>
  )
}
