import React, { useEffect } from 'react'

import moment from 'moment'
import { Card } from '@/pages/AIJobs/components/sectionCard'
import { Button, ModalDialog, TextArea } from '@/components'
import { useAIJobs } from '@/hooks/useAIJobs'
import { useAuth } from '@/hooks'

interface ModalDialogProps {
  isOpen: boolean
  onClose: () => void
}

const CancelModal = ({ isOpen, onClose }: ModalDialogProps) => {
  const { cancelCurrentJob } = useAIJobs()
  const onConfirm = () => {
    cancelCurrentJob()
    onClose()
  }
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Cancel Job"
      footer={
        <div className="flex gap-3 items-center">
          <Button onClick={onClose} className="btn btn-secondary">
            Cancel
          </Button>
          <Button onClick={onConfirm} className="btn btn-danger">
            Confirm
          </Button>
        </div>
      }
    >
      <h4 className="text-xl font-semibold">
        Are you sure you want to cancel job?
      </h4>
    </ModalDialog>
  )
}

const SubmitModal = ({ isOpen, onClose }: ModalDialogProps) => {
  const { submitCurrentJob } = useAIJobs()
  const onConfirm = () => {
    submitCurrentJob()
    onClose()
  }
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Confirm Job"
      footer={
        <div className="flex gap-3 items-center">
          <Button onClick={onClose} className="btn btn-secondary">
            Cancel
          </Button>
          <Button onClick={onConfirm} className="btn btn-danger">
            Confirm
          </Button>
        </div>
      }
    >
      <h4 className="text-xl font-semibold">
        Are you sure you want to approve this job?
      </h4>
    </ModalDialog>
  )
}
const Conversation = () => {
  const [cancelModal, setCancelModal] = React.useState(false)
  const [submitModal, setSubmitModal] = React.useState(false)
  const scrollRef = React.createRef<HTMLUListElement>()
  const { message, setMessage, job } = useAIJobs()
  const { accesses } = useAuth()

  useEffect(() => {
    const width = window.innerWidth
    const section = document.getElementById('info-section')
    if (section && width >= 768) {
      section.scrollIntoView({ behavior: 'smooth' })
      scrollRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [])

  return (
    <Card
      title={
        <div>
          {job?.customer?.firstName + ' ' + job?.customer?.lastName}
          <p className="text-xs text-gray-500 mt-1">{job?.location?.name}</p>
        </div>
      }
      headerExtra={
        accesses.SUPERHUMAN_WRITE ? (
          <div className="flex items-center gap-3">
            <Button
              className="btn btn-danger"
              onClick={() => setCancelModal(true)}
            >
              Discard
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => setSubmitModal(true)}
            >
              Approve
            </Button>
          </div>
        ) : undefined
      }
      footer={
        <div className="border-t border-gray-300 pt-4 sticky bottom-0 bg-gray-100 px-4 py-5 md:px-6 w-full'">
          <TextArea
            className="flex flex-col gap-2"
            rows={2}
            label={
              <div className="flex justify-between items-center">
                <p>AI Generated message</p>
                {message !== job?.message ? (
                  <Button
                    className="btn btn-secondary max-w-max"
                    onClick={() => setMessage(job?.message || '')}
                  >
                    Reset
                  </Button>
                ) : null}
              </div>
            }
            onChange={e => setMessage(e.target.value)}
            value={message}
          />
        </div>
      }
    >
      <ul className="list-unstyled" ref={scrollRef}>
        {job?.messages
          ?.filter(message => message.body)
          ?.map(message => {
            return (
              <li key={message._id}>
                {message?.type === 'received' ? (
                  <div className="flex">
                    <div
                      className="media-body bg-white border border-dark p-3"
                      style={{
                        maxWidth: '80%',
                        borderRadius: '1rem',
                        margin: '10px',
                      }}
                    >
                      <h5 className="mt-0 mb-1">{message?.from}</h5>
                      {message?.body}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <div
                      className="media-body text-end bg-indigo-600 text-white border border-dark p-3"
                      style={{
                        maxWidth: '80%',
                        borderRadius: '1rem',
                        margin: '10px',
                      }}
                    >
                      <h6 className="mt-0 mb-1">{message?.from}</h6>
                      {message?.body}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    fontSize: '13px',
                    color: 'gray',
                    textAlign: message.type === 'received' ? 'left' : 'right',
                    marginTop: -5,
                    marginRight: 15,
                    marginLeft: 15,
                    marginBottom: 10,
                  }}
                >
                  {moment(message?.createdAt)
                    .tz(job?.timezone)
                    .format('DD/MM/YYYY hh:mm A')}{' '}
                  {message?.phoneNumber ? (
                    <span> - {message?.phoneNumber}</span>
                  ) : null}
                </div>
              </li>
            )
          })}
      </ul>
      {job?.jobId && (
        <CancelModal
          isOpen={cancelModal}
          onClose={() => setCancelModal(false)}
        />
      )}
      {job?.jobId && (
        <SubmitModal
          isOpen={submitModal}
          onClose={() => setSubmitModal(false)}
        />
      )}
    </Card>
  )
}

export default Conversation
