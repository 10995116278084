import React, { useCallback } from 'react'
import moment from 'moment'

import { appointmentStatusFilter, dateFilterFields } from '@/constants'
import { Button, DatePicker, Select, TextInput } from '@/components'
import { IAppointmentFilter, IFilterProps } from '@/types'

export const Filters: React.FC<IFilterProps<IAppointmentFilter>> = ({
  onReset,
  onUpdate,
  filters,
}) => {
  const onSelectValue = useCallback(
    (field: keyof IAppointmentFilter) => (value: any) => {
      let _value = value
      if (['startDate', 'endDate'].includes(field) && _value) {
        _value = moment(_value).toISOString()
        if (field === 'startDate') {
          const el = document.querySelectorAll(
            '.popover-trigger',
          )[1] as HTMLElement
          el?.click()
        }
      }

      onUpdate({ [field]: _value })
    },
    [onUpdate],
  )

  return (
    <div className="flex flex-col xl:flex-row gap-x-8 gap-y-4 py-5 items-end justify-between">
      <div className="grid w-full gap-5 items-center xl:grid-cols-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        <DatePicker
          className="w-full"
          label="Start Date"
          placeholder="Enter start date"
          onChange={onSelectValue('startDate')}
          maxDate={filters?.endDate ? new Date(filters?.endDate) : undefined}
          value={filters?.startDate ? new Date(filters?.startDate) : undefined}
        />
        <DatePicker
          className="w-full"
          label="End Date"
          placeholder="Enter end date"
          onChange={onSelectValue('endDate')}
          value={filters?.endDate ? new Date(filters?.endDate) : undefined}
          minDate={
            filters?.startDate ? new Date(filters?.startDate) : undefined
          }
        />
        <Select
          label="Sort by"
          className="w-full"
          options={dateFilterFields}
          placeHolder="Select sort..."
          onChange={value => onSelectValue('sortBy')(value.value)}
        />
        <TextInput
          label="Phone"
          className="w-full"
          value={filters.phoneNumber}
          placeholder="Enter phone number"
          onChange={e => onSelectValue('phoneNumber')(e.target.value)}
        />
        <TextInput
          label="Name"
          className="w-full"
          value={filters.name}
          placeholder="Enter name"
          onChange={e => onSelectValue('name')(e.target.value)}
        />
        <Select
          label="Status"
          className="w-full"
          value={filters.status}
          placeHolder="Select status..."
          options={appointmentStatusFilter}
          onChange={value => onSelectValue('status')(value.value)}
        />
      </div>
      <div className="lg:min-w-max">
        <Button className="btn-primary !py-[9px]" onClick={onReset}>
          Reset filters
        </Button>
      </div>
    </div>
  )
}
