import React from 'react'
import { Outlet } from 'react-router-dom'

import { AppFooter, AppHeader } from '@/layout'

import { Notifications } from './components'
import { useAuth } from '@/hooks'
import { Unauthorized } from '@/pages'
import { LocationContextProvider, UnusedFeedsContextProvider } from '@/context'

export const AppLayout: React.FC = () => {
  const { isNotPermitted } = useAuth()
  if (isNotPermitted) {
    return <Unauthorized />
  }
  return (
    <>
      <AppHeader />
      <LocationContextProvider>
        <UnusedFeedsContextProvider>
          <main>
            <Outlet />
            <Notifications />
          </main>
        </UnusedFeedsContextProvider>
      </LocationContextProvider>
      <AppFooter />
    </>
  )
}
