import { useAccessControl } from '@/hooks'
import React, { useCallback, useEffect } from 'react'
import { permissionValueType } from '@/types'

import { Form, Modal } from '@/components'
import PermissionFormTable from '@/pages/AccessControl/components/permissionFormTable'
import { XMarkIcon } from '@heroicons/react/24/solid'
import {
  ParsedPermission,
  PermissionValueTypeWithOrg,
} from '@matador/automations-lib/src/types/permissions'
import { combineParsedPermission } from '@matador/automations-lib/src/utils/permissions'

export const PermissionForm = () => {
  const {
    selectedUser,
    setSelectedUser,
    onSetPermission,
    grantAccess,
    onSetAccess,
    setGrantAccess,
  } = useAccessControl()
  const [list, setList] = React.useState<PermissionValueTypeWithOrg[]>(
    (selectedUser?.permissions as PermissionValueTypeWithOrg[]) || [],
  )
  const permissionList = list
    .map(el => {
      let orgLevel = el
      if (!el.includes('/')) {
        orgLevel = (orgLevel + '/[*]') as PermissionValueTypeWithOrg
      }
      return orgLevel as PermissionValueTypeWithOrg
    })
    .sort()

  const onSubmit = useCallback(
    (data: ParsedPermission[]) => {
      const list = combineParsedPermission(data)

      selectedUser &&
        (grantAccess
          ? onSetAccess({
              email: selectedUser?.email,
              permissions: list as PermissionValueTypeWithOrg[],
              hasAccess: true,
            })
          : onSetPermission({
              ...selectedUser,
              permissions: list as PermissionValueTypeWithOrg[],
            }))
    },
    [onSetPermission, selectedUser, grantAccess, onSetAccess],
  )

  const hidePermissionModal = () => {
    setSelectedUser(undefined)
    setGrantAccess(undefined)
  }

  useEffect(() => {
    setList((selectedUser?.permissions as PermissionValueTypeWithOrg[]) || [])
  }, [selectedUser])

  return (
    <Modal
      isOpen={!!selectedUser}
      onClose={hidePermissionModal}
      showCloseButton={false}
    >
      <div className="lg:w-[75vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-semibold text-2xl">{`Manage Permissions for ${selectedUser?.name || selectedUser?.email || ''}`}</h1>
          <XMarkIcon
            onClick={hidePermissionModal}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <div className="mt-6">
          <Form className="flex flex-col gap-6 items-center">
            <div className="flex gap-3 justify-between flex-wrap w-full">
              <PermissionFormTable
                data={permissionList}
                grantAccess={grantAccess}
                onSubmit={onSubmit}
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
