import React, { useMemo } from 'react'
import moment from 'moment'

import {
  TextLink,
  DataTable,
  WithPaginator,
  TableLoadingSkeleton,
} from '@/components'
import { ellipsizeMiddle, generatePath, organizationsPath } from '@/utils'
import { useAIBotCustomers } from '@/hooks'
import { DATE_FORMAT } from '@/constants'
import { IAIBotCustomer } from '@/types'

import { headers, Filters } from './'

export const AIBotCustomersList: React.FC = () => {
  const {
    onResetFilters,
    onUpdateFilters,
    filters,
    isLoading,
    totalItems,
    onPageMetaChange,
    data,
    pageMeta,
  } = useAIBotCustomers()

  const fakeCustomers = useMemo(() => [...new Array(10).fill(1)], [])

  const pageData: IAIBotCustomer[] = useMemo(() => {
    if (isLoading) return fakeCustomers
    return data[pageMeta.page] ?? []
  }, [data, fakeCustomers, isLoading, pageMeta.page])

  return (
    <WithPaginator
      data={pageData}
      loading={isLoading}
      totalItems={totalItems}
      initialPage={pageMeta.page}
      onPageMetaChange={onPageMetaChange}
      initialPageSize={pageMeta.pageSize}
    >
      {pagedData => (
        <>
          <Filters
            filters={filters}
            onReset={onResetFilters}
            onUpdate={onUpdateFilters}
          />
          <DataTable data={pagedData} headers={headers}>
            {data =>
              isLoading ? (
                <TableLoadingSkeleton
                  rows={10}
                  className="py-4"
                  cols={headers.length}
                />
              ) : (
                data.map((datum, idx) => <ListItem key={idx} {...datum} />)
              )
            }
          </DataTable>
        </>
      )}
    </WithPaginator>
  )
}

export const ListItem: React.FC<IAIBotCustomer> = ({ ...item }) => {
  return (
    <tr className="my-2">
      <td>
        <TextLink
          to={generatePath(organizationsPath, { id: item.organization._id })}
        >
          {ellipsizeMiddle(item.organization.name, 40)}
        </TextLink>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          {item.phoneNumber}
        </div>
      </td>
      <td>{moment(item.createdAt).format(DATE_FORMAT)}</td>
    </tr>
  )
}
