import React, { useEffect, useMemo } from 'react'
import { InventoryContextProvider } from '@/context/InventoryContext'
import { useInventory, useToast, useUnusedFeeds } from '@/hooks'
import {
  IImportJob,
  IInventoryAdmin,
  InventoryStatusTypes,
  InventoryType,
  IResponse,
} from '@/types'
import {
  Button,
  DataTable,
  TableLoadingSkeleton,
  WithPaginator,
} from '@/components'
import { headers } from './components/constants'
import ListItem from './components/listItem'
import CreateForm from '@/pages/Inventory/components/createForm'
import ImportJobs from '@/pages/Inventory/components/importJobs'
import { useImportJobs } from '@/apis/hooks/inventory'
import Filter from '@/pages/Inventory/components/filter'
import UnusedFeedsBanner from '@/pages/Dashboard/components/unusedFeedsBanner'
import { useSearchParams } from 'react-router-dom'
import UnusedFeedsModal from '@/pages/Inventory/components/feeds'
import ConfirmDeleteModal from '@/pages/Inventory/components/confirmDeleteModal'
import FileList from '@/pages/Inventory/components/fileListModal'
import EventListModal from '@/pages/Inventory/components/eventListModal'

const InventoryList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [eventMappingId, setEventMappingId] = React.useState<string | null>(
    null,
  )
  const { feeds } = useUnusedFeeds()
  const {
    data,
    pageMeta,
    onPageMetaChange,
    total,
    loading,
    toggleInventoryHandler,
    getOrganizationFiles,
  } = useInventory()
  const { addToast } = useToast()
  const [selectedMappingId, setSelectedMappingId] = React.useState<
    string | null
  >(null)
  const [importJobs, setImportJobs] = React.useState<IImportJob[] | null>(null)
  const [create, setCreate] = React.useState<boolean>(false)
  const [editItem, setEditItem] = React.useState<IInventoryAdmin | null>(null)
  const [deleteItem, setDeleteItem] = React.useState<IInventoryAdmin | null>(
    null,
  )
  const [showFiles, setShowFiles] = React.useState<null | InventoryType>(null)
  const fakeAppointments = useMemo(() => [...new Array(10).fill(1)], [])
  const onSuccess = (response: IResponse<IImportJob[]>) => {
    setImportJobs(response.data)
  }

  const onSearchClick = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault()
    // @ts-ignore
    const tr = e.target.closest('tr')
    const sibling = tr.nextElementSibling
    document.querySelectorAll('.inventory-search-row')?.forEach(row => {
      if (row !== sibling) row.classList.remove('expanded')
      else sibling.classList.toggle('expanded')
    })
    const isExpanded = document.querySelector('.inventory-search-row.expanded')
    const table = document.querySelector('.inventory-table') as HTMLTableElement
    if (table) table.style.overflow = isExpanded ? 'initial' : 'auto'
  }

  const [getImportJobs] = useImportJobs(onSuccess)

  const pageData: IInventoryAdmin[] = useMemo(() => {
    if (loading) return fakeAppointments
    return data[pageMeta.page] ?? []
  }, [data, fakeAppointments, loading, pageMeta.page])

  const onToggle = (id: string, status: InventoryStatusTypes) => {
    toggleInventoryHandler(id, status).then(res => {
      addToast('success', res.data?.message || '')
    })
  }
  const onEditAction = (data: IInventoryAdmin) => {
    setEditItem(data)
  }

  const onView = (id: string) => {
    setSelectedMappingId(id)
  }

  const onDelete = (data: IInventoryAdmin) => {
    setDeleteItem(data)
  }
  const onViewFiles = (
    organizationId: string,
    vendor: InventoryType,
    dealer: string,
  ) => {
    getOrganizationFiles(organizationId, dealer)
    setShowFiles(vendor)
  }

  const onAudit = (mappingId: string) => {
    setEventMappingId(mappingId)
  }

  useEffect(() => {
    if (selectedMappingId) {
      getImportJobs({ pathParams: { id: selectedMappingId } })
    } else {
      setImportJobs(null)
    }
  }, [getImportJobs, selectedMappingId])

  const modalTitle = useMemo(() => {
    return pageData.find(item => item.id === selectedMappingId)?.dealer_id || ''
  }, [pageData, selectedMappingId])

  const showFeeds = useMemo(
    () =>
      searchParams.get('show') === 'feeds' &&
      Object.values(feeds || {}).reduce((acc, curr) => acc + curr.length, 0) >
        0,
    [feeds, searchParams],
  )
  return (
    <div className="container mx-auto py-5">
      <UnusedFeedsBanner />
      <div className="flex justify-between mt-4">
        <h5 className="text-xl font-semibold">Inventory settings</h5>
        <div className="flex items-center gap-4">
          <Button
            className="btn-primary max-w-max"
            onClick={() => setCreate(true)}
          >
            Add new
          </Button>
        </div>
      </div>
      <Filter />
      <WithPaginator
        data={pageData}
        loading={loading}
        totalItems={total}
        initialPage={pageMeta.page}
        onPageMetaChange={onPageMetaChange}
        initialPageSize={pageMeta.pageSize}
      >
        {pagedData => (
          <div className="w-full">
            <DataTable
              className="inventory-table !overflow-visible"
              data={pagedData}
              headers={headers}
            >
              {data =>
                loading ? (
                  <TableLoadingSkeleton
                    rows={10}
                    className="py-4"
                    cols={headers.length}
                  />
                ) : (
                  data.map((datum, idx) => (
                    <ListItem
                      item={datum}
                      onEdit={onEditAction}
                      key={idx}
                      onSearchClick={onSearchClick}
                      onToggle={() => {
                        onToggle(datum.id, 'inactive')
                      }}
                      onView={onView}
                      onDelete={onDelete}
                      onViewFiles={onViewFiles}
                      onAudit={onAudit}
                    />
                  ))
                )
              }
            </DataTable>
          </div>
        )}
      </WithPaginator>
      {(editItem || create) && (
        <CreateForm
          isOpen={!!editItem || create}
          onClose={() => {
            setEditItem(null)
            setCreate(false)
          }}
          inventory={editItem}
        />
      )}
      {importJobs && (
        <ImportJobs
          isOpen={!!importJobs}
          onClose={() => setSelectedMappingId(null)}
          importJobs={importJobs}
          title={modalTitle}
        />
      )}
      {showFeeds && (
        <UnusedFeedsModal
          isOpen={showFeeds}
          onClose={() => setSearchParams(undefined, { replace: true })}
        />
      )}
      {deleteItem && (
        <ConfirmDeleteModal
          inventory={deleteItem}
          isOpen={!!deleteItem}
          onClose={() => setDeleteItem(null)}
        />
      )}
      {eventMappingId && (
        <EventListModal
          id={eventMappingId}
          isOpen={!!eventMappingId}
          onClose={() => setEventMappingId(null)}
        />
      )}
      <FileList
        isOpen={!!showFiles}
        vendor={showFiles}
        onClose={() => setShowFiles(null)}
      />
    </div>
  )
}

export const InventoryPage = () => {
  return (
    <InventoryContextProvider>
      <InventoryList />
    </InventoryContextProvider>
  )
}
