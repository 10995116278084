import React, { useEffect, useMemo, useState } from 'react'
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Feedback, Form, Modal, TextInput } from '@/components'
import {
  IInventoryAdmin,
  IInventoryAdminActionPayload,
  IInventoryStatus,
} from '@/types'
import { useDashboard, useInventory, useToast } from '@/hooks'
import SearchBar from '@/components/SearchBar'
import { isValidUrl } from '@/utils'
import { vendors } from '@/pages/Inventory/constants'

const formDefaultState = {
  id: '',
  organization_id: '',
  vendor: '',
  status: IInventoryStatus.INACTIVE,
  dealer_id: '',
  domain_url: [''],
}

interface IInventoryFormProps
  extends Omit<IInventoryAdminActionPayload, 'domain_url'> {
  domain_url: string[]
}
const CreateForm = ({
  isOpen,
  onClose,
  inventory,
}: {
  inventory?: IInventoryAdmin | null
  isOpen: boolean
  onClose: () => void
}) => {
  const [formError, setFormError] = useState<
    Partial<Record<keyof IInventoryAdminActionPayload, string>>
  >({})
  const [urlErrors, setUrlErrors] = useState<Record<string, string>>({})
  const [serverError, setServerError] = useState('')
  const { addToast } = useToast()
  const { updateInventoryHandler, updating, createInventoryHandler, creating } =
    useInventory()
  const { organizations } = useDashboard()
  const [form, setForm] = React.useState<IInventoryFormProps>(formDefaultState)

  const onSubmit = () => {
    setServerError('')
    setFormError({})
    setUrlErrors({})
    const values = { ...form }
    delete values.id
    const keys = Object.keys(values)

    const errors: Partial<Record<keyof IInventoryAdminActionPayload, string>> =
      {}
    keys
      .filter(key => key === 'domain_url')
      .forEach(key => {
        if (!form[key as keyof IInventoryAdminActionPayload]) {
          errors[key as keyof IInventoryAdminActionPayload] =
            'Field is required!'
        }
      })
    const urlErrorList: Record<string, string> = {}
    if (
      values.domain_url.length > 1 ||
      (values.domain_url.length === 1 && values.domain_url[0] !== '')
    ) {
      values.domain_url.forEach(url => {
        if (!isValidUrl(url)) {
          urlErrorList[url] = 'Invalid URL'
        }
      })
    }
    setUrlErrors(urlErrorList)
    if (Object.keys(errors).length || Object.keys(urlErrorList).length) {
      return
    }
    const action = inventory ? updateInventoryHandler : createInventoryHandler
    action({ ...form, domain_url: form.domain_url.join(',') })
      .then(res => {
        onClose()
        setForm(formDefaultState)
        addToast('success', res.data?.message || '')
      })
      .catch(error => {
        setServerError(error.response.data.error)
      })
  }

  useEffect(() => {
    if (inventory) {
      setForm({
        id: inventory?.id || '',
        organization_id: inventory?.organization?._id || '',
        status: inventory.status,
        vendor: inventory.vendor,
        dealer_id: inventory.dealer_id,
        domain_url: inventory.domain_url
          ? [...inventory.domain_url.split(',').map(v => v.trim())]
          : [''],
      })
    }
  }, [inventory])

  const organization = useMemo(() => {
    const current = organizations.find(_v => _v._id === form.organization_id)
    const label = current?.name || 'Select Organization...'
    const value = current?._id || ''
    return {
      label,
      value,
    }
  }, [form.organization_id, organizations])

  const vendor = useMemo(() => {
    const current = vendors.find(_v => _v.value === form.vendor)
    const label = current?.label || 'Select Vendor...'
    const value = current?.value || ''
    return {
      label,
      value,
    }
  }, [form.vendor])

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[50vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10  flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-semibold text-2xl">
            {inventory ? 'Edit' : 'Create'} Inventory
          </h1>
          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <div className="py-6 flex-1 flex">
          <Form className="flex flex-col gap-4 flex-1" onSubmit={onSubmit}>
            {serverError && (
              <div className="py-4">
                <Feedback status="error" title={serverError} />
              </div>
            )}
            <TextInput
              error={formError.dealer_id}
              placeholder="Dealer ID"
              label="Dealer ID"
              value={form.dealer_id}
              onChange={e =>
                setForm(prevState => ({
                  ...prevState,
                  dealer_id: e.target.value,
                }))
              }
            />
            <SearchBar
              error={formError.organization_id}
              label="Organization"
              placeholder="Select Organization..."
              options={organizations.map(_v => ({
                value: _v._id,
                label: _v.name,
              }))}
              onChange={_v => {
                setForm(prevState => ({
                  ...prevState,
                  // @ts-ignore
                  organization_id: _v.value,
                }))
              }}
              value={organization}
            />
            <SearchBar
              error={formError.vendor}
              label="Vendor"
              placeholder="Select Vendor..."
              options={vendors}
              onChange={_v => {
                setForm(prevState => ({
                  ...prevState,
                  // @ts-ignore
                  vendor: _v.value,
                }))
              }}
              value={vendor}
            />
            <div className="flex flex-col gap-4">
              {form.domain_url.map((url, index) => (
                <div key={index} className="flex items-start gap-3">
                  <TextInput
                    error={urlErrors[url]}
                    className="w-full"
                    placeholder="Domain URL"
                    label={'Domain URL' + (index > 0 ? ` (${index + 1})` : '')}
                    value={url}
                    onChange={e => {
                      setForm(prevState => ({
                        ...prevState,
                        domain_url: prevState.domain_url.map((v, i) =>
                          i === index ? e.target.value : v,
                        ),
                      }))
                    }}
                  />
                  {form.domain_url.length > 1 && (
                    <Button
                      className="btn btn-danger max-w-max mt-6"
                      type="button"
                      onClick={() => {
                        setForm(prevState => ({
                          ...prevState,
                          domain_url: prevState.domain_url.filter(
                            (_, i) => i !== index,
                          ),
                        }))
                      }}
                    >
                      <TrashIcon className="w-4 h-4 me-2" />
                      Remove
                    </Button>
                  )}
                </div>
              ))}
              <Button
                className="btn btn-primary max-w-max mt-2"
                type="button"
                onClick={() =>
                  setForm(prevState => ({
                    ...prevState,
                    domain_url: [...prevState.domain_url, ''],
                  }))
                }
              >
                Add Domain
              </Button>
            </div>
            <div className="flex justify-end gap-4 mt-auto">
              <Button
                className="btn btn-secondary gap-2 max-w-max"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                loading={updating || creating}
                className="btn btn-primary gap-2 max-w-max"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}

export default CreateForm
