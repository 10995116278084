import React, { ReactNode } from 'react'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

const statusClasses = {
  info: {
    bg: 'bg-blue-50',
    text: 'text-blue-400',
    icon: <InformationCircleIcon />,
  },
  success: {
    bg: 'bg-green-50',
    text: 'text-green-400',
    icon: <CheckCircleIcon />,
  },
  error: {
    bg: 'bg-red-50',
    text: 'text-red-400',
    icon: <XCircleIcon />,
  },
  warning: {
    bg: 'bg-yellow-50',
    text: 'text-yellow-400',
    icon: <ExclamationTriangleIcon />,
  },
}

interface FeedbackProps {
  status: keyof typeof statusClasses
  title: string | ReactNode
  onAction?: () => void
}
export const Feedback = ({
  status = 'info',
  title,
  onAction,
}: FeedbackProps) => {
  const statusClass = statusClasses[status]
  return (
    <div className={`${statusClass.bg} rounded-md bg-blue-50 p-4 my-3`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <span
            className={`h-5 w-5 ${statusClass.text} rounded-full flex items-center justify-center`}
          >
            {statusClass.icon}
          </span>
        </div>
        <div
          className={`ml-3 flex-1 md:flex md:justify-between gap-4 ${statusClass.text}`}
        >
          <p className="text-sm font-semibold">{title}</p>
          {onAction && (
            <div
              className={`text-sm font-semibold ${statusClass.text} cursor-pointer md:mt-0 mt-4`}
              onClick={onAction}
            >
              Details
              <span aria-hidden="true"> &rarr;</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
