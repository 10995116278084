import React, { useCallback } from 'react'
import { Select } from '@/components'
import { options, soldOptions } from '@/pages/Organization/components'
import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/solid'
import { IAutomationForm, IRule, ISingOrganizationType } from '@/types'
import FrequencyItem from '@/pages/Organization/components/Automations/components/frequencyItem'
import Button from '@/components/Button'
import { frequencyDefaultState } from '@/pages/Organization/components/Automations/components/constants'

interface FrequencyProps {
  rule?: IRule
  currentState: IAutomationForm
  setCurrentState: React.Dispatch<React.SetStateAction<IAutomationForm>>
  onChange: (field: string) => (value: any) => void
  organization?: ISingOrganizationType
}
const Frequency = ({
  rule,
  currentState,
  onChange,
  organization,
  setCurrentState,
}: FrequencyProps) => {
  const onFreqChange = useCallback(
    (index: number) => (field: string, value: any) => {
      setCurrentState(prevState => {
        const frequencies = [...prevState.frequencies]
        if (field === 'hour' || field === 'minute') {
          frequencies[index] = {
            ...frequencies[index],
            time: {
              ...frequencies[index].time,
              [field]: value,
            },
          }
        } else {
          frequencies[index] = {
            ...frequencies[index],
            [field]: value,
          }
        }
        return {
          ...prevState,
          frequencies,
        }
      })
    },
    [setCurrentState],
  )

  const addFrequency = useCallback(() => {
    setCurrentState(prevState => {
      return {
        ...prevState,
        frequencies: [
          ...prevState.frequencies,
          {
            ...frequencyDefaultState,
            time: {
              ...frequencyDefaultState.time,
              isoTimezone: organization?.timezone,
            },
          },
        ],
      }
    })
  }, [organization?.timezone, setCurrentState])

  const onDelete = useCallback(
    (index: number) => () => {
      setCurrentState(prevState => {
        const frequencies = prevState.frequencies.filter((_, i) => i !== index)
        return {
          ...prevState,
          frequencies,
        }
      })
    },
    [setCurrentState],
  )

  return (
    <div>
      {!!rule &&
        rule.type !== 'lead' &&
        rule.type !== 'appointment' &&
        rule.type !== 'workflow' &&
        rule.type !== 'aibot' && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Frequencies</h3>
            {currentState.frequencies.map((frequency, index) => (
              <div key={index} className="py-4">
                <FrequencyItem
                  frequency={frequency}
                  onChange={onFreqChange}
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                  organization={organization}
                  index={index}
                  onDelete={onDelete(index)}
                />
              </div>
            ))}
            <div className="flex justify-center my-4">
              <Button
                type="button"
                className="btn-outline w-full"
                onClick={addFrequency}
              >
                <PlusIcon className="w-5 h-5 mr-2" />
                Add Frequency
              </Button>
            </div>
          </div>
        )}

      {rule?.type === 'automation' && (
        <React.Fragment>
          {rule?.flags?.useFutureOffset && (
            <span className="bg-red-400 text-white p-2 mt-2 block w-full text-center">
              <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
              The offset is set for future dates
            </span>
          )}
          {rule &&
            rule?.crm !== 'vinsolutions' &&
            rule?.crm !== 'subarucareconnect' &&
            rule?.crm !== 'dealercenter' && (
              <React.Fragment>
                <div className="flex gap-5 w-full mt-2">
                  <div className="flex items-end gap-2 w-full">
                    <Select
                      required
                      placeHolder="Select Start offset"
                      allowEmpty
                      label="Start offset"
                      options={soldOptions}
                      className="w-1/2 block"
                      value={currentState?.start}
                      onChange={_v => onChange('start')(_v.value)}
                      name="start"
                    />
                    <Select
                      required
                      name="startUnit"
                      options={options}
                      className="w-1/2 block"
                      value={currentState?.startUnit}
                      onChange={_v => onChange('startUnit')(_v.value)}
                    />
                  </div>
                  <div className="flex items-end gap-2 w-full">
                    <Select
                      required
                      placeHolder="Select End offset"
                      allowEmpty
                      name="end"
                      label="End offset"
                      options={soldOptions}
                      className="w-1/2 block"
                      value={currentState?.end}
                      onChange={_v => onChange('end')(_v.value)}
                    />
                    <Select
                      required
                      name="endUnit"
                      options={options}
                      className="w-1/2 block"
                      value={currentState?.endUnit}
                      onChange={_v => onChange('endUnit')(_v.value)}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </div>
  )
}

export default Frequency
