import React from 'react'
import { Feedback } from '@/components'
import { useUnusedFeeds } from '@/hooks'
import { inventoryPath } from '@/utils'
import { useNavigate } from 'react-router-dom'

const UnusedFeedsBanner = () => {
  const navigate = useNavigate()

  const { feeds } = useUnusedFeeds()
  const onAction = () => {
    navigate(inventoryPath + '?show=feeds')
  }

  const feedsCount = Object.values(feeds || {}).reduce(
    (acc, curr) => acc + curr.length,
    0,
  )
  return feedsCount > 0 ? (
    <Feedback
      status="error"
      title={`You have ${feedsCount} unused inventory feed${feedsCount > 1 ? 's' : ''}`}
      onAction={onAction}
    />
  ) : null
}

export default UnusedFeedsBanner
