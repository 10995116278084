import React, { useMemo } from 'react'

import { DataTable, TableLoadingSkeleton, WithPaginator } from '@/components'
import { useOrganization } from '@/hooks'
import { ILead } from '@/types'

import { headers, ListItem, Filters } from '@/pages/Lead/components'

export const LeadsList: React.FC = () => {
  const {
    leadsFilter,
    onResetLeadsFilter,
    onUpdateLeadsFilter,
    leadsResponse,
  } = useOrganization()
  const {
    total,
    data,
    pageMeta,
    onPageMetaChange,
    loading: isLeadsLoading,
  } = leadsResponse

  const pageData: ILead[] = useMemo(() => {
    if (isLeadsLoading) return [...new Array(10).fill(1)]
    return data[pageMeta.page] ?? []
  }, [data, isLeadsLoading, pageMeta.page])

  return (
    <WithPaginator
      data={pageData}
      totalItems={total}
      loading={isLeadsLoading}
      initialPage={pageMeta.page}
      onPageMetaChange={onPageMetaChange}
      initialPageSize={pageMeta.pageSize}
    >
      {pagedData => (
        <>
          <Filters
            filters={leadsFilter}
            onReset={onResetLeadsFilter}
            onUpdate={onUpdateLeadsFilter}
          />
          <DataTable data={pagedData} headers={headers}>
            {data =>
              isLeadsLoading ? (
                <TableLoadingSkeleton
                  rows={10}
                  className="py-4"
                  cols={headers.length}
                />
              ) : (
                data.map((datum, idx) => <ListItem key={idx} {...datum} />)
              )
            }
          </DataTable>
        </>
      )}
    </WithPaginator>
  )
}
