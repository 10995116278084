import React from 'react'

import { TextLink } from '@/components'
import { homePath } from '@/utils'

import './styles.scss'
import { useAuth } from '@/hooks'

export const NotFoundPage: React.FC = () => {
  const { availableRoutes } = useAuth()
  console.log(availableRoutes)
  return (
    <div className="not-found">
      <div className="text-center">
        <p className="error-code">404</p>
        <h1 className="page-title">Page not found</h1>
        <p className="page-description">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="links-section">
          <TextLink
            to={
              availableRoutes?.[0]?.children?.[0]?.path ||
              availableRoutes[0]?.path ||
              homePath
            }
            className="go-back"
          >
            Go back home
          </TextLink>
        </div>
      </div>
    </div>
  )
}
