import React, {
  useMemo,
  useState,
  useEffect,
  createContext,
  useCallback,
} from 'react'

import {
  useInventoryAdmin,
  useInventoryAdminCreate,
  useInventoryAdminToggle,
  useInventoryAdminUpdate,
  useInventoryDelete,
  useOrganizationFiles,
  useOrganizationFileUrl,
} from '@/apis/hooks/inventory'
import { IUsePaginatedRequestResponse } from '@/apis'
import {
  IInventoryAdmin,
  IInventoryAdminActionPayload,
  IInventoryFile,
  InventoryStatusTypes,
  InventoryType,
  IResponse,
  ISuccessResponse,
} from '@/types'
import { AxiosResponse } from 'axios'

interface IInventoryContext
  extends IUsePaginatedRequestResponse<IInventoryAdmin> {
  toggleInventoryHandler: (
    id: string,
    status: InventoryStatusTypes,
  ) => Promise<AxiosResponse<ISuccessResponse, any>>
  createInventoryHandler: (
    inventory: IInventoryAdminActionPayload,
  ) => Promise<AxiosResponse<IResponse<IInventoryAdmin>, any>>
  updateInventoryHandler: (
    inventory: IInventoryAdminActionPayload,
  ) => Promise<AxiosResponse<ISuccessResponse, any>>
  deleteInventoryHandler: (
    inventoryId: string,
  ) => Promise<AxiosResponse<ISuccessResponse, any>>
  toggling: boolean
  creating: boolean
  updating: boolean
  deleting: boolean
  filesLoading: boolean
  filter: IInventoryFilter
  setFilter: React.Dispatch<React.SetStateAction<IInventoryFilter>>
  getOrganizationFiles: (organizationId: string, dealer: string) => void
  getOrganizationFileUrl: (fileName: string, vendor: InventoryType) => void
  files: IInventoryFile[] | null
  fileUrl: string | null
  setFiles: React.Dispatch<React.SetStateAction<IInventoryFile[] | null>>
  setFileUrl: React.Dispatch<React.SetStateAction<string | null>>
}
interface IInventoryFilter {
  status: InventoryStatusTypes | string
  organization: string
  vendor: string
}
export const InventoryContext = createContext({} as IInventoryContext)
export const InventoryContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [refreshKey, onInventoryRefresh] = useState<string>(`${Date.now()}`)
  const [filter, setFilter] = useState<IInventoryFilter>({
    status: '',
    organization: '',
    vendor: '',
  })

  const [files, setFiles] = React.useState<IInventoryFile[] | null>(null)
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)

  useEffect(() => {
    onInventoryRefresh(`${Date.now()}`)
  }, [filter])

  const queryOptions = useMemo(
    () => ({
      refreshKey,
      enabled: true,
      ...filter,
    }),
    [filter, refreshKey],
  )

  const { onPageMetaChange, pageMeta, data, total, loading } =
    useInventoryAdmin(queryOptions)

  useEffect(() => {
    onInventoryRefresh(`${Date.now()}`)
  }, [])

  const { mutateAsync: toggleInventory, isPending: toggling } =
    useInventoryAdminToggle()
  const { mutateAsync: createInventory, isPending: creating } =
    useInventoryAdminCreate()

  const { mutateAsync: deleteInventory, isPending: deleting } =
    useInventoryDelete()
  const { mutateAsync: updateInventory, isPending: updating } =
    useInventoryAdminUpdate()

  const onFilesSuccess = useCallback(
    (response: IResponse<IInventoryFile[]>) => {
      setFiles(response.data)
    },
    [],
  )

  const onFileUrlSuccess = useCallback(
    (response: IResponse<{ url: string }>) => {
      setFileUrl(response.data.url)
    },
    [],
  )

  const [organizationFiles, { isFetching: filesLoading }] =
    useOrganizationFiles(onFilesSuccess)
  const [organizationFileUrl] = useOrganizationFileUrl(onFileUrlSuccess)

  const toggleInventoryHandler = useCallback(
    async (id: string, status: InventoryStatusTypes) => {
      return await toggleInventory({ status, params: { id } }).then(res => {
        onInventoryRefresh(`${Date.now()}`)
        return res
      })
    },
    [toggleInventory],
  )

  const createInventoryHandler = useCallback(
    async (inventory: IInventoryAdminActionPayload) => {
      const payload = {
        organization_id: inventory.organization_id,
        vendor: inventory.vendor,
        dealer_id: inventory.dealer_id,
        status: inventory.status,
        domain_url: inventory.domain_url,
      }
      return await createInventory(payload).then(res => {
        onInventoryRefresh(`${Date.now()}`)
        return res
      })
    },
    [createInventory],
  )

  const updateInventoryHandler = useCallback(
    async (inventory: IInventoryAdminActionPayload) => {
      const payload = {
        organization_id: inventory.organization_id,
        status: inventory.status,
        vendor: inventory.vendor,
        dealer_id: inventory.dealer_id,
        domain_url: inventory.domain_url,
        params: {
          id: inventory.id || '',
        },
      }

      return await updateInventory(payload).then(res => {
        onInventoryRefresh(`${Date.now()}`)
        return res
      })
    },
    [updateInventory],
  )

  const deleteInventoryHandler = useCallback(
    async (inventoryId: string) => {
      return await deleteInventory({ params: { inventoryId } }).then(res => {
        onInventoryRefresh(`${Date.now()}`)
        return res
      })
    },
    [deleteInventory],
  )

  const getOrganizationFiles = useCallback(
    async (organizationId: string, dealer: string) => {
      return await organizationFiles({ pathParams: { organizationId, dealer } })
    },
    [organizationFiles],
  )

  const getOrganizationFileUrl = useCallback(
    async (fileName: string, vendor: InventoryType) => {
      return await organizationFileUrl({ pathParams: { fileName, vendor } })
    },
    [organizationFileUrl],
  )

  const value = useMemo(
    () => ({
      data,
      pageMeta,
      loading,
      total,
      onPageMetaChange,
      toggleInventoryHandler,
      createInventoryHandler,
      updateInventoryHandler,
      deleteInventoryHandler,
      getOrganizationFiles,
      getOrganizationFileUrl,
      toggling,
      creating,
      updating,
      deleting,
      filesLoading,
      filter,
      setFilter,
      files,
      fileUrl,
      setFiles,
      setFileUrl,
    }),
    [
      data,
      pageMeta,
      loading,
      total,
      onPageMetaChange,
      toggleInventoryHandler,
      createInventoryHandler,
      updateInventoryHandler,
      deleteInventoryHandler,
      getOrganizationFiles,
      getOrganizationFileUrl,
      toggling,
      creating,
      updating,
      deleting,
      filesLoading,
      filter,
      files,
      fileUrl,
    ],
  )

  return (
    <InventoryContext.Provider value={value}>
      {children}
    </InventoryContext.Provider>
  )
}
