import React, { useMemo, useState } from 'react'
import { Badge } from '@/components'
import { IUnionInventory, TTaskStatus } from '@/types'
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/16/solid'
import noImage from '@/assets/images/image-not-available.png'
import { checkImage } from '@/utils'
import InventoryItemDescription from '@/pages/AIJobs/components/itemDescription'

const InventoryDetails = ({ item }: { item: IUnionInventory }) => {
  const [realImage, setRealImage] = useState<string | undefined>(noImage)
  const image = item.imagelist?.split(',')?.[0]
  const categorizedOptions = useMemo(() => {
    return item['categorized options']
      ? item['categorized options']
          ?.split('~')
          ?.reduce<{ [key: string]: string[] }>((acc, curr) => {
            const [key, value] = curr.split('@')
            acc[key] ? acc[key].push(value) : (acc[key] = [value])
            return acc
          }, {}) || {}
      : null
  }, [item])

  checkImage(image).then(res => {
    setRealImage(res ? image : noImage)
  })
  return (
    <>
      <div>
        <img
          className="lg:max-w-prose w-full max-w-96"
          src={realImage}
          alt={item.model}
        />
      </div>
      <div className="flex flex-col gap-1">
        <h3 className="pb-3 text-xl font-semibold text-gray-500">
          Car Details
        </h3>
        <InventoryItemDescription title="Make" description={item.make} />
        <InventoryItemDescription title="Model" description={item.model} />
        <InventoryItemDescription title="Year" description={item.year} />
        <InventoryItemDescription
          title="Type"
          description={
            <Badge
              label={item.type}
              status={
                item.type === 'New'
                  ? TTaskStatus.COMPLETED
                  : TTaskStatus.PENDING
              }
            />
          }
        />
        {item.modelnumber && (
          <InventoryItemDescription
            title="Model Number"
            description={item.modelnumber}
          />
        )}
        {item.passengercapacity && (
          <InventoryItemDescription
            title="Passenger Capacity"
            description={item.passengercapacity}
          />
        )}
        <InventoryItemDescription title="Doors" description={item.doors} />
        <InventoryItemDescription title="Body" description={item.body} />
        <InventoryItemDescription title="Trim" description={item.trim} />
        <InventoryItemDescription
          title="Certified"
          description={
            item.certified?.toLowerCase() === 'true' ? (
              <CheckBadgeIcon className="text-green-600 w-6 h-6" />
            ) : (
              <ExclamationCircleIcon className="text-red-600 w-6 h-6" />
            )
          }
        />
        <InventoryItemDescription
          title="Drivetrain"
          description={item.drivetrain}
        />
        {item.factory_codes && (
          <InventoryItemDescription
            title="Factory Codes"
            description={item.factory_codes}
          />
        )}
        <InventoryItemDescription
          title="Fuel Type"
          description={item.fueltype}
        />
        {item.marketclass && (
          <InventoryItemDescription
            title="Market Class"
            description={item.marketclass}
          />
        )}
        {item.style_description && (
          <InventoryItemDescription
            title="Style Description"
            description={item.style_description}
          />
        )}
        {item.vin && (
          <InventoryItemDescription title="VIN" description={item.vin} />
        )}
        {item.location && (
          <InventoryItemDescription
            title="Location"
            description={item.location}
          />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <h3 className="pb-3 text-xl font-semibold text-gray-500">
          Engine Details
        </h3>
        {item.engineaspirationtype && (
          <InventoryItemDescription
            title="Aspiration Type"
            description={item.engineaspirationtype}
          />
        )}
        {item.engine_block_type && (
          <InventoryItemDescription
            title="Block Type"
            description={item.engine_block_type}
          />
        )}
        <InventoryItemDescription
          title="Description"
          description={item.enginedescription}
        />
        <InventoryItemDescription
          title="Cylinders"
          description={item.enginecylinders}
        />
        <InventoryItemDescription
          title="Displacement"
          description={item.enginedisplacement}
        />
        {item.enginedisplacementcubicinches && (
          <InventoryItemDescription
            title="Displacement Cubic Inches"
            description={item.enginedisplacementcubicinches}
          />
        )}
        {item.horsepower && (
          <InventoryItemDescription
            title="Horsepower"
            description={item.horsepower}
          />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <h3 className="pb-3 text-xl font-semibold text-gray-500">
          Exterior Details
        </h3>
        {item.ext_color_code && (
          <InventoryItemDescription
            title="Color Code"
            description={item.ext_color_code}
          />
        )}
        <InventoryItemDescription
          title="Color Generic"
          description={item.extcolorgeneric}
        />
        {item.extcolorhexcode && (
          <InventoryItemDescription
            title="Color Hex Code"
            description={
              <span className="flex items-center gap-4">
                {item.extcolorhexcode}
                <span
                  className="w-4 h-4 rounded-full"
                  style={{ backgroundColor: `#${item.extcolorhexcode}` }}
                ></span>
              </span>
            }
          />
        )}
        {item.exteriorcolor && (
          <InventoryItemDescription
            title="Color"
            description={item.exteriorcolor}
          />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <h3 className="pb-3 text-xl font-semibold text-gray-500">
          Transmission Details
        </h3>
        <InventoryItemDescription
          title="Type"
          description={item.transmission}
        />
        {item.transmissiontype && (
          <InventoryItemDescription
            title="Description"
            description={item.transmissiontype}
          />
        )}
        {item.transmissionspeed && (
          <InventoryItemDescription
            title="Speed"
            description={item.transmissionspeed}
          />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <h3 className="pb-3 text-xl font-semibold text-gray-500">
          Dealer Details
        </h3>
        {item.dealername && (
          <InventoryItemDescription
            title="Dealer Name"
            description={item.dealername}
          />
        )}
        {item.dealercontactname && (
          <InventoryItemDescription
            title="Dealer Contact Name"
            description={item.dealercontactname}
          />
        )}
        {item.dealeremail && (
          <InventoryItemDescription
            title="Dealer Email"
            description={item.dealeremail}
          />
        )}
        {item.dealerphone && (
          <InventoryItemDescription
            title="Dealer Phone"
            description={item.dealerphone}
          />
        )}
        {item['dealer fax'] && (
          <InventoryItemDescription
            title="Dealer Fax"
            description={item['dealer fax']}
          />
        )}
        <InventoryItemDescription
          title="Dealer Address"
          description={
            <span>
              {[
                item.dealeraddress,
                item.dealercity,
                item.dealerstate,
                item.dealerzip,
              ]
                .filter(el => el)
                .join(', ')}
            </span>
          }
        />
      </div>
      <div className="lg:col-span-2">
        <h3 className="pb-3 text-xl font-semibold text-gray-500">
          Description
        </h3>
        <div>{item.description}</div>
      </div>
      {item.options && (
        <div className="lg:col-span-2">
          <h3 className="pb-3 text-xl font-semibold text-gray-500">Options</h3>
          <div>{item.options}</div>
        </div>
      )}
      {item.features && (
        <div className="lg:col-span-2">
          <h3 className="pb-3 text-xl font-semibold text-gray-500">Features</h3>
          <div>{item.features}</div>
        </div>
      )}
      {categorizedOptions && (
        <div className="lg:col-span-2">
          <h3 className="pb-3 text-xl font-semibold text-gray-500">
            Categorized Options
          </h3>
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-12">
            {Object.keys(categorizedOptions).map(key => (
              <div key={key}>
                <span className="font-semibold">{key}</span>
                <ul>
                  {categorizedOptions[key].map((value, index) => (
                    <li key={index}> - {value}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default InventoryDetails
