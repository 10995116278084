import {
  GetSuperhumanJob,
  IAIJobAppointmentCancelPayload,
  IAIJobAppointmentEditPayload,
  IAIJobPayload,
  IAppointmentFilter,
  IAvailableJobCount,
  IHomenetInventory,
  IVAutoInventory,
} from '@/types'
import { endpoints, useLazyGet, usePaginatedRequest, usePost } from '@/apis'
import { AxiosResponse } from 'axios'

export const useQueueLength = (
  onSuccess?: (_data: IAvailableJobCount) => void,
) => {
  return useLazyGet(endpoints.aiJobsQueueLength, undefined, {
    onSuccess,
  })
}

export const useJob = (onSuccess?: (_data: GetSuperhumanJob) => void) => {
  return useLazyGet(endpoints.aiJobsJob, undefined, {
    onSuccess,
  })
}

export const useRequest = (
  onSuccess?: (_data: AxiosResponse<{ jobId: string }>) => void,
) => {
  return usePost<undefined, { jobId: string }>(
    endpoints.aiJobsRequest,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useAiJobSubmit = () => {
  return usePost<IAIJobPayload, GetSuperhumanJob>(
    endpoints.aiJobSubmit,
    undefined,
    undefined,
  )
}

export const useAIJobCancel = (
  onSuccess?: (_data: AxiosResponse<{ message: string }>) => void,
) => {
  return usePost<{ params: { id: string } }, { message: string }>(
    endpoints.aiJobCancel,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useAIJobAppointmentCancel = (
  onSuccess?: (_data: AxiosResponse<{ message: string; id: string }>) => void,
) => {
  return usePost<
    IAIJobAppointmentCancelPayload,
    { message: string; id: string }
  >(endpoints.aiJobAppointmentCancel, undefined, {
    onSuccess,
  })
}

export const useAIJobAppointmentEdit = (
  onSuccess?: (
    _data: AxiosResponse<{
      message: string
      appointment: { _id: string; eventStart: Date }
    }>,
  ) => void,
) => {
  return usePost<
    IAIJobAppointmentEditPayload,
    { message: string; appointment: { _id: string; eventStart: Date } }
  >(endpoints.aiJobAppointmentEdit, undefined, {
    onSuccess,
  })
}

export const useInventorySearch = ({
  enabled,
  refreshKey,
  ...rest
}: Partial<IAppointmentFilter> & {
  enabled: boolean
  refreshKey?: string
}) => {
  const response = usePaginatedRequest<IHomenetInventory | IVAutoInventory>({
    enabled,
    params: {
      queryParams: Object.keys(rest)
        // @ts-ignore
        .filter(key => !!rest[key])
        // @ts-ignore
        .map(key => ({ [key]: `${rest[key]}` })),
    },
    refreshKey,
    path: endpoints.superhumanInventorySearch,
  })
  return response
}
