import { endpoints, usePost } from '@/apis'
import { IListUser, ISetAccessControlPayload } from '@/types'

export const useAccessControlList = () => {
  return usePost<undefined, IListUser[]>(
    endpoints.accessControlList,
    undefined,
    undefined,
  )
}

export const useAccessControl = () => {
  return usePost<ISetAccessControlPayload, IListUser>(
    endpoints.accessControl,
    undefined,
    undefined,
  )
}
