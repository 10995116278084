import { IDataTableHeader } from '@/components'
import { ITask, TAutomationType } from '@/types'

export const headers: IDataTableHeader<ITask>[] = [
  {
    sortable: false,
    key: 'organization',
    label: 'Organization',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    sortable: false,
    key: 'launchesAt',
    label: 'Launches At',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
  },
]

export const filters: {
  name: string
  key: TAutomationType
  current: boolean
}[] = [
  { name: 'Automation Tasks', key: 'automation', current: true },
  { name: 'Appointment Tasks', key: 'appointment', current: false },
  { name: 'Lead Tasks', key: 'lead', current: false },
  { name: 'AI Bot', key: 'aibot', current: false },
]
