import React, { createContext, useCallback, useEffect, useMemo } from 'react'

import { useAccessControl, useAccessControlList, usePermission } from '@/apis'
import { IListUser, ISetAccessControlPayload } from '@/types'
import { PermissionValueTypeWithOrg } from '@matador/automations-lib/src/types/permissions'

interface IControlPayload extends ISetAccessControlPayload {
  permissions: PermissionValueTypeWithOrg[]
}
interface IAccessControlContext {
  isLoading: boolean
  isPermissionLoading: boolean
  grantAccess: string | undefined
  userList: IListUser[]
  onSetAccess: (
    _payload: ISetAccessControlPayload & {
      permissions: PermissionValueTypeWithOrg[]
    },
  ) => void
  onSetPermission: (_payload: IListUser) => void
  selectedUser?: IListUser
  setSelectedUser: React.Dispatch<React.SetStateAction<IListUser | undefined>>
  setGrantAccess: React.Dispatch<React.SetStateAction<string | undefined>>
}
export const AccessControlContext = createContext<IAccessControlContext>(
  {} as IAccessControlContext,
)

export const AccessControlContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [grantAccess, setGrantAccess] = React.useState<string | undefined>()
  const [userList, setUserList] = React.useState<IListUser[]>([])
  const [selectedUser, setSelectedUser] = React.useState<
    IListUser | undefined
  >()

  const { mutateAsync: fetchList, isPending: isListLoading } =
    useAccessControlList()
  const { mutateAsync: setAccess } = useAccessControl()

  const { mutateAsync: onPermission, isPending: isPermissionLoading } =
    usePermission()

  const onSetAccess = useCallback(
    async (payload: IControlPayload) => {
      const response = await setAccess(payload)
      const access = payload.hasAccess
      if (response.data) {
        if (!access) {
          setUserList(prev => prev.filter(user => user.email !== payload.email))
        } else {
          // @ts-ignore
          setUserList(prev => {
            const haveUser = prev.find(user => user.email === payload.email)
            if (!haveUser) {
              return [
                ...prev,
                {
                  email: payload.email,
                  hasAccess: payload.hasAccess,
                  name: payload.email,
                  permissions: payload.permissions,
                },
              ]
            } else {
              return prev.map(user => {
                if (user.email === payload.email) {
                  return {
                    ...user,
                    hasAccess: payload.hasAccess,
                  }
                }

                return user
              })
            }
          })
        }
      }
      setSelectedUser(undefined)
      setGrantAccess(undefined)
    },
    [setAccess],
  )

  const onSetPermission = useCallback(
    async (payload: IListUser) => {
      const response = await onPermission({
        email: payload.email,
        permissions: payload.permissions,
      })
      if (response.data) {
        setUserList(prev => {
          return prev.map(user => {
            if (user.email === payload.email) {
              return response.data
            }

            return user
          })
        })
      }
      setSelectedUser(undefined)
    },
    [onPermission],
  )

  const onFetchList = useCallback(async () => {
    const data = await fetchList()
    setUserList(data.data)
  }, [fetchList])

  useEffect(() => {
    onFetchList()
  }, [onFetchList])

  useEffect(() => {
    setSelectedUser(
      grantAccess
        ? {
            email: grantAccess,
            hasAccess: true,
            name: grantAccess,
            permissions: ['automations:read/[*]'],
          }
        : undefined,
    )
  }, [grantAccess])

  const value = useMemo(
    () => ({
      userList,
      grantAccess,
      setGrantAccess,
      onSetAccess,
      isLoading: isListLoading,
      isPermissionLoading,
      selectedUser,
      setSelectedUser,
      onSetPermission,
    }),
    [
      userList,
      grantAccess,
      onSetAccess,
      isListLoading,
      isPermissionLoading,
      selectedUser,
      onSetPermission,
    ],
  )

  return (
    <AccessControlContext.Provider value={value}>
      {children}
    </AccessControlContext.Provider>
  )
}
