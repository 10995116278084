import React from 'react'
import { AIJobsContextProvider } from '@/context'
import { useAIJobs } from '@/hooks/useAIJobs'
import { LoadingSkeleton, PageLoadingWrapper, ToggleButton } from '@/components'
import Organization from '@/pages/AIJobs/components/organization'
import Conversation from '@/pages/AIJobs/components/conversation'
import Actions from '@/pages/AIJobs/components/actions'

const AIJobsSkeleton = () => {
  return (
    <div
      className="grid grid-cols-1 md:grid-cols-10 h-full gap-3"
      id="info-section"
    >
      <div className="col-span-3 flex flex-col gap-8 md:max-h-screen overflow-y-auto px-1">
        <LoadingSkeleton height="100vh" width="100%" />
      </div>
      <div className="col-span-4 max-h-screen overflow-x-hidden overflow-y-auto px-1">
        <LoadingSkeleton height="100vh" width="100%" />
      </div>
      <div className="col-span-3 md:max-h-screen overflow-y-auto px-1">
        <LoadingSkeleton height="100vh" width="100%" />
      </div>
    </div>
  )
}

function AIJobs() {
  const {
    isAgentAvailable,
    job,
    aiJobTotalCount,
    aiJobDelayCount,
    changeAgentAvailability,
  } = useAIJobs()

  return (
    <PageLoadingWrapper>
      <div className="px-6 lg:px-8 h-full mx-auto pt-4 flex flex-col gap-5">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow md:p-6 md:col-span-2 col-span-1">
            <ToggleButton
              className="mb-2"
              title={isAgentAvailable ? 'Available' : 'Unavailable'}
              checked={isAgentAvailable}
              onChange={_val => changeAgentAvailability(_val)}
            />
            <p className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
              {!job && !isAgentAvailable
                ? 'Set yourself as available to start your shift'
                : ''}
              {job && !isAgentAvailable
                ? 'After completing the current job, you will not receive new jobs'
                : ''}
              {!job && isAgentAvailable ? 'Waiting for jobs...' : ''}
            </p>
          </div>

          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow md:p-6 col-span-1">
            <p className="font-semibold">Total jobs: {aiJobTotalCount}</p>
            <p className="font-semibold">Late jobs: {aiJobDelayCount}</p>
          </div>
        </div>
        {!job ? (
          aiJobTotalCount === 0 ? (
            <h2 className="text-center text-xl mt-6">No job available</h2>
          ) : isAgentAvailable ? (
            <AIJobsSkeleton />
          ) : null
        ) : job ? (
          <>
            <div
              className="grid grid-cols-1 md:grid-cols-10 h-full gap-3"
              id="info-section"
            >
              <div className="col-span-3 flex flex-col gap-8 md:max-h-screen overflow-y-auto px-1">
                <Organization job={job} />
              </div>
              <div className="col-span-4 max-h-screen overflow-x-hidden overflow-y-auto px-1">
                <Conversation />
              </div>
              <div className="col-span-3 md:max-h-screen overflow-y-auto px-1">
                <Actions job={job} />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </PageLoadingWrapper>
  )
}

export const AIJobsPage: React.FC = () => (
  <AIJobsContextProvider>
    <AIJobs />
  </AIJobsContextProvider>
)
