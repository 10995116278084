import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from '@/components'
import { a2pPath, pendingA2pPath, registrationA2pPath } from '@/utils'
import A2PForm from '@/pages/A2p/components/A2PForm'
import PendingForms from '@/pages/A2p/components/PendingForms'
import { useAuth, useToast } from '@/hooks'
import HTTPClientInternal from '@/pages/A2p/httpClient'

const tabs = [
  {
    label: 'Registration',
    key: registrationA2pPath,
  },
  {
    label: 'Pending',
    key: pendingA2pPath,
  },
]
export const A2p = () => {
  const { addToast } = useToast()
  const [syncing, setSyncing] = React.useState(false)
  const { section } = useParams()
  const [activeTab, setActiveTab] = React.useState(
    section || registrationA2pPath,
  )
  const { accesses } = useAuth()
  const navigate = useNavigate()
  const onTabChange = (tab: string) => {
    navigate(`${a2pPath}/${tab}`)
  }

  useEffect(() => {
    setActiveTab(section || registrationA2pPath)
  }, [section])

  const tabList = useMemo(() => {
    if (!accesses.ONBOARDING_READ) {
      return tabs.filter(tab => tab.key !== pendingA2pPath)
    }
    if (!accesses.ONBOARDING_WRITE) {
      return tabs.filter(tab => tab.key !== registrationA2pPath)
    }
    return tabs
  }, [accesses])

  const syncProfiles = () => {
    setSyncing(true)
    HTTPClientInternal('/profiles/sync', 'POST')
      .then(response => {
        if (response.data) {
          setSyncing(false)
          addToast('success', response.data.message)
        }
      })
      .catch(err => {
        addToast('error', err.message)
        setSyncing(false)
      })
  }

  return (
    <div className="container mx-auto px-5 flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <h1 className="mb-3 text-xl font-semibold">
          A2P registration dashboard
        </h1>
        <Button
          className="btn-primary max-w-max"
          onClick={syncProfiles}
          loading={syncing}
        >
          Sync Profiles
        </Button>
      </div>
      <div className="tabs-container">
        <nav className="flex w-full lg:w-auto overflow-auto" aria-label="Tabs">
          {tabList.map(tab => (
            <Button
              data-tab={tab.key}
              key={tab.key}
              className={classNames('tab', {
                active: tab.key === activeTab,
              })}
              onClick={() => onTabChange(tab.key)}
            >
              {tab.label}
            </Button>
          ))}
        </nav>
      </div>
      <div>
        {activeTab === registrationA2pPath && accesses.ONBOARDING_WRITE && (
          <A2PForm />
        )}
        {activeTab === pendingA2pPath && accesses.ONBOARDING_READ && (
          <PendingForms />
        )}
      </div>
    </div>
  )
}

export default A2p
