import React from 'react'

import { automationImg } from '@/utils'
import { Modal } from '@/components'
import { IPingTestResponse, TPingTestType } from '@/types'

const LoadingDesc: React.FC = () => {
  return (
    <>
      <h1>The automation is currently being tested.</h1>
      <h2>The process should not take more than 2 minutes.</h2>
    </>
  )
}

export const SuccessDesc: React.FC<Partial<IPingTestResponse>> = ({
  number,
  type,
}) => {
  return (
    <>
      <h1>
        The
        {type === 'weeklyreport' ? ' weekly report ' : ' automation '}
        has been setup successfully.
      </h1>
      <h2>You can know enjoy your extra time!</h2>
      {type !== 'weeklyreport' && (
        <h2>{number} customers were pulled during this test.</h2>
      )}
    </>
  )
}

const ErrorDesc: React.FC = () => {
  return (
    <>
      <h1>The automation setup has failed for some reason.</h1>
      <h2>
        Make sure you have submitted the correct settings, then try again.
      </h2>
    </>
  )
}

const desc: Record<TPingTestType, React.ElementType> = {
  failed: ErrorDesc,
  pending: LoadingDesc,
  completed: (props: Partial<IPingTestResponse>) => <SuccessDesc {...props} />,
}

export const RequestState: React.FC<IPingTestResponse> = ({
  status,
  ...args
}) => {
  const Component = desc[status]
  return (
    <Modal
      isOpen={!!status}
      className="w-full"
      closeOnBackdrop={false}
      showCloseButton={false}
    >
      <div className="flex justify-center">
        <div className="w-full flex flex-col items-center p-4 bg-white rounded">
          <div className="w-1/2 object-contain">
            <img
              alt=""
              src={automationImg(status)}
              className="w-[300px] h-[300px] object-contain"
            />
          </div>
          <div className="flex flex-col items-center px-4">
            <Component {...args} />
          </div>
        </div>
      </div>
    </Modal>
  )
}
