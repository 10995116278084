import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import { useWait } from '@/hooks'
import { Button } from '../'

import { type IModalProps } from './types'
import './styles.scss'

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  header,
  subtitle,
  footer,
  children,
  onOpened,
  onClosed,
  onClose,
  className,
  closeOnBackdrop = true,
  showCloseButton = true,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(!!isOpen)

  useWait(
    () => {
      setVisible(!!isOpen)
      if (isOpen) {
        onOpened?.()
      }
    },
    isOpen ? 0 : 200,
  )

  useWait(
    () => {
      setOpen(!!isOpen)
      if (!isOpen) {
        onClosed?.()
      }
    },
    isOpen ? 200 : 0,
  )

  useEffect(() => {
    if (open) {
      document.body.classList.add('scroll-lock')
    } else {
      document.body.classList.remove('scroll-lock')
    }
    return () => {
      document.body.classList.remove('scroll-lock')
    }
  }, [open])

  if (!visible) return null

  return createPortal(
    <div className={classNames('modal-wrap', className, { open })}>
      <div
        onClick={closeOnBackdrop ? onClose : undefined}
        className="overlay"
      />
      {visible && (
        <div className="modal-content">
          {showCloseButton && (
            <div className="close-btn">
              <Button onClick={onClose}>Close</Button>
            </div>
          )}
          <div className="scroll-wrap">
            {(!!header || !!subtitle) && (
              <div className="modal-head">
                {typeof header === 'string' ? <h3>{header}</h3> : header}
                {!!subtitle && <p>{subtitle}</p>}
              </div>
            )}
            <div className="modal-body custom-scrollbar">{children}</div>

            {!!footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      )}
    </div>,
    document.getElementById('portal')!,
  )
}

export default Modal
