import React from 'react'
import { Navigate, Outlet, useLocation, Location } from 'react-router-dom'

import { PageLoadingWrapper } from '@/components'
import { homePath, signInPath } from '@/utils'
import { useAuth } from '@/hooks'
import { DashboardContextProvider } from '@/context'

export const AuthWrapper: React.FC = () => {
  const location: Location = useLocation()
  const { isAuthLoading, authenticated } = useAuth()

  if (isAuthLoading) {
    return <PageLoadingWrapper isLoading />
  }

  if (!isAuthLoading && !authenticated) {
    return (
      <Navigate
        to={signInPath}
        state={{ from: location || homePath }}
        replace
      />
    )
  }

  return (
    <DashboardContextProvider>
      <Outlet />
    </DashboardContextProvider>
  )
}
