import React from 'react'

import { AutomationForm } from './automationForm'

export const AddAutomationTab: React.FC = () => {
  return (
    <div className="tab-content">
      <div className="flex gap-4 flex-wrap lg:flex-nowrap mb-2">
        <div className="w-full">
          <AutomationForm />
        </div>
      </div>
    </div>
  )
}
