import React from 'react'

import { AIBotCustomersContextProvider } from '@/context'
import { PageLoadingWrapper } from '@/components'

import { AIBotCustomersList } from './components'

const AIBotCustomersView: React.FC = () => {
  return (
    <PageLoadingWrapper>
      <div className="container mx-auto px-5">
        <AIBotCustomersList />
      </div>
    </PageLoadingWrapper>
  )
}

export const AIBotCustomersPage: React.FC = () => (
  <AIBotCustomersContextProvider>
    <AIBotCustomersView />
  </AIBotCustomersContextProvider>
)
