import React, { useEffect, useMemo, useState } from 'react'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import _ from 'lodash'
import moment from 'moment-timezone'
import {
  PencilIcon,
  TrashIcon,
  UserMinusIcon,
  UserPlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

import { GetSuperhumanJob } from '@/types'
import { Card } from '@/pages/AIJobs/components/sectionCard'
import { Button, FormError, ModalDialog, Select, TextArea } from '@/components'
import { useAIJobs } from '@/hooks/useAIJobs'
import { getUserRenderNameSorted } from '@/utils'

interface ModalDialogProps {
  isOpen: boolean
  onClose: () => void
}

const AppointmentCancelModal = ({
  isOpen,
  onClose,
  appointment,
}: ModalDialogProps & { appointment?: string }) => {
  const { cancelCurrentJobAppointment, cancelAppointmentLoading } = useAIJobs()
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen || cancelAppointmentLoading}
      title="Cancel Appointment"
      footer={
        <div className="flex items-center justify-center gap-4">
          <Button onClick={onClose} className="btn btn-secondary max-w-max">
            Close
          </Button>
          <Button
            onClick={() => {
              appointment && cancelCurrentJobAppointment(appointment)
              onClose()
            }}
            className="btn btn-danger max-w-max"
            loading={cancelAppointmentLoading}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <div className="p-2">
        <h4 className="text-lg font-semibold mb-3">
          Your appointment will be canceled.
          <br />
          Are you sure you want to proceed?
        </h4>
      </div>
    </ModalDialog>
  )
}

const AppointmentEditModal = ({
  isOpen,
  onClose,
  appointment,
}: ModalDialogProps & { appointment?: { _id: string; eventStart: Date } }) => {
  const { editCurrentJobAppointment, editAppointmentLoading } = useAIJobs()
  const [date, setDate] = useState<Date | null>(appointment?.eventStart || null)
  const [error, setError] = useState<string | undefined>('')

  useEffect(() => {
    setDate(appointment?.eventStart || null)
    setError(undefined)
  }, [appointment?.eventStart])

  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Update Appointment"
      footer={
        <div className="flex items-center justify-center gap-4">
          <Button onClick={onClose} className="btn btn-secondary max-w-max">
            Close
          </Button>
          <Button
            onClick={() => {
              appointment &&
                date &&
                editCurrentJobAppointment(appointment._id, date)
                  .catch(e => {
                    setError(e.response.data.message)
                  })
                  .then(res => {
                    if (res) {
                      onClose()
                    }
                  })
            }}
            className="btn btn-primary max-w-max"
            loading={editAppointmentLoading}
            disabled={!date}
          >
            Update
          </Button>
        </div>
      }
    >
      <div className="p-2 date-time-picker">
        <DateTimePicker
          label="Create a new appointment"
          defaultValue={null}
          timezone={'UTC'}
          // @ts-ignore
          value={date ? moment.utc(date) : null}
          onChange={newValue => {
            setDate(newValue ? moment.utc(newValue).toDate() : null)
          }}
          className="w-full"
        />
        <div className="mt-3">
          <FormError error={error} />
        </div>
      </div>
    </ModalDialog>
  )
}

const AppointmentList = () => {
  const [cancelAppointment, setCancelAppointment] = useState<
    string | undefined
  >()
  const [updateAppointment, setUpdateAppointment] = useState<
    { _id: string; eventStart: Date } | undefined
  >()
  const { job } = useAIJobs()
  return (
    <div className="py-2 border-t border-gray-300 mt-4">
      <h4 className="font-semibold text-xl mb-2">Appointments</h4>
      {job?.appointments?.length ? (
        <div className="rounded-md border border-gray-300 bg-white">
          <ul role="list" className="divide-y divide-gray-300">
            {job?.appointments
              ?.sort((left, right) => {
                return moment
                  .utc(left.eventStart)
                  .diff(moment.utc(right.eventStart))
              })
              .map(appointment => {
                return (
                  <li
                    className="px-4 py-4 flex flex-wrap justify-between items-center gap-2"
                    key={appointment._id}
                  >
                    <span className="font-semibold">
                      {moment(appointment?.eventStart)
                        .tz('UTC', true)
                        .format('DD-MM-YYYY HH:mm')}
                    </span>
                    <div className="flex items-center gap-2">
                      <Button
                        className="btn btn-primary gap-2"
                        onClick={() => {
                          setUpdateAppointment({
                            _id: appointment._id,
                            eventStart: moment(appointment?.eventStart)
                              .tz('UTC', true)
                              .toDate(),
                          })
                        }}
                      >
                        <PencilIcon className="w-4 h-4 text-white" />
                        <span className="hidden xl:block">Edit</span>
                      </Button>
                      <Button
                        className="btn btn-danger gap-2"
                        onClick={() => {
                          setCancelAppointment(appointment._id)
                        }}
                      >
                        <TrashIcon className="w-4 h-4 text-white" />
                        <span className="hidden xl:block">Cancel</span>
                      </Button>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      ) : (
        <p>No appointments</p>
      )}
      <AppointmentCancelModal
        appointment={cancelAppointment}
        isOpen={!!cancelAppointment}
        onClose={() => {
          setCancelAppointment(undefined)
        }}
      />
      <AppointmentEditModal
        appointment={updateAppointment}
        isOpen={!!updateAppointment}
        onClose={() => {
          setUpdateAppointment(undefined)
        }}
      />
    </div>
  )
}

const UserAssignModal = ({ isOpen, onClose }: ModalDialogProps) => {
  const {
    job,
    usersToTag,
    setUsersToTag,
    tagMessage,
    setTagMessage,
    toggleTaggedUser,
    taggedUsers,
  } = useAIJobs()

  const getUserRenderText = useMemo(() => {
    return getUserRenderNameSorted(job?.availableUsers || [], taggedUsers)
  }, [taggedUsers, job?.availableUsers])

  const options = useMemo(() => {
    const assignedUsersFiltered = job?.assignedUsers?.filter(
      user => !!job.availableUsers.find(u => u._id === user._id),
    )
    return (
      _.uniqBy(
        [...(job?.availableUsers || []), ...(assignedUsersFiltered || [])],
        '_id',
      )
        ?.map(user => {
          return {
            value: user?._id,
            label: `${user?.firstName} ${user?.lastName}`,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    )
  }, [job?.assignedUsers, job?.availableUsers])

  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Tag Users"
      footer={
        <div className="flex items-center justify-center">
          <Button onClick={onClose} className="btn btn-secondary max-w-max">
            Close
          </Button>
        </div>
      }
    >
      <div className="p-2">
        <h4 className="text-lg font-semibold mb-3">
          Users will be automatically tagged when you send the job.
        </h4>
        <div className="flex flex-wrap items-center gap-3">
          {getUserRenderText?.map(({ text, userId }) => {
            return (
              <Button
                className="btn btn-outline-secondary max-w-max flex items-center"
                key={userId}
                onClick={() => {
                  toggleTaggedUser(userId)
                }}
              >
                {text}
                <XCircleIcon className="h-4 w-4 text-gray-600 ml-3" />
              </Button>
            )
          })}
        </div>
        <div className="py-3">
          <Select
            placeHolder="Select user to tag"
            className="w-full block"
            value={''}
            onChange={option => {
              setUsersToTag(_.uniq([...usersToTag, option.value]))
              toggleTaggedUser(option.value)
            }}
            allowEmpty
            options={options}
          />
        </div>
        <div className="border-t border-gray-300 py-2 w-full'">
          <TextArea
            className="flex flex-col gap-2"
            rows={2}
            label="Message"
            onChange={e => setTagMessage(e.target.value)}
            value={tagMessage}
            placeholder="A tagging message is not required"
          />
        </div>
      </div>
    </ModalDialog>
  )
}
const Actions = ({ job }: { job: GetSuperhumanJob }) => {
  const {
    usersToTag,
    appointmentDate,
    setAppointmentDate,
    aiRecommendedAppointment,
    taggedUsers,
    setUsersToTag,
    toggleTaggedUser,
  } = useAIJobs()

  const getUserRenderText = useMemo(() => {
    const usersToTagFiltered = usersToTag.filter(
      user => !!job.availableUsers.find(u => u._id === user),
    )
    return getUserRenderNameSorted(
      job?.availableUsers || [],
      _.uniq([...usersToTag, ...usersToTagFiltered]),
    )
  }, [job.availableUsers, usersToTag])

  const getAssignedUserRenderText = useMemo(() => {
    return job?.assignedUsers
      .map(user => {
        return {
          text: `${user?.firstName} ${user?.lastName}`,
          userId: user?._id,
        }
      })
      .filter(user => !!job.availableUsers.find(u => u._id === user.userId))
      .sort((a, b) => a.text.localeCompare(b.text))
  }, [job?.assignedUsers, job?.availableUsers])

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Card title="Job actions">
        <h4 className="font-semibold text-xl mb-2">Assigned users</h4>
        <div className="mb-4">
          {getAssignedUserRenderText?.map(({ text, userId }) => {
            return (
              <div
                className="flex items-center justify-between mb-2"
                key={userId}
              >
                <div key={userId} className="truncate w-full">
                  {text}
                </div>
                {taggedUsers.includes(userId) ? (
                  <Button
                    className="btn btn-danger gap-2 max-w-max shrink-0"
                    onClick={() => toggleTaggedUser(userId)}
                  >
                    <UserMinusIcon className="w-4 h-4 text-white" />
                    <span className="hidden md:block">Untag</span>
                  </Button>
                ) : (
                  <Button
                    className="btn btn-primary gap-2 max-w-max shrink-0"
                    onClick={() => {
                      toggleTaggedUser(userId)
                      setUsersToTag(prevState => _.uniq([...prevState, userId]))
                    }}
                  >
                    <UserPlusIcon className="w-4 h-4 text-white" />
                    <span className="hidden md:block">Tag</span>
                  </Button>
                )}
              </div>
            )
          })}
        </div>
        {getUserRenderText.length > 0 ? (
          <div className="pt-4 border-t border-gray-300">
            <h4 className="font-semibold text-lg mb-2 text-green-600">
              Tag users that will be tagged after your submit.
            </h4>
            {getUserRenderText.map(({ text, userId }) => {
              return (
                <div
                  className="flex items-center justify-between mb-2"
                  key={userId}
                >
                  <div key={userId} className="truncate">
                    {text}
                  </div>
                  {taggedUsers.includes(userId) ? (
                    <Button
                      className="btn btn-danger gap-2 max-w-max shrink-0"
                      onClick={() => toggleTaggedUser(userId)}
                    >
                      <UserMinusIcon className="w-4 h-4 text-white" />
                      <span className="hidden md:block">Untag</span>
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-primary gap-2 max-w-max shrink-0"
                      onClick={() => toggleTaggedUser(userId)}
                    >
                      <UserPlusIcon className="w-4 h-4 text-white" />
                      <span className="hidden md:block">Tag</span>
                    </Button>
                  )}
                </div>
              )
            })}
            <br />
          </div>
        ) : null}
        <div className="mb-4">
          <Button
            className="btn btn-primary"
            onClick={() => setIsModalOpen(true)}
          >
            Add/remove users to tag
          </Button>
        </div>
        <div className="pb-3 pt-5 border-t border-gray-300 date-time-picker">
          <div className="flex gap-3 justify-between items-center mb-3">
            <DateTimePicker
              label="Create a new appointment"
              defaultValue={undefined}
              timezone={'UTC'}
              // @ts-ignore
              value={moment.utc(appointmentDate)}
              onChange={newValue => {
                setAppointmentDate(moment.utc(newValue).toDate())
              }}
              className="w-full"
            />
            {appointmentDate && (
              <XCircleIcon
                className="text-red-600 w-6 h-6 cursor-pointer"
                onClick={() => {
                  setAppointmentDate(null)
                }}
              />
            )}
          </div>
          {aiRecommendedAppointment ? (
            <div>
              <h4 className="font-semibold text-md mb-3">
                Recommended appointment datetime
              </h4>
              <div className="flex justify-between items-center gap-2">
                {moment(aiRecommendedAppointment)
                  .tz('UTC', false)
                  .format('DD-MM-YYYY HH:mm')}
                <Button
                  className="btn btn-sm btn-primary max-w-max"
                  onClick={() => {
                    setAppointmentDate(
                      moment(aiRecommendedAppointment)
                        .tz('UTC', false)
                        .toDate(),
                    )
                  }}
                >
                  Use
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <p className="text-xs text-gray-500 mt-2">
          You are using {moment.tz.guess()} timezone
        </p>

        <AppointmentList />
        <UserAssignModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </Card>
    </LocalizationProvider>
  )
}

export default Actions
