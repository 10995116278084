import React, { useCallback, useMemo } from 'react'
import { Bars3CenterLeftIcon, MapPinIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useDeleteWorkflow, useToggleWorkflow } from '@/apis'
import { generatePath, workflowPath } from '@/utils'
import { useAuth, useWorkflow } from '@/hooks'
import { Button } from '@/components'
import { IWorkflow } from '@/types'

export const Workflows: React.FC = () => {
  const { accesses } = useAuth()

  const navigate = useNavigate()
  const { locations, workflows, organization, updateWorkflows } = useWorkflow()

  const { mutateAsync: onToggleWorkflow, isPending: isToggleLoading } =
    useToggleWorkflow()
  const { mutateAsync: onDeleteWorkflow, isPending: isDeleteLoading } =
    useDeleteWorkflow()

  const toggleWorkflow = useCallback(
    (_workflow: IWorkflow) => async () => {
      const response = await onToggleWorkflow({
        params: {
          workflowId: _workflow._id,
          locationId: _workflow.location,
        },
      })
      updateWorkflows(response.data)
    },
    [onToggleWorkflow, updateWorkflows],
  )

  const deleteWorkflow = useCallback(
    (_workflow: IWorkflow) => async () => {
      await onDeleteWorkflow({
        params: {
          workflowId: _workflow._id,
          locationId: _workflow.location,
        },
      })
      updateWorkflows(_workflow, 'delete')
    },
    [onDeleteWorkflow, updateWorkflows],
  )

  const onEditWorkflow = useCallback(
    (workFlowId: string) => () => {
      navigate(
        generatePath(workflowPath, { id: organization?._id, workFlowId }),
      )
    },
    [organization?._id, navigate],
  )

  const disabled = useMemo(
    () => isDeleteLoading || isToggleLoading,
    [isDeleteLoading, isToggleLoading],
  )

  if (!workflows) {
    return (
      <div className="w-full text-center font-medium">No workflows found</div>
    )
  }

  return (
    <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 ">
      {workflows?.map((workflow, idx) => (
        <div
          className="w-full shadow-lg border-gray-100 border flex flex-col gap-2 rounded p-5"
          key={idx}
        >
          <h5 className="flex gap-2 font-semibold items-center">
            <Bars3CenterLeftIcon className="w-5 h-5" />
            {workflow?.name}
          </h5>
          <p className="flex gap-2 font-semibold items-center py-2">
            <MapPinIcon className="w-5 h-5" />
            {locations.find(_v => _v._id === workflow?.location)?.name ??
              'Not found'}
          </p>
          {accesses.WORKFLOWS_WRITE && (
            <div className="flex gap-2">
              <div>
                <Button
                  disabled={disabled}
                  className="btn-primary"
                  onClick={onEditWorkflow(workflow._id)}
                >
                  Edit
                </Button>
              </div>
              <div>
                <Button
                  disabled={disabled}
                  onClick={toggleWorkflow(workflow)}
                  className={classNames({
                    'btn-danger': workflow.active,
                    'btn-primary': !workflow.active,
                  })}
                >
                  {workflow?.active ? 'Disable' : 'Enable'}
                </Button>
              </div>
            </div>
          )}
          {!workflow?.active && accesses.WORKFLOWS_WRITE && (
            <div className="w-full">
              <Button
                disabled={disabled}
                className="btn-danger"
                onClick={deleteWorkflow(workflow)}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
