import React, { useMemo } from 'react'
import SearchBar from '@/components/SearchBar'
import { IInventoryStatus } from '@/types'
import { useInventory } from '@/hooks'
import { TextInput } from '@/components'
import { vendors } from '@/pages/Inventory/constants'

const Filter = () => {
  const { filter, setFilter } = useInventory()

  const statusOptions = useMemo(() => {
    const values = Object.values(IInventoryStatus).map(_v => ({
      label: _v.charAt(0).toUpperCase() + _v.slice(1),
      value: _v,
    }))
    return [{ label: 'All', value: '' }, ...values]
  }, [])

  const vendorOptions = useMemo(() => {
    return [{ label: 'All', value: '' }, ...vendors]
  }, [])

  return (
    <div className="pt-8 pb-4 flex gap-8">
      <div className="max-w-sm w-full">
        <SearchBar
          label="Status"
          options={statusOptions}
          onChange={_v => {
            // @ts-ignore
            setFilter(prevState => ({ ...prevState, status: _v.value }))
          }}
          value={
            statusOptions.find(_v => _v.value === filter.status) ||
            statusOptions[0]
          }
        />
      </div>
      <div className="max-w-sm w-full">
        <SearchBar
          label="Vendor"
          options={vendorOptions}
          onChange={_v => {
            // @ts-ignore
            setFilter(prevState => ({ ...prevState, vendor: _v.value }))
          }}
          value={
            vendorOptions.find(_v => _v.value === filter.vendor) ||
            vendorOptions[0]
          }
        />
      </div>
      <div className="max-w-sm w-full">
        <TextInput
          label="Organization/Dealer"
          className="w-full"
          value={filter.organization || ''}
          placeholder="Search by organization name or dealer id..."
          onChange={e =>
            setFilter(prevState => ({
              ...prevState,
              organization: e.target.value,
            }))
          }
        />
      </div>
    </div>
  )
}

export default Filter
