import React, { useEffect, useMemo, useState } from 'react'
import {
  Bars3Icon,
  BellAlertIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline'
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import classNames from 'classnames'

import {
  a2pPath,
  superhumanPath,
  generatePath,
  homePath,
  organizationsPath,
  userSettingsPath,
} from '@/utils'
import { Button, Dropdown, TextLink } from '@/components'
import { useAuth, useDashboard } from '@/hooks'
import MobileMenu from '@/layout/AppHeader/components/mobileMenu'
import { checkSuperhuman } from '@/utils/permissions'
import SearchBar from '@/components/SearchBar'
import { getResourceAndLevel } from '@matador/automations-lib/src/utils/permissions'
import { permissionValueType } from '@matador/automations-lib/src/types/permissions'

export const OrganizationSelect = ({ className }: { className?: string }) => {
  const navigate = useNavigate()
  const params = useParams()
  const { organizations } = useDashboard()
  const organization = useMemo(
    () => organizations.find(_v => _v._id === params?.id),
    [organizations, params.id],
  )
  return (
    <SearchBar
      className={className}
      placeholder="Select Organization..."
      options={organizations.map(_v => ({
        value: _v._id,
        label: _v.name,
      }))}
      onChange={_v => {
        // @ts-ignore
        navigate(generatePath(organizationsPath, { id: _v.value }))
        window.location.reload()
      }}
      value={
        organization?._id
          ? { label: organization?.name, value: organization?._id }
          : { label: 'Select Organization', value: '' }
      }
    />
  )
}

export const AppHeader: React.FC = () => {
  const location = useLocation()
  const { notifications } = useDashboard()
  const { permissions, accesses, availableRoutes } = useAuth()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const isSuperhuman = useMemo(
    () =>
      checkSuperhuman(
        getResourceAndLevel(
          permissions as permissionValueType[],
        ) as permissionValueType[],
      ),
    [permissions],
  )

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location])

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center gap-x-12">
          <TextLink
            to={isSuperhuman ? superhumanPath : homePath}
            className="-m-1.5 p-1.5 shrink-0"
          >
            <img
              alt=""
              className="h-6 w-auto"
              src="https://matador-crm.vercel.app/logo-01.png"
            />
          </TextLink>

          <div className="hidden items-center xl:flex gap-x-4 xl:gap-x-6 2xl:gap-x-12">
            {isSuperhuman ? (
              <NavLink
                to={superhumanPath}
                className={({ isActive }) =>
                  classNames('text-sm font-semibold leading-6 text-gray-900', {
                    'text-indigo-600': isActive,
                  })
                }
              >
                AI Jobs
              </NavLink>
            ) : (
              availableRoutes.map(({ label, path, children }) => {
                if (children && children.length) {
                  return (
                    <Dropdown
                      key={label}
                      title={label}
                      items={children.map(({ label, path }) => ({
                        label,
                        path,
                      }))}
                    />
                  )
                }
                return (
                  <NavLink
                    key={label}
                    to={path}
                    className={({ isActive }) =>
                      classNames(
                        'text-sm font-semibold leading-6 text-gray-900',
                        {
                          'text-indigo-600':
                            isActive ||
                            (label === 'A2P' &&
                              location.pathname.includes(a2pPath)),
                        },
                      )
                    }
                  >
                    {label}
                  </NavLink>
                )
              })
            )}
          </div>
        </div>
        {accesses.AUTOMATIONS_READ && (
          <OrganizationSelect className="w-full max-w-xs ml-auto mr-5 xl:hidden" />
        )}
        <div className="flex xl:hidden gap-5">
          <Button
            hasDefaultStyles={false}
            disabled={!notifications.length}
            // @ts-ignore
            onClick={window?.toggleNotifications}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            <BellAlertIcon
              className={classNames('w-5 h-5 text-gray-500', {
                'text-orange-600': notifications.some(_v => !_v.acknowledged),
              })}
            />
          </Button>
          <Link
            to={userSettingsPath}
            className="text-sm font-semibold leading-6 text-gray-900 block flex items-center"
          >
            <Cog6ToothIcon
              className={classNames(
                'w-5 h-5 text-gray-500 shrink-0 hover:text-blue-500 transition-all',
              )}
            />
          </Link>
          <Button
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Button>
        </div>
        <div className="hidden xl:flex gap-5 w-1/2">
          {accesses.AUTOMATIONS_READ && (
            <OrganizationSelect className="xl:max-w-[32rem] max-w-sm w-full  ml-auto" />
          )}
          <Button
            hasDefaultStyles={false}
            disabled={!notifications.length}
            // @ts-ignore
            onClick={window?.toggleNotifications}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            <BellAlertIcon
              className={classNames('w-5 h-5 text-gray-500 shrink-0', {
                'text-orange-600': notifications.some(_v => !_v.acknowledged),
              })}
            />
          </Button>
          <Link
            to={userSettingsPath}
            className="text-sm font-semibold leading-6 text-gray-900 block flex items-center"
          >
            <Cog6ToothIcon
              className={classNames(
                'w-5 h-5 text-gray-500 shrink-0 hover:text-blue-500 transition-all',
              )}
            />
          </Link>
          <Button
            // @ts-ignore
            onClick={window.onSignOut}
            className="btn-danger max-w-max text-sm font-semibold leading-6 text-gray-900 shrink-0 !py-[9px]"
          >
            Log out{' '}
            <span className="ml-2" aria-hidden="true">
              &rarr;
            </span>
          </Button>
        </div>
      </nav>
      <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
    </header>
  )
}
