import { ILockedLocation, ILockedLocationsResponse, IResponse } from '@/types'
import { endpoints, useLazyGet, usePost } from '@/apis'
import { AxiosResponse } from 'axios'

export const useLockedLocations = (
  onSuccess?: (_data: ILockedLocationsResponse) => void,
) => {
  return useLazyGet(endpoints.lockedLocations, undefined, {
    onSuccess,
  })
}

export const useLockLocation = (
  onSuccess?: (_data: AxiosResponse<IResponse<ILockedLocation>>) => void,
) => {
  return usePost<
    {
      reason: string
      params: {
        locationId: string
      }
    },
    IResponse<ILockedLocation>
  >(endpoints.lockLocation, undefined, {
    onSuccess,
  })
}

export const useUnlockLocation = (
  onSuccess?: (_data: AxiosResponse<IResponse<{ locationId: string }>>) => void,
) => {
  return usePost<
    {
      params: {
        locationId: string
      }
    },
    IResponse<{ locationId: string }>
  >(endpoints.unlockLocation, undefined, {
    onSuccess,
  })
}
