import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useAIJobAppointmentCancel,
  useAIJobAppointmentEdit,
  useAIJobCancel,
  useAiJobSubmit,
  useJob,
  useQueueLength,
  useRequest,
} from '@/apis/hooks/aijobs'
import { useInterval, useToast } from '@/hooks'
import {
  GetSuperhumanJob,
  IAvailableJobCount,
  InventoryType,
  IResponse,
} from '@/types'
import { AxiosResponse } from 'axios'
import { useInventoryAccessCheck } from '@/apis/hooks/inventory'
interface IAIJobsContext {
  aiJobsCount: number
  aiJobDelayCount: number
  aiJobTotalCount: number
  isAgentAvailable: boolean
  job: GetSuperhumanJob | null
  changeAgentAvailability: (val: boolean) => void
  setMessage: Dispatch<SetStateAction<string>>
  setTagMessage: Dispatch<SetStateAction<string>>
  message: string
  tagMessage: string
  appointmentDate: Date | null
  setAppointmentDate: Dispatch<SetStateAction<Date | null>>
  aiRecommendedAppointment: Date | null
  setAiRecommendedAppointment: Dispatch<SetStateAction<Date | null>>
  cancelCurrentJob: () => void
  submitCurrentJob: () => void
  cancelCurrentJobAppointment: (organization: string) => void
  editCurrentJobAppointment: (
    organization: string,
    startDate: Date,
  ) => Promise<boolean>
  setUsersToTag: Dispatch<SetStateAction<string[]>>
  usersToTag: string[]
  taggedUsers: string[]
  toggleTaggedUser: (id: string) => void
  cancelAppointmentLoading: boolean
  editAppointmentLoading: boolean
  vendor?: InventoryType
}
export const AIJobsContext = createContext({} as IAIJobsContext)

export const AIJobsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { addToast } = useToast()
  const [aiJobsCount, setAiJobsCount] = useState(0)
  const [aiJobDelayCount, setAiJobDelayCount] = useState(0)
  const [aiJobTotalCount, setAiJobTotalCount] = useState(0)
  const [isAgentAvailable, setIsAgentAvailable] = useState(false)
  const [job, setJob] = useState<GetSuperhumanJob | null>(null)
  const [message, setMessage] = useState('')
  const [tagMessage, setTagMessage] = useState('')
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null)
  const [aiRecommendedAppointment, setAiRecommendedAppointment] =
    useState<Date | null>(null)
  const [usersToTag, setUsersToTag] = useState<string[]>([])
  const [hasAction, setHasAction] = useState(false)
  const [taggedUsers, setTaggedUsers] = useState<string[]>([])
  const [vendor, setVendor] = useState<InventoryType | undefined>(undefined)

  useEffect(() => {
    setMessage(job?.message || '')
    if (job) setUsersToTag(job.people_to_tag?.length ? job.people_to_tag : [])
    if (job?.people_to_tag?.length) setTaggedUsers(job.people_to_tag)
    if (job) setAppointmentDate(null)
    if (job) {
      setAiRecommendedAppointment(
        job.ai_recommended_appointment
          ? new Date(job.ai_recommended_appointment)
          : null,
      )
    }
  }, [job])
  const onQueueSuccess = useCallback((res: IAvailableJobCount) => {
    setAiJobsCount(res.queueLength)
    setAiJobDelayCount(res.lateQueueLength)
    setAiJobTotalCount(res.totalLength)
  }, [])
  const onJobSuccess = useCallback((res: GetSuperhumanJob) => {
    setJob(res)
  }, [])

  const [requestAIJob] = useJob(onJobSuccess)

  const onCheckSuccess = useCallback(
    (res: IResponse<InventoryType | undefined>) => {
      setVendor(res.data)
    },
    [],
  )
  const [checkAccess] = useInventoryAccessCheck(onCheckSuccess)
  useEffect(() => {
    job?.organization?._id &&
      checkAccess({ pathParams: { organization: job.organization._id } })
  }, [checkAccess, job?.organization?._id])
  const [getQueueLength] = useQueueLength(onQueueSuccess)

  const toggleTaggedUser = useCallback((id: string) => {
    setTaggedUsers(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(item => item !== id)
      }
      return [...prevState, id]
    })
  }, [])

  const onRequestSuccess = useCallback(
    (res: AxiosResponse<{ jobId: string }>) => {
      setAppointmentDate(null)
      requestAIJob({
        pathParams: {
          id: res.data.jobId,
        },
      })
      getQueueLength()
      setTagMessage('')
    },
    [getQueueLength, requestAIJob],
  )

  const { mutate: makeRequest } = useRequest(onRequestSuccess)

  const { mutateAsync: cancelJob } = useAIJobCancel()
  const { mutateAsync: submitJob } = useAiJobSubmit()
  const {
    mutateAsync: cancelJobAppointment,
    isPending: cancelAppointmentLoading,
  } = useAIJobAppointmentCancel()
  const { mutateAsync: editJobAppointment, isPending: editAppointmentLoading } =
    useAIJobAppointmentEdit()

  const cancelCurrentJobAppointment = useCallback(
    (appointment: string) => {
      if (job) {
        cancelJobAppointment({
          params: {
            id: job.jobId,
            appointment,
          },
        }).then(res => {
          setJob(prevState => {
            if (prevState) {
              return {
                ...prevState,
                appointments: prevState.appointments.filter(
                  app => app._id !== res.data.id,
                ),
              }
            }
            return null
          })
          addToast('success', 'Appointment cancelled successfully')
        })
      }
    },
    [addToast, cancelJobAppointment, job],
  )

  const editCurrentJobAppointment = useCallback(
    (appointment: string, startDate: Date) => {
      return editJobAppointment({
        params: {
          id: job?.jobId || '',
          appointment,
        },
        startDate,
      }).then(res => {
        setJob(prevState => {
          if (prevState) {
            return {
              ...prevState,
              appointments: prevState.appointments.map(app => {
                if (app._id === res.data.appointment._id) {
                  return { ...app, eventStart: res.data.appointment.eventStart }
                }
                return app
              }),
            }
          }
          return null
        })
        addToast('success', 'Appointment updated successfully')
        return Boolean(res.data.appointment)
      })
    },
    [addToast, editJobAppointment, job],
  )

  useEffect(() => {
    getQueueLength()
  }, [getQueueLength])

  useInterval(() => {
    getQueueLength()
  }, 1000)

  const afterJobAction = useCallback(() => {
    setJob(null)
    setTaggedUsers([])
    setMessage('')
    setTagMessage('')
    setTimeout(() => {
      setHasAction(false)
    }, 1200)
    setHasAction(false)
  }, [])

  const cancelCurrentJob = useCallback(() => {
    setHasAction(true)
    if (job) {
      cancelJob({
        params: {
          id: job.jobId,
        },
      })
        .then(() => {
          afterJobAction()
        })
        .catch(e => {
          if (e.response.status === 404) {
            afterJobAction()
          }
        })
    }
  }, [afterJobAction, cancelJob, job])

  const submitCurrentJob = useCallback(() => {
    setHasAction(true)
    if (job) {
      submitJob({
        message,
        users_to_tag: taggedUsers,
        appointment_time: appointmentDate ? appointmentDate : null,
        tagging_message: tagMessage ? tagMessage : undefined,
        params: {
          id: job.jobId,
        },
      })
        .then(() => {
          afterJobAction()
        })
        .catch(e => {
          if (e.response.status === 404) {
            afterJobAction()
          }
        })
    }
  }, [
    afterJobAction,
    appointmentDate,
    job,
    message,
    submitJob,
    tagMessage,
    taggedUsers,
  ])

  useEffect(() => {
    if (!job && isAgentAvailable && aiJobTotalCount > 0 && !hasAction) {
      makeRequest()
    }
  }, [
    aiJobTotalCount,
    aiJobsCount,
    hasAction,
    isAgentAvailable,
    job,
    makeRequest,
  ])

  const changeAgentAvailability = useCallback((isAvailable: boolean) => {
    setIsAgentAvailable(isAvailable)
  }, [])

  const value = useMemo(
    () => ({
      aiJobsCount,
      aiJobDelayCount,
      aiJobTotalCount,
      isAgentAvailable,
      job,
      changeAgentAvailability,
      message,
      cancelCurrentJob,
      submitCurrentJob,
      cancelCurrentJobAppointment,
      editCurrentJobAppointment,
      usersToTag,
      tagMessage,
      appointmentDate,
      aiRecommendedAppointment,
      cancelAppointmentLoading,
      editAppointmentLoading,
      taggedUsers,
      toggleTaggedUser,
      setMessage,
      setTagMessage,
      setUsersToTag,
      setAppointmentDate,
      setAiRecommendedAppointment,
      vendor,
    }),
    [
      aiJobsCount,
      aiJobDelayCount,
      aiJobTotalCount,
      isAgentAvailable,
      job,
      changeAgentAvailability,
      message,
      cancelCurrentJob,
      submitCurrentJob,
      cancelCurrentJobAppointment,
      editCurrentJobAppointment,
      usersToTag,
      tagMessage,
      appointmentDate,
      aiRecommendedAppointment,
      cancelAppointmentLoading,
      editAppointmentLoading,
      taggedUsers,
      toggleTaggedUser,
      vendor,
    ],
  )
  return (
    <AIJobsContext.Provider value={value}>{children}</AIJobsContext.Provider>
  )
}
