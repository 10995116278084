import classNames from 'classnames'

import { FormError } from '../'

import { ISelectProps } from './types'

import './styles.scss'

export const Select = <T,>({
  value,
  error,
  options,
  disabled,
  onChange,
  className = '',
  label,
  required,
  placeHolder,
  renderOption,
  allowEmpty,
  ...rest
}: ISelectProps<T>) => {
  return (
    <label className={classNames(className)}>
      {!!label && (
        <span className="name">
          {label} {required && <sup> *</sup>}
        </span>
      )}
      <span className="select">
        <select
          {...rest}
          required={required}
          className="w-100"
          disabled={disabled}
          // @ts-ignore
          value={value ?? ''}
          onChange={_e =>
            onChange?.(
              options[_e.currentTarget.selectedIndex - (placeHolder ? 1 : 0)],
            )
          }
        >
          {placeHolder && (
            <option value="" disabled={!allowEmpty}>
              {placeHolder}
            </option>
          )}

          {options.map((option, index) => (
            // @ts-ignore
            <option key={index} value={option.value} disabled={option.disabled}>
              {renderOption ? renderOption(option, index) : option.label}
            </option>
          ))}
        </select>
      </span>
      {!!error && <FormError error={error} />}
    </label>
  )
}
