import React from 'react'
import { Button, ModalDialog } from '@/components'
import { IInventoryAdmin } from '@/types'
import { useInventory, useToast } from '@/hooks'

interface ModalDialogProps {
  isOpen: boolean
  onClose: () => void
  inventory: IInventoryAdmin
}

const ConfirmDeleteModal = ({
  isOpen,
  onClose,
  inventory,
}: ModalDialogProps) => {
  const { deleteInventoryHandler, deleting } = useInventory()
  const { addToast } = useToast()

  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Confirm Delete Inventory"
      footer={
        <div className="flex items-center justify-center gap-4">
          <Button onClick={onClose} className="btn btn-secondary max-w-max">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteInventoryHandler(inventory.id).then(res => {
                if (res) {
                  addToast('success', res.data.message)
                  onClose()
                }
              })
            }}
            className="btn btn-danger max-w-max"
            loading={deleting}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <div className="p-2">
        <h4 className="text-lg mb-3">
          Inventory with Dealer ID <b>{inventory.dealer_id}</b> will be deleted.
          <br />
          Are you sure you want to proceed?
        </h4>
      </div>
    </ModalDialog>
  )
}

export default ConfirmDeleteModal
