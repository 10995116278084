import React, { useMemo } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import {
  DataTable,
  Modal,
  TableLoadingSkeleton,
  WithPaginator,
} from '@/components'
import {
  headers,
  inventoryEventHeaders,
} from '@/pages/Inventory/components/constants'
import moment from 'moment/moment'
import { DATE_FORMAT } from '@/constants'
import { IInventoryEvent } from '@/types'
import { useInventoryEvents } from '@/apis/hooks/inventory'

interface EventListModalProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const Raw = ({
  item: { user, payload, createdAt },
  ...rest
}: {
  item: IInventoryEvent
}) => {
  const data = {
    dealer_id: payload.dealer_id,
    vendor: payload.vendor,
    status: payload.status,
    organization: payload.organization.name,
  }
  return (
    <tr className="my-2" {...rest}>
      <td className="flex flex-col gap-2">
        <div className="font-semibold text-gray-800">
          {user.firstName + ' ' + user.lastName}
        </div>
        <div className="text-gray-400">{user.email}</div>
      </td>
      <td>
        <div>
          <pre className="bg-gray-100 py-3 px-6 rounded-lg text-gray-800">
            {JSON.stringify(data, undefined, 2)}
          </pre>
        </div>
      </td>
      <td>{moment(createdAt).format(DATE_FORMAT)}</td>
    </tr>
  )
}
const EventListModal = ({ isOpen, onClose, id }: EventListModalProps) => {
  const queryOptions = useMemo(
    () => ({
      enabled: !!id,
      id,
    }),
    [id],
  )

  const fakeAppointments = useMemo(() => [...new Array(10).fill(1)], [])
  const { onPageMetaChange, pageMeta, data, total, loading } =
    useInventoryEvents(queryOptions)

  const pageData: IInventoryEvent[] = useMemo(() => {
    if (loading) return fakeAppointments
    return data[pageMeta.page] ?? []
  }, [data, fakeAppointments, loading, pageMeta.page])
  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[75vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-semibold text-2xl">Audit trail</h1>
          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <div>
          <WithPaginator
            data={pageData}
            loading={loading}
            totalItems={total}
            initialPage={pageMeta.page}
            onPageMetaChange={onPageMetaChange}
            initialPageSize={pageMeta.pageSize}
          >
            {pagedData => (
              <DataTable data={pagedData} headers={inventoryEventHeaders}>
                {data =>
                  loading ? (
                    <TableLoadingSkeleton
                      rows={10}
                      className="py-4"
                      cols={headers.length}
                    />
                  ) : (
                    data.map((datum, idx) => <Raw key={idx} item={datum} />)
                  )
                }
              </DataTable>
            )}
          </WithPaginator>
        </div>
      </div>
    </Modal>
  )
}

export default EventListModal
