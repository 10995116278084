import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { queryClient, QueryProvider } from '@/apis'
import { AppRoutes } from '@/navigation'

import './App.scss'

function App() {
  return (
    <SnackbarProvider>
      <QueryProvider client={queryClient}>
        <Router>
          <AppRoutes />
        </Router>
      </QueryProvider>
    </SnackbarProvider>
  )
}

export default App
