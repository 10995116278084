import React from 'react'
import classNames from 'classnames'

import { LoadingSkeleton, WithSkeleton } from '@/components'
import { rem } from '@/utils'
import { IStats } from '@/types'

interface IStatsProps {
  stats?: IStats
  isLoading?: boolean
  isVertical?: boolean
}

export const Stats: React.FC<IStatsProps> = ({
  stats,
  isLoading,
  isVertical,
}) => {
  return (
    <div className="stats">
      <h3 className="title">Today's stats</h3>
      <dl
        className={classNames('cards', {
          '!flex-col': isVertical,
        })}
      >
        <StatCard
          isLoading={!!isLoading}
          value={stats?.totalCompleted}
          label="Tasks Completed"
        />
        <StatCard
          isLoading={!!isLoading}
          value={stats?.automation?.successRate}
          label="Broadcast Success Rate"
        />
        <StatCard
          isLoading={!!isLoading}
          value={stats?.appointment?.successRate}
          label="Appointment Confirmations Success Rate"
        />
      </dl>
    </div>
  )
}

interface IStatCardProps {
  label: string
  isLoading: boolean
  value: number | string | undefined
}

const StatCard: React.FC<IStatCardProps> = ({ value, isLoading, label }) => {
  return (
    <WithSkeleton
      loading={isLoading}
      skeleton={<LoadingSkeleton className="w-full" height={rem(108)} />}
    >
      <div className="card">
        <dt className="title">{label}</dt>
        <dd className="value">{value}</dd>
      </div>
    </WithSkeleton>
  )
}
