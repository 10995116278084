import { IDataTableHeader } from '@/components'

export const headers: IDataTableHeader<any>[] = [
  {
    sortable: false,
    key: '_id',
    label: 'ID',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
  },
  {
    sortable: false,
    key: 'message',
    label: 'Message',
  },
  {
    key: 'createdAt',
    label: 'Created At',
    sortable: false,
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
  },
]

export const modalFormFields = [
  {
    slug: 'authorized_representative_job_position',
    allowedValues: [
      'Director',
      'GM',
      'VP',
      'CEO',
      'CFO',
      'General Counsel',
      'Other',
    ],
  },
  {
    slug: 'business_info_business_type',
    allowedValues: [
      'Partnership',
      'Limited Liability Corporation',
      'Corporation',
      'Non-profit Corporation',
    ],
  },
  {
    slug: 'email',
    defaultValue: 'harry@hgreg.com',
  },

  {
    slug: 'business_info_website_url',
  },
  {
    slug: 'business_info_business_registration_number',
  },

  {
    slug: 'authorized_representative_business_title',
  },

  {
    slug: 'authorized_representative_first_name',
  },

  {
    slug: 'authorized_representative_last_name',
  },

  {
    slug: 'authorized_representative_phone_number',
    defaultValue: '+1',
  },
  {
    slug: 'authorized_representative_email',
  },

  {
    slug: 'customer_profile_address_customer_name',
  },

  {
    slug: 'customer_profile_address_street',
  },
  {
    slug: 'customer_profile_address_city',
  },
  {
    slug: 'customer_profile_address_region',
  },
  {
    slug: 'customer_profile_address_postal_code',
  },

  {
    slug: 'customer_profile_address_iso_country',
    allowedValues: ['US'],
  },

  {
    slug: 'message_flow_template',
    allowedValues: ['custom', 'matador_connect', 'consent_form'],
  },

  {
    slug: 'website_opt_in_link',
  },

  {
    slug: 'screenshot_url',
  },
]

export const registerFormFields = [
  {
    slug: 'authorized_representative_job_position',
    allowedValues: [
      'Director',
      'GM',
      'VP',
      'CEO',
      'CFO',
      'General Counsel',
      'Other',
    ],
    isSelectedValue: 'Other',
  },
  {
    slug: 'business_info_business_type',
    allowedValues: [
      'Partnership',
      'Limited Liability Corporation',
      'Corporation',
      'Non-profit Corporation',
    ],
    isSelectedValue: 'Corporation',
  },
  {
    slug: 'email',
    defaultValue: 'harry@hgreg.com',
    isSelectedValue: 'harry@hgreg.com',
  },

  {
    slug: 'business_info_website_url',
    isSelectedValue: '',
  },
  {
    slug: 'business_info_business_registration_number',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'authorized_representative_business_title',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'authorized_representative_first_name',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'authorized_representative_last_name',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'authorized_representative_phone_number',
    isSelectedValue: '[[reuse_profile]]',
    defaultValue: '+1',
  },

  {
    slug: 'authorized_representative_email',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'customer_profile_address_customer_name',
    isSelectedValue: '',
  },

  {
    slug: 'customer_profile_address_street',
    isSelectedValue: '[[reuse_profile]]',
  },
  {
    slug: 'customer_profile_address_city',
    isSelectedValue: '[[reuse_profile]]',
  },
  {
    slug: 'customer_profile_address_region',
    isSelectedValue: '[[reuse_profile]]',
  },
  {
    slug: 'customer_profile_address_postal_code',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'customer_profile_address_iso_country',
    disabled: true,
    allowedValues: ['US'],
    isSelectedValue: 'US',
  },

  {
    slug: 'message_flow_template',
    allowedValues: ['custom', 'matador_connect', 'consent_form'],
    defaultValue: 'custom',
    disabled: true,
    isSelectedValue: 'consent_form',
  },

  {
    slug: 'website_opt_in_link',
    disabled: true,
    defaultValue: '[for next step]',
    isSelectedValue: '[[reuse_profile]]',
  },

  {
    slug: 'screenshot_url',
    disabled: true,
    defaultValue: '[for next step]',
    isSelectedValue: '[[reuse_profile]]',
  },
]
