import React from 'react'
import { ellipsizeMiddle, generatePath, organizationsPath } from '@/utils'
import moment from 'moment/moment'
import { DATE_FORMAT } from '@/constants'
import { ILockedLocation, TOrganizationTab } from '@/types'
import { TextLink } from '@/components'

const ListItem = ({
  location,
  lockedBy,
  lockedAt,
  reason,
  crm,
  assignedUsers,
}: ILockedLocation) => {
  return (
    <tr>
      <td>
        <TextLink
          to={generatePath(
            organizationsPath,
            {
              id: location._organization_id,
            },
            [{ tab: TOrganizationTab.SETTINGS }, { location: location._id }],
          )}
          className="text-link"
        >
          <div
            className="font-mono flex items-center gap-x-4 text-lg font-semibold  pr-4"
            title={location?.name}
          >
            {ellipsizeMiddle(location?.name || '', 35)}
          </div>
        </TextLink>
      </td>
      <td className="capitalize">{crm}</td>
      <td>
        <div className="flex gap-x-3 pr-4">
          <div
            className="text-sm leading-6 text-black"
            title={lockedBy?.firstName + ' ' + lockedBy.lastName}
          >
            {ellipsizeMiddle(
              lockedBy?.firstName + ' ' + lockedBy.lastName || '',
              25,
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="flex gap-x-3  pr-4">
          <div className="text-sm leading-6 text-gray-600" title={reason}>
            {ellipsizeMiddle(reason || '', 35)}
          </div>
        </div>
      </td>
      <td>
        <div className="flex flex-col gap-1">
          {assignedUsers.map(user => (
            <div key={user._id}>
              {user.firstName} {user.lastName}
            </div>
          ))}
        </div>
      </td>
      <td>{moment(lockedAt).format(DATE_FORMAT)}</td>
    </tr>
  )
}

export default ListItem
