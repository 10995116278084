import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { dashboardV2Path, generatePath, organizationsPath } from '@/utils'
import { useWorkflow } from '@/hooks'
import { Button } from '@/components'

import {
  Alert,
  SaveState,
  SelectOptions,
  SelectLocation,
  useLocalWorkflow,
  LocalWorkflowContextProvider,
} from './components'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'

const steps: Record<'1' | '2' | '3', React.ElementType> = {
  1: SelectLocation,
  2: SelectOptions,
  3: SaveState,
}
const WorkflowView: React.FC = () => {
  const navigate = useNavigate()
  const { organization } = useWorkflow()
  const { workflow, payload, currentStep } = useLocalWorkflow()
  const [showAlertModal, setShowAlertModal] = React.useState(false)

  const Component = useMemo(() => steps[currentStep], [currentStep])

  const progress = useMemo(
    () => (100 * +currentStep) / (Object.values(steps).length || 1),
    [currentStep],
  )

  const onToggleBack = useCallback(() => {
    currentStep !== '3' && setShowAlertModal(prev => !prev)
  }, [])

  const onConfirm = useCallback(() => {
    navigate(generatePath(dashboardV2Path, { id: organization?._id }))
  }, [navigate, organization?._id])

  return (
    <div className="container mx-auto pb-5">
      <div className="shadow rounded p-3 mt-5">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold text-lg">
            {payload?.name || 'Create new workflow'}
          </h2>
          <Button
            className="btn-outline flex items-center"
            onClick={onToggleBack}
          >
            <ArrowUturnLeftIcon className="w-5 h-5 mr-2" />
            Back to v2
          </Button>
        </div>
        <div className="bg-gray-400 w-full h-[2px] rounded-full mt-2">
          <div
            style={{ width: `${workflow?.name ? 100 : progress}%` }}
            className="bg-indigo-600 h-[2px] transition-width duration-300 ease-in-out"
          />
        </div>
        <div className="lg:max-h-[83vh] overflow-auto">
          <div
            className={classNames('mx-auto w-full pt-8', {
              'lg:w-1/3': currentStep === '1',
            })}
          >
            <Component />
          </div>
        </div>
      </div>
      {showAlertModal && <Alert onClose={onToggleBack} onConfirm={onConfirm} />}
    </div>
  )
}

export const WorkflowPage: React.FC = () => (
  <LocalWorkflowContextProvider>
    <WorkflowView />
  </LocalWorkflowContextProvider>
)
