import React from 'react'
import classNames from 'classnames'

import { IBadgeProps } from './types'
import './styles.scss'

export const Badge: React.FC<IBadgeProps> = ({
  label,
  status,
  clickable = true,
}) => {
  return (
    <span
      className={classNames(`badge badge-${status}`, {
        clickable,
      })}
    >
      {label ?? status}
    </span>
  )
}
