import React from 'react'
import classNames from 'classnames'

import { ICheckboxProps } from './types'
import './styles.scss'

export const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  label,
  children,
  onChange,
  disabled,
  className,
  ...rest
}) => {
  return (
    <label
      className={classNames('custom-checkbox', className, {
        disabled: !!disabled,
      })}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={() => onChange?.(!checked)}
        disabled={disabled}
        {...rest}
      />
      {(!!label || !!children) && (
        <span className="checkbox">
          {label}
          {children}
        </span>
      )}
    </label>
  )
}

export default Checkbox
