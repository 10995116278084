import React from 'react'
import classNames from 'classnames'

import { type ITextInputProps } from './types'
import { FormError } from '../FormError'

import './styles.scss'

export const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      label,
      labelClassName,
      icon,
      name,
      error,
      className = '',
      onChange,
      required,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        htmlFor={name}
        className={classNames(className, {
          error: !!error,
          disabled: !!rest.disabled,
        })}
      >
        {!!label && (
          <span className={classNames('name', labelClassName)}>
            {label}
            {!!required && <sup>*</sup>}
          </span>
        )}
        <span className="field">
          <input {...rest} ref={ref} name={name} onChange={onChange} />
        </span>
        {!!error && <FormError error={error} />}
      </label>
    )
  },
)

export default TextInput
