import React from 'react'
import { useAuth } from '@/hooks'
import { groupPermissions } from '@/utils/permissions'
import { permissionType } from '@matador/automations-lib/src/types/permissions'
import { IOption, permissionValueType } from '@/types'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { generatePath, organizationsPath } from '@/utils'

const PermissionCard = ({
  title,
  options,
  permissions,
  ...rest
}: {
  title: string
  options: IOption[]
  permissions: permissionValueType[]
}) => {
  return (
    <div
      className="overflow-hidden rounded-xl border border-gray-200"
      {...rest}
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-indigo-50 px-4 py-3">
        <div className="text-sm font-medium leading-6 text-gray-900">
          {title}
        </div>
      </div>
      <div className="text-sm px-4 py-3 flex flex-col gap-2">
        {options.map((option, idx) => {
          const isChecked = permissions.includes(option.value)
          return (
            <div
              key={idx}
              className="flex gap-4 justify-between items-center w-full"
            >
              <span>{option.label}</span>
              {isChecked ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const UserSettings = () => {
  const { userPermissions } = useAuth()
  const groupedPermissions = groupPermissions(Object.values(permissionType))
  console.log(groupedPermissions)
  return (
    <div className="container divide-y">
      <div className="pb-8">
        <h3 className="text-xl font-semibold mb-4">Permissions</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-8 lg:grid-cols-6 xl:gap-x-8 capitalize">
          {Object.keys(groupedPermissions).map(scope => (
            <PermissionCard
              key={scope}
              title={scope}
              options={groupedPermissions[scope]}
              permissions={userPermissions.permissions}
            />
          ))}
        </div>
      </div>
      <div className="py-8">
        <h3 className="text-xl font-semibold mb-4">Assigned Organizations</h3>
        <div className="flex gap-4 items-center flex-wrap">
          {userPermissions.organizations.map((organization, idx) => (
            <Link
              to={generatePath(organizationsPath, { id: organization._id })}
              key={idx}
              className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-50"
            >
              {organization.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
