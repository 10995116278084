import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { IDialogProps } from '@/components/Dialog/types'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const ModalDialog = ({
  isOpen,
  onClose,
  children,
  footer,
  showCloseButton = true,
  title,
}: IDialogProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => onClose()}
        style={{ zIndex: 1000 }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center  py-10">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-0"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-full max-w-[90vw] min-h-60 pb-2 sm:max-w-2xl">
                <div className="flex flex-col gap-4 w-full">
                  <div className="text-center flex justify-between px-8 py-4 sm:mt-0 sm:text-left rounded-t border-b border-gray-200 w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    {showCloseButton && (
                      <button
                        type="button"
                        className="rounded-md bg-white shrink-0 text-gray-400 hover:text-gray-500"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                  <div className="px-8 py-3 h-full">{children}</div>
                  {footer && (
                    <div className="border-t border-gray-200 px-8 py-4 mt-auto">
                      {footer}
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalDialog
