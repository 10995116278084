import React, { useCallback, useMemo, useState } from 'react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'

import {
  Button,
  DataTable,
  ToggleButton,
  WithPaginator,
  TableLoadingSkeleton,
  WithSkeleton,
  LoadingSkeleton,
} from '@/components'
import { useDeleteAutomation, useToggleAutomation } from '@/apis'
import {
  IAutomationData,
  IFrequency,
  TAutomationFilters,
  TAutomationType,
} from '@/types'
import { useAuth, useOrganization } from '@/hooks'

import { automationTypeColors, EditAutomationModal, headers } from './'
import Filters from '@/pages/Organization/components/Automations/components/filters'
import classNames from 'classnames'

export const AutomationsTab: React.FC = () => {
  const [filter, setFilter] = useState<TAutomationFilters>({
    location: [],
    type: [],
  })
  const { automations, isAutomationsLoading } = useOrganization()
  const { accesses } = useAuth()

  const sortedData = useMemo(() => {
    return automations.sort((a, b) => {
      if (a.location.name < b.location.name) return -1
      if (a.location.name > b.location.name) return 1
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  }, [automations])

  const filteredData = useMemo(() => {
    return sortedData.filter(item => {
      if (
        filter.location.length &&
        !filter.location.find(el => el.value === item.location._id)
      ) {
        return false
      }
      return !(
        filter.type.length && !filter.type.find(el => el.value === item.type)
      )
    })
  }, [sortedData, filter])

  const _data = useMemo(
    () => (isAutomationsLoading ? [...new Array(10).fill(1)] : filteredData),
    [filteredData, isAutomationsLoading],
  )

  const headerList = useMemo(() => {
    if (accesses.AUTOMATIONS_WRITE) {
      return headers
    }
    return headers.filter(header => header.key !== 'action')
  }, [accesses.AUTOMATIONS_WRITE])

  return (
    <div className="tab-content automations">
      <Filters filter={filter} setFilter={setFilter} />
      <WithPaginator
        data={_data}
        initialPage={1}
        initialPageSize={automations.length}
        loading={isAutomationsLoading}
        totalItems={automations.length}
      >
        {pagedData => (
          <>
            <DataTable data={pagedData} headers={headerList}>
              {data =>
                isAutomationsLoading ? (
                  <TableLoadingSkeleton
                    rows={10}
                    className="py-4"
                    cols={headerList.length}
                  />
                ) : (
                  data.map((datum, idx) => <ListItem key={idx} {...datum} />)
                )
              }
            </DataTable>
          </>
        )}
      </WithPaginator>
    </div>
  )
}

const messages = {
  automation: (frequency?: IFrequency) =>
    `${frequency?.readableText} at ${frequency?.time?.hour}:${frequency?.time?.minute}`,
  aibot: 'Every 30 minutes',
  workflow: 'At every new one',
}

const ListItem: React.FC<IAutomationData> = ({ ...item }) => {
  const { accesses } = useAuth()
  const { onDeletedAutomation, onToggleAutomation } = useOrganization()
  const { mutateAsync: removeAutomation } = useDeleteAutomation()
  const { mutateAsync: toggleAutomation } = useToggleAutomation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onTriggerToggleAutomation = useCallback(async () => {
    try {
      await toggleAutomation({ id: item._id })
      onToggleAutomation(item._id)
    } catch (err) {
      console.log(err)
    }
  }, [item._id, onToggleAutomation, toggleAutomation])

  const onRemoveAutomation = useCallback(async () => {
    try {
      await removeAutomation({ id: item._id })
      onDeletedAutomation(item._id)
    } catch (err) {
      console.log(err)
    }
  }, [item._id, onDeletedAutomation, removeAutomation])

  const readableText = useMemo(() => {
    const type = item.type as keyof typeof messages
    return (
      (typeof messages?.[type] === 'function'
        ? item.frequencies
            .map(frequency => {
              // @ts-ignore
              return messages[type](frequency)
            })
            .join(', ')
        : messages[type]) ?? `At every new ${type}`
    )
  }, [item.frequencies, item.type])

  const onClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return (
    <tr>
      <td>
        <WithSkeleton
          loading={!item.location}
          skeleton={<LoadingSkeleton className="h-6" />}
        >
          {item.location.name}
        </WithSkeleton>
      </td>
      <td>{item.name}</td>
      {/* @ts-ignore */}
      <td>{readableText}</td>
      <td>
        <span
          className={classNames(
            'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset capitalize',
            automationTypeColors[item.type as TAutomationType],
          )}
        >
          {item.type}
        </span>
      </td>
      <td>
        <ToggleButton
          checked={item.active}
          onChange={onTriggerToggleAutomation}
          disabled={!accesses.AUTOMATIONS_WRITE}
        />
      </td>
      {accesses.AUTOMATIONS_WRITE && (
        <td>
          <div className="flex items-center space-x-4">
            <div>
              <Button className="btn-edit" onClick={() => setIsModalOpen(true)}>
                <PencilSquareIcon className="h-5 w-5 mr-2" />
                Edit
              </Button>
            </div>
            <div>
              {!item.active && (
                <Button className="btn-delete" onClick={onRemoveAutomation}>
                  <TrashIcon className="h-5 w-5 mr-2" />
                  Remove
                </Button>
              )}
            </div>
          </div>
        </td>
      )}
      {isModalOpen && (
        <EditAutomationModal onClose={onClose} automation={item} />
      )}
    </tr>
  )
}
