import {
	Level,
	ParsedPermission,
	permissionValueType,
	PermissionValueTypeWithOrg,
	Resource,
} from '../types/permissions';

export const getOrganizationsFromString = (organizations: string) => {
	return (
		organizations
			.replace(/[\[\]']+/g, '')
			.split(',')
			.map((org) => org.trim()) || []
	);
};

export const checkHasOrganizationPermission = (
	organizations: string,
	org: string,
) => {
	const orgList = getOrganizationsFromString(organizations);
	if (orgList.includes('*')) return true;
	return organizations.includes(org);
};

export const parsePermission = (
	permission: PermissionValueTypeWithOrg,
): ParsedPermission => {
	const [resource, levelAndOrg] = permission.split(':');
	const [level, orgs] = levelAndOrg.split('/');
	const organizations = getOrganizationsFromString(orgs);

	return {
		resource: resource as Resource,
		level: level as Level,
		organizations,
	};
};

export const combineParsedPermission = (list: ParsedPermission[]) => {
	return list.map((permission) => {
		const organizations = permission.organizations.includes('*')
			? '*'
			: permission.organizations.join(',');
		return `${permission.resource}:${permission.level}/[${organizations}]`;
	});
};

export const getResourceAndLevel = (list: permissionValueType[]) => {
	return list.map((permission) => {
		return permission.split('/')[0];
	});
};

export const resourceList = Object.keys(Resource);
export const levelList = Object.keys(Level);
