import React from 'react'

import { AppointmentsContextProvider } from '@/context'
import { PageLoadingWrapper } from '@/components'

import { AppointmentsTable } from './components'

export const AppointmentsView: React.FC = () => {
  return (
    <PageLoadingWrapper>
      <div className="container mx-auto px-5">
        <AppointmentsTable />
      </div>
    </PageLoadingWrapper>
  )
}

export const AppointmentsPage: React.FC = () => (
  <AppointmentsContextProvider>
    <AppointmentsView />
  </AppointmentsContextProvider>
)
