import moment from 'moment'
import { DateRange } from 'react-day-picker'
import { IFrequency, IWorkingHours } from '@/types'

export const formatDateTime = (
  dateTime: Date | string | number,
  format: string,
): string => moment(dateTime).format(format)

export const isDisableDate = (
  curDate: Date,
  disabledList: Date[] = [],
  maxDate?: Date,
  minDate?: Date,
): boolean => {
  if (maxDate) {
    if (moment(curDate).isAfter(maxDate)) {
      return true
    }
  }
  if (minDate) {
    if (moment(curDate).isBefore(minDate)) {
      return true
    }
  }
  return !!disabledList?.some(d => moment(d).isSame(curDate, 'day'))
}

export const getDateRange = (
  curVal: DateRange,
  newVal: DateRange | undefined,
): DateRange => {
  if (!newVal) return { from: undefined, to: undefined }
  if (newVal.from && newVal.to) {
    if (moment(curVal.from).isAfter(newVal.from)) {
      return { from: newVal.from, to: newVal.to }
    }
    return { from: newVal.to, to: newVal.from }
  } else if (newVal.from) {
    return newVal
  } else if (newVal.to) {
    return { from: newVal.to, to: curVal.from }
  }

  return { from: undefined, to: undefined }
}

export const getRangeText = (
  value?: DateRange | undefined | null,
  format = 'MMM DD, YYYY',
  defaultEnd = true,
): string => {
  if (!value) return ''

  let res = ''
  if (value.from !== undefined) {
    res = formatDateTime(value.from, format)
  }
  if (value.to) {
    res += ` - ${formatDateTime(value.to, format)}`
  } else if (defaultEnd) {
    res += ` - ${moment(value.from).endOf('month').format(format)}`
  }

  return res
}

export const minutesToTimeStr = (minutes: number): string => {
  if (!minutes) return '0m'

  const sign = minutes < 0 ? '-' : ''

  const _minutes = Math.abs(minutes)
  const daysDiff = Math.floor(_minutes / 1440)
  const hoursDiff = Math.floor((_minutes % 1440) / 60)
  const minsDiff = _minutes % 60

  let timeStr = sign
  if (daysDiff) {
    timeStr += `${daysDiff}d `
  }
  if (hoursDiff) {
    timeStr += `${hoursDiff}h `
  }
  if (minsDiff) {
    timeStr += `${minsDiff}m`
  }

  return timeStr
}

export const getTimeDiff = (
  start: Date | string | number,
  end: Date | string | number,
  unit: 'minutes' | 'seconds' | 'hours',
): number => moment(start).diff(end, unit)

export const humanize = (datetime: Maybe<Date | string | number>): string => {
  if (!datetime) return ''
  const diff = moment(datetime).diff(moment(), 'minutes')

  if (diff >= 0 && diff <= 1) {
    return 'Just now'
  }
  return moment.duration(diff, 'minutes').humanize(true)
}

export const isAlertExpiring = (date: Date | string | number) =>
  getTimeDiff(date, new Date(), 'minutes') < 720

export const getMinDate = (days: string[]) => {
  let minDate: Date = new Date(days[0])
  days.forEach(day => {
    if (moment(minDate).isAfter(new Date(day))) minDate = new Date(day)
  }, [])

  return formatDateTime(minDate, 'YYYY-MM-DD')
}

/**
 * Get timestamp in seconds from date
 * @param value Date | string | undefined : Selected value
 * @param type 'date' | 'datetime' : Determine whether to return date or datetime value
 * @param shouldEndDay boolean : Determine whether return end of day or start of day when `type` is 'date`
 * @param defaultValue Date | string | undefined : Default value
 * @returns number : Timestamp value in seconds
 */
export const getTimeStamp = (
  value: Date | string | undefined,
  type: 'date' | 'datetime' = 'date',
  shouldEndDay = false,
  defaultValue?: Date | string,
): number => {
  let res = 0
  const tmstpInSeconds = (val: Date | string): number => {
    const datetime = moment(val)
    let timestamp = 0
    if (type === 'date') {
      timestamp = shouldEndDay
        ? datetime.endOf('day').unix()
        : datetime.startOf('day').unix()
    } else {
      timestamp = datetime.unix()
    }

    return timestamp
  }

  if (!value) {
    if (defaultValue) {
      res = tmstpInSeconds(defaultValue)
    }
  } else {
    res = tmstpInSeconds(value)
  }

  return res
}

export const getGreetingTime = (): 'morning' | 'afternoon' | 'evening' => {
  const now = new Date().getHours()
  if (now >= 0 && now <= 12) return 'morning'
  if (now > 12 && now < 18) return 'afternoon'

  return 'evening'
}

const timeToMinutes = (time: string) => {
  const [timePart, modifier] = time.split(' ')
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timePart.split(':').map(Number)
  if (modifier === 'PM' && hours !== 12) hours += 12
  if (modifier === 'AM' && hours === 12) hours = 0
  return hours * 60 + minutes
}

export const getWeekDaysOverlapHours = (
  workingHours: IWorkingHours,
  selectedDays: string[],
) => {
  if (selectedDays.length < 1) {
    return { minStart: 0, maxEnd: 0 }
  }
  let minStart = 0
  let maxEnd = 24 * 60

  selectedDays.forEach(day => {
    const dayHours = workingHours[day as keyof IWorkingHours]
    if (dayHours.isOpen) {
      const startMinutes = timeToMinutes(dayHours.from)
      const endMinutes = timeToMinutes(dayHours.to)
      minStart = Math.max(minStart, startMinutes)
      maxEnd = Math.min(maxEnd, endMinutes)
    }
  })
  console.log(minStart, maxEnd)
  return { minStart, maxEnd }
}

export const extractAutomationSchedules = (
  freq: IFrequency,
  weekdays: string[],
) => {
  const days: Record<
    string,
    { hour: number | string; minute: number | string }
  > = {}
  if (freq?.increment === 'weekday') {
    freq.weekdays.map(day => {
      const dayFormat = weekdays.find(
        weekDay => weekDay.substring(0, 3).toLowerCase() === day,
      )
      if (dayFormat) {
        days[dayFormat] = { hour: freq.time.hour, minute: freq.time.minute }
      }
    })
  }
  return days
}

export const combineSchedules = (
  data: Record<string, { hour: number | string; minute: number | string }>[],
) => {
  const combinedDays: Record<
    string,
    { hour: number | string; minute: number | string }[]
  > = {}
  return data.reduce((cur, acc) => {
    const keys = Object.keys(acc)
    keys.forEach(key => {
      cur[key] = cur[key] ? [...cur[key], acc[key]] : [acc[key]]
    })
    return cur
  }, combinedDays)
}

interface IStartEndTimezoneProps {
  start?: string
  end?: string
  timezone: string
}

export const calculateStartEndDateWithTimezone = ({
  start,
  end,
  timezone,
}: IStartEndTimezoneProps) => {
  const startDate = moment(start).startOf('day')
  const endDate = moment(end).endOf('day')

  return {
    startDate: moment.tz(startDate, timezone).utc().toISOString(),
    endDate: moment.tz(endDate, timezone).utc().toISOString(),
  }
}
