import React, { useMemo } from 'react'
import { UserMinusIcon, KeyIcon } from '@heroicons/react/24/solid'

import { Button, DataTable, TableLoadingSkeleton } from '@/components'
import { useAccessControl, useAuth } from '@/hooks'
import { IListUser } from '@/types'

import { headers, GrantAccess } from './'
import { PermissionForm } from '@/pages/AccessControl/components/permissionForm'

export const UserList: React.FC = () => {
  const { userList, isLoading } = useAccessControl()

  const pagedData: IListUser[] = useMemo(() => {
    if (isLoading) return [...new Array(1).fill(1)]
    return userList
  }, [userList, isLoading])

  const { accesses } = useAuth()

  const headerList = useMemo(() => {
    if (!accesses.ADMIN_WRITE) {
      return headers.filter(h => h.key !== 'action')
    }
    return headers
  }, [accesses.ADMIN_WRITE])

  return (
    <>
      <GrantAccess />
      <DataTable data={pagedData} headers={headerList}>
        {data =>
          isLoading ? (
            <TableLoadingSkeleton className="py-4" rows={15} cols={3} />
          ) : (
            data.map((datum, idx) => <ListItem key={idx} {...datum} />)
          )
        }
      </DataTable>
      <PermissionForm />
    </>
  )
}

export const ListItem: React.FC<IListUser> = ({
  name,
  hasAccess,
  email,
  permissions,
}) => {
  const { onSetAccess, setSelectedUser } = useAccessControl()
  const { accesses } = useAuth()

  return (
    <tr>
      <td>
        <div className="font-mono text-sm leading-6 text-black">{name}</div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">{email}</div>
      </td>
      {accesses.ADMIN_WRITE && (
        <td>
          {hasAccess && (
            <Button
              className="btn btn-danger gap-2"
              onClick={() =>
                onSetAccess({ email, hasAccess: false, permissions })
              }
            >
              <UserMinusIcon className="w-4 h-4 text-white" />
              <span className="hidden md:block">Remove access</span>
            </Button>
          )}
          <Button
            className="btn btn-primary gap-2"
            onClick={() =>
              setSelectedUser({ email, hasAccess: false, permissions, name })
            }
          >
            <KeyIcon className="w-4 h-4 text-white" />
            <span className="hidden md:block">Permissions</span>
          </Button>
        </td>
      )}
    </tr>
  )
}
