import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import moment from 'moment-timezone'

import {
  TDateFields,
  IAppointment,
  IDataContext,
  IAppointmentFilter,
} from '@/types'
import { useAllAppointments } from '@/apis'
import { calculateStartEndDateWithTimezone } from '@/utils'

interface IAppointmentsContext
  extends IDataContext<Record<string, IAppointment[]>, IAppointmentFilter> {}

export const AppointmentsContext = createContext({} as IAppointmentsContext)

const userTimezone = moment.tz.guess()

export const appointmentsDefaultFilters: Partial<IAppointmentFilter> = {
  sortBy: TDateFields.CREATED_AT,
  startDate: moment().subtract(7, 'd').toString(),
  endDate: moment().endOf('day').toString(),
}
export const AppointmentsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [refreshKey, onAppointmentsRefresh] = useState<string>(`${Date.now()}`)
  const [filters, setFilters] = useState<Partial<IAppointmentFilter>>(
    appointmentsDefaultFilters,
  )

  const queryOptions = useMemo(
    () => ({
      refreshKey,
      enabled: true,
      ...filters,
      ...calculateStartEndDateWithTimezone({
        start: filters.startDate,
        end: filters.endDate,
        timezone: userTimezone,
      }),
    }),
    [filters, refreshKey],
  )

  const { onPageMetaChange, pageMeta, data, loading, total } =
    useAllAppointments(queryOptions)

  const onResetFilters = useCallback(() => {
    setFilters(appointmentsDefaultFilters)
  }, [])

  useEffect(() => {
    onAppointmentsRefresh(`${Date.now()}`)
  }, [filters])

  const onUpdateFilters = useCallback(
    (_filters: Partial<IAppointmentFilter>) => {
      setFilters(prev => ({ ...prev, ..._filters }))
    },
    [],
  )

  const value = useMemo(
    () => ({
      data,
      filters,
      pageMeta,
      onResetFilters,
      onUpdateFilters,
      onPageMetaChange,
      totalItems: total,
      isLoading: loading,
    }),
    [
      data,
      total,
      filters,
      loading,
      pageMeta,
      onResetFilters,
      onUpdateFilters,
      onPageMetaChange,
    ],
  )

  return (
    <AppointmentsContext.Provider value={value}>
      {children}
    </AppointmentsContext.Provider>
  )
}
