import {
  IPageMeta,
  TAppointmentStatus,
  TDateFields,
  TTaskStatus,
} from '@/types'

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
export const GOOGLE_AUTH_CLIENT_ID = import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID
export const INTERNAL_API_ENDPOINT = import.meta.env.VITE_INTERNAL_API_ENDPOINT
export const AUTH_TOKEN_KEY = '@authToken'
export const defaultPageMeta: IPageMeta = {
  page: 1,
  pageSize: 10,
}

export const DATE_FORMAT = 'dddd, MMM Do YYYY, HH:mm A'

export const statusFilter = Object.keys(TTaskStatus).map(key => ({
  label: key.toUpperCase(),
  // @ts-ignore
  value: TTaskStatus[key] as TTaskStatus,
}))

export const dateFilterFields = Object.keys(TDateFields).map(key => ({
  label: key.replace('_', ' ').toUpperCase(),
  // @ts-ignore
  value: TDateFields[key] as TDateFields,
}))

export const appointmentStatusFilter = Object.keys(TAppointmentStatus).map(
  key => ({
    label: key.replace('_', ' ').toUpperCase(),
    // @ts-ignore
    value: TAppointmentStatus[key] as TAppointmentStatus,
  }),
)
