import React, { useMemo } from 'react'

import { DataTable, TableLoadingSkeleton, WithPaginator } from '@/components'
import { useOrganization } from '@/hooks'
import { IAppointment } from '@/types'

import { headers, ListItem, Filters } from '@/pages/Appointments/components'

export const AppointmentsList: React.FC = () => {
  const {
    appointmentFilter,
    onUpdateAppointmentFilter,
    onResetAppointmentFilter,
    appointmentsResponse,
  } = useOrganization()
  const { total, onPageMetaChange, loading, pageMeta, data } =
    appointmentsResponse

  const pageData: IAppointment[] = useMemo(() => {
    if (loading) return [...new Array(10).fill(1)]
    return data[pageMeta.page] ?? []
  }, [data, loading, pageMeta.page])

  return (
    <WithPaginator
      data={pageData}
      loading={loading}
      totalItems={total}
      initialPage={pageMeta.page}
      initialPageSize={pageMeta.pageSize}
      onPageMetaChange={onPageMetaChange}
    >
      {pagedData => (
        <>
          <Filters
            filters={appointmentFilter}
            onReset={onResetAppointmentFilter}
            onUpdate={onUpdateAppointmentFilter}
          />
          <DataTable data={pagedData} headers={headers}>
            {data =>
              loading ? (
                <TableLoadingSkeleton
                  rows={10}
                  className="py-4"
                  cols={headers.length}
                />
              ) : (
                data.map((datum, idx) => <ListItem key={idx} {...datum} />)
              )
            }
          </DataTable>
        </>
      )}
    </WithPaginator>
  )
}
