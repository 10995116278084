import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Modal, Popover, TextInput, WithPaginator } from '@/components'
import { useInventorySearch } from '@/apis/hooks/aijobs'
import {
  IInventory,
  InventoryType,
  IUnionInventory,
  TTaskStatus,
  VendorInventory,
} from '@/types'

import noImage from '@/assets/images/image-not-available.png'
import { checkImage, generateInventoryItem } from '@/utils'
import { XMarkIcon } from '@heroicons/react/24/solid'
import InventoryDetails from '@/pages/AIJobs/components/inventoryDetails'

const InventoryModal = ({
  isOpen,
  onClose,
  item,
}: {
  item: IUnionInventory
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[90vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-semibold text-2xl">
            {item.make} {item.model}, {item.year}
          </h1>
          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <div className="py-6 grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-8">
          <InventoryDetails item={item} />
        </div>
      </div>
    </Modal>
  )
}

const InventoryItem = ({
  item,
  ...rest
}: {
  item: IInventory
  onClick: () => void
}) => {
  const [realImage, setRealImage] = useState<string | undefined>(noImage)
  const image = item.imagelist.split(',')?.[0]

  checkImage(image).then(res => {
    setRealImage(res ? image : noImage)
  })

  return (
    <div
      className="flex flex-col gap-1 p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-50"
      {...rest}
    >
      <div className="flex justify-between">
        <img className="max-w-16 w-full" src={realImage} alt={item.model} />
        <div className="shrink-0">
          <Badge
            label={item.type}
            status={
              item.type === 'New' ? TTaskStatus.COMPLETED : TTaskStatus.PENDING
            }
          />
        </div>
      </div>
      <div className="text-sm text-left col-span-4">
        {item.make} {item.model},{' '}
        <span className="font-semibold">{item.year}</span>
      </div>
      <div className="text-sm col-span-4">{item.vin}</div>
      <div className="text-sm col-span-1 text-right"></div>
    </div>
  )
}

const InventoryItemHorizontal = ({
  item,
  ...rest
}: {
  item: IInventory
  onClick: () => void
}) => {
  const [realImage, setRealImage] = useState<string | undefined>(noImage)
  const image = item.imagelist.split(',')?.[0]

  checkImage(image).then(res => {
    setRealImage(res ? image : noImage)
  })

  return (
    <div
      className="grid grid-cols-8 gap-1 p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-50"
      {...rest}
    >
      <div className="col-span-1">
        <img className="max-w-32 w-full" src={realImage} alt={item.model} />
      </div>
      <div className="text-sm text-left col-span-4">
        {item.make} {item.model},{' '}
        <span className="font-semibold">{item.year}</span>
      </div>
      <div className="text-sm col-span-2">{item.vin}</div>
      <div className="text-sm col-span-1 text-right">
        <Badge
          label={item.type}
          status={
            item.type === 'New' || item.type === 'new'
              ? TTaskStatus.COMPLETED
              : TTaskStatus.PENDING
          }
        />
      </div>
    </div>
  )
}

const InventorySearch = ({
  horizontal = false,
  organization,
  vendor,
}: {
  horizontal?: boolean
  organization?: string
  vendor: InventoryType
}) => {
  const [opened, setOpened] = useState(false)
  const ListItem = horizontal ? InventoryItemHorizontal : InventoryItem
  const [query, setQuery] = useState('')
  const [inventoryItems, setInventoryItems] = useState<IInventory[]>([])
  const [selectedItem, setSelectedItem] = useState<IUnionInventory | null>(null)
  const filter = useMemo(() => {
    return {
      query,
      refreshKey: query,
      enabled: !!query,
      organization,
      vendor,
    }
  }, [organization, query, vendor])
  const { onPageMetaChange, pageMeta, data, loading, total } =
    useInventorySearch(filter)

  useEffect(() => {
    if (!query) {
      setInventoryItems([])
      return
    }
    data[pageMeta.page]?.length && setInventoryItems(data[pageMeta.page] ?? [])
  }, [data, pageMeta.page, query])
  const onItemClick = (item: IInventory) => {
    const data = generateInventoryItem({ vendor, item } as VendorInventory)
    setSelectedItem(data)
  }
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setSelectedItem(null)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])
  return (
    <div>
      <Popover
        initialOpen={opened}
        hasArrow={false}
        onClosed={() => setOpened(false)}
        closeOnOutside
        contentClassName="w-full"
        placement="bottom-start"
        WrapEl="div"
        distance={10}
        button={
          <div className="relative w-full">
            <TextInput
              className="max-w-lg block focus-within:max-w-none w-full transition-all ease-in-out duration-500"
              id={'inventory-search'}
              placeholder="Search a vehicle"
              value={query}
              onChange={e => {
                setQuery(e.target.value)
                setOpened(true)
              }}
            />
            {loading && query && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5">
                <svg
                  className="animate-spin text-indigo-600"
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                </svg>
              </div>
            )}
          </div>
        }
      >
        {onClose => (
          <div className="w-full border border-gray-200 p-4 bg-white rounded shadow-md">
            <WithPaginator
              showVertical
              data={inventoryItems}
              loading={loading}
              totalItems={inventoryItems.length ? total : 0}
              initialPage={pageMeta.page}
              onPageMetaChange={onPageMetaChange}
              initialPageSize={pageMeta.pageSize}
            >
              {items => {
                if (!items?.length && !loading) {
                  return (
                    <div className="text-center">
                      {query === ''
                        ? 'Search for a vehicle'
                        : 'No results found'}
                    </div>
                  )
                }
                return (
                  <div className="pb-4">
                    {items?.map(item => (
                      <ListItem
                        key={item.id}
                        item={item}
                        onClick={() => {
                          onItemClick(item)
                          onClose()
                        }}
                      />
                    ))}
                  </div>
                )
              }}
            </WithPaginator>
          </div>
        )}
      </Popover>
      {selectedItem && (
        <InventoryModal
          isOpen={!!selectedItem}
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  )
}

export default InventorySearch
