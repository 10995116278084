import React, { ReactNode } from 'react'
const InventoryItemDescription = ({
  title,
  description,
}: {
  title: string
  description: ReactNode
}) => {
  return (
    <div className="grid grid-cols-8 gap-3">
      <span className="font-semibold col-span-4">{title}</span>
      <span className="col-span-4">{description}</span>
    </div>
  )
}

export default InventoryItemDescription
