import React from 'react'
import { PageLoadingWrapper } from '@/components'

import { AccessControlContextProvider } from '@/context'

import { UserList } from './components'

export const AccessControlPage: React.FC = () => {
  return (
    <AccessControlContextProvider>
      <PageLoadingWrapper>
        <div className="container mx-auto px-5 py-1">
          <UserList />
        </div>
      </PageLoadingWrapper>
    </AccessControlContextProvider>
  )
}
