import React, { useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Select } from '@/components'
import {
  defaultWeekdays,
  hourOptions,
  minuteOptions,
} from '@/pages/Organization/components'
import moment from 'moment'
import {
  IAutomationForm,
  IFrequency,
  IRule,
  ISingOrganizationType,
} from '@/types'
import {
  combineSchedules,
  extractAutomationSchedules,
  getWeekDaysOverlapHours,
} from '@/utils'
import { useOrganization } from '@/hooks'
import { TrashIcon } from '@heroicons/react/24/solid'

interface FrequencyItemProps {
  frequency: IFrequency
  onChange: (index: number) => (field: string, value: any) => void
  rule?: IRule
  organization?: ISingOrganizationType
  currentState: IAutomationForm
  setCurrentState: React.Dispatch<React.SetStateAction<IAutomationForm>>
  index: number
  onDelete?: () => void
}
const FrequencyItem = ({
  frequency,
  onChange,
  rule,
  organization,
  currentState,
  setCurrentState,
  index,
  onDelete,
}: FrequencyItemProps) => {
  const [weekdays, setWeekdays] =
    useState<Record<string, boolean>>(defaultWeekdays)
  const { automations } = useOrganization()
  const overlapMinMax = useMemo(() => {
    const isDay = frequency?.increment === 'day'
    return organization?.workingHours
      ? getWeekDaysOverlapHours(
          organization?.workingHours,
          isDay
            ? moment.weekdays()
            : Object.keys(weekdays).filter(day => weekdays[day]),
        )
      : { minStart: 0, maxEnd: 0 }
  }, [organization?.workingHours, weekdays, frequency?.increment])

  useEffect(() => {
    const hour = 60 * Number(frequency?.time.hour)
    const minute =
      Number(frequency?.time.minute) + 60 * Number(frequency?.time.hour)
    if (
      !(hour >= overlapMinMax.minStart && hour <= overlapMinMax.maxEnd) ||
      !(minute >= overlapMinMax.minStart && minute <= overlapMinMax.maxEnd)
    ) {
      setCurrentState(prev => ({ ...prev, hour: '', minute: '' }))
    }
  }, [
    frequency?.time.hour,
    frequency?.time.minute,
    overlapMinMax,
    setCurrentState,
    weekdays,
  ])

  const scheduledTimes = useMemo(() => {
    const filteredAutomations = currentState.edit
      ? automations.filter(el => el._id !== currentState.automationId)
      : automations
    const weekdayList = moment.weekdays()
    const selectedDays = weekdayList.filter(day => weekdays[day])
    const currentFrequency = filteredAutomations.map(element => {
      const { frequencies } = element
      if (!frequencies) return {}
      const mappedFreq = frequencies.map(freq => {
        return extractAutomationSchedules(freq, selectedDays)
      })
      return mappedFreq.reduce((acc, cur) => {
        const keys = Object.keys(cur)
        keys.forEach(key => {
          acc[key] = acc[key] ? { ...acc[key], ...cur[key] } : { ...cur[key] }
        })
        return acc
      }, {})
    })
    return combineSchedules(currentFrequency)
  }, [automations, currentState.automationId, currentState.edit, weekdays])

  const disabledHours = useMemo(() => {
    return hourOptions.map(hour => {
      const time = Number(hour.value) * 60
      return {
        ...hour,
        disabled: !(
          time >= overlapMinMax.minStart && time <= overlapMinMax.maxEnd
        ),
      }
    })
  }, [overlapMinMax])

  const disabledMinutes = useMemo(() => {
    return minuteOptions.map(minute => {
      const time = Number(minute.value) + 60 * Number(frequency.time.hour)
      const scheduledMinutes = Object.values(scheduledTimes).reduce<
        (number | string)[]
      >((cur, acc) => {
        const filteredByTime = acc.filter(
          val => Number(val.hour) === Number(frequency.time.hour),
        )
        filteredByTime.forEach(el => cur.push(el.minute))
        return cur
      }, [])
      return {
        ...minute,
        disabled:
          !(time >= overlapMinMax.minStart && time <= overlapMinMax.maxEnd) ||
          scheduledMinutes.includes(Number(minute.value)),
      }
    })
  }, [
    frequency.time.hour,
    overlapMinMax.maxEnd,
    overlapMinMax.minStart,
    scheduledTimes,
  ])

  const disabledDays = useMemo(() => {
    const days: string[] = []
    currentState.frequencies
      .filter((el, idx) => index !== idx)
      .map(freq => {
        days.push(...freq.weekdays)
      })
    return [...new Set(days)]
  }, [currentState.frequencies, index])

  useEffect(() => {
    const _weekdays: Record<string, boolean> = {}
    moment.weekdays().forEach(day => {
      // @ts-ignore
      _weekdays[day] = frequency?.weekdays?.includes(
        day.substring(0, 3).toLowerCase(),
      )
    })
    setWeekdays(_weekdays)
  }, [frequency?.weekdays])

  return (
    <div className="divide-y divide-gray-200 overflow-hidden border border-gray-100 rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h6 className="mb-2">Setup the broadcast launch sequence</h6>
        <div className="w-full">
          <div className="flex flex-col gap-3">
            <div className="flex gap-4 lg:gap-8 items-start justify-between">
              <Checkbox
                required
                id={`daily-${index}`}
                name={`increment-${index}`}
                type="radio"
                label="Daily"
                className="form-check-input"
                checked={frequency.increment === 'weekday'}
                onChange={() => onChange(index)('increment', 'weekday')}
              />
              {frequency.increment === 'weekday' && (
                <div className="overflow-x-auto whitespace-nowrap">
                  <div className="flex gap-4 lg:flex-row flex-col">
                    {moment.weekdays().map(day => (
                      <Checkbox
                        key={day}
                        label={day.substring(0, 3)}
                        checked={weekdays[day]}
                        onChange={() => {
                          setCurrentState(prev => ({
                            ...prev,
                            frequencies: prev.frequencies.map((freq, idx) => {
                              if (index === idx) {
                                const _day = day.substring(0, 3).toLowerCase()
                                return {
                                  ...freq,
                                  weekdays: freq.weekdays.includes(_day)
                                    ? freq.weekdays.filter(d => d !== _day)
                                    : [...freq.weekdays, _day],
                                }
                              }
                              return freq
                            }),
                          }))
                        }}
                        disabled={disabledDays.includes(
                          day.substring(0, 3).toLowerCase(),
                        )}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <Checkbox
              required
              value="day"
              type="radio"
              id={`monthly-${index}`}
              name={`increment-${index}`}
              className="form-check-input"
              label="First day of the month"
              checked={frequency.increment === 'day'}
              onChange={() => onChange(index)('increment', 'day')}
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col">
          <span className="font-semibold text-sm">
            {rule?.type === 'weeklyreport' ? 'Weekly report' : 'Broadcast time'}
            schedule
            <p className="text-xs font-black">
              (current timezone: {organization?.timezone})
            </p>
          </span>
          <div className="flex w-full gap-4 mt-2">
            <Select
              placeHolder="Select hour"
              allowEmpty
              disabled={!frequency.increment}
              required
              name={`hour-${index}`}
              id={`hour-${index}`}
              label="Hour"
              options={disabledHours}
              className="block w-full"
              value={frequency?.time.hour}
              onChange={_v => onChange(index)('hour', _v.value)}
            />
            <Select
              placeHolder="Select minute"
              allowEmpty
              disabled={!frequency?.time.hour}
              required
              name={`minute-${index}`}
              id={`minute-${index}`}
              label="Minute"
              options={disabledMinutes}
              className="block w-full"
              value={frequency?.time.minute}
              onChange={_v => onChange(index)('minute', _v.value)}
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-4 sm:px-6">
        <Button className="btn-danger max-w-max" onClick={onDelete}>
          <TrashIcon className="w-4 h-4 mr-2" />
          Delete
        </Button>
      </div>
    </div>
  )
}

export default FrequencyItem
