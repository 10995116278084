import React from 'react'
import { homePath } from '@/utils'
import { TextLink } from '@/components'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useAuth } from '@/hooks'

export const Unauthorized = () => {
  const { setIsNotPermitted, availableRoutes } = useAuth()
  return (
    <div className="not-found">
      <div className="text-center">
        <p className="error-code">403</p>
        <LockClosedIcon className="h-12 w-12 mx-auto text-black mt-6" />
        <h1 className="page-title">Access to this page is restricted</h1>
        <p className="page-description">
          Please check with the site admin if you believe this is a mistake.
        </p>
        <div
          className="links-section max-w-max mx-auto"
          onClick={() => setIsNotPermitted(false)}
        >
          <TextLink
            to={
              availableRoutes[0]?.children?.[0]?.path ||
              availableRoutes[0]?.path ||
              homePath
            }
            className="go-back"
          >
            Go back home
          </TextLink>
        </div>
      </div>
    </div>
  )
}
