import { IMenu, IParams, permissionValueType } from '@/types'
import { permissionType } from '@matador/automations-lib/src/types/permissions'
import { checkPermissions } from '@/utils/permissions'

export const isActiveLink = (locationPath: string, path: string): boolean => {
  const _path = locationPath.split('/')

  return _path.includes(path)
}

export const generatePath = (
  path: string,
  params: IParams = {},
  queryData?: Record<string, string | number | boolean | undefined | []>[],
  fullUrl?: boolean,
): string => {
  let newPath: string = path
  Object.keys(params).forEach(param => {
    newPath = newPath.replace(`:${param}`, `${params[param] || ''}`)
  })

  if (queryData && queryData.length > 0) {
    const query = queryData
      .map(item => {
        if (Array.isArray(Object.values(item)[0])) {
          const values = Object.values(item)[0] as string[]
          if (values.length === 0) return
          return values
            .map(value => Object.keys(item)[0] + '[]' + '=' + value)
            .join('&')
        }
        return Object.keys(item)[0] + '=' + (Object.values(item)[0] ?? '')
      })
      .join('&')
    newPath = newPath + '?' + query
  }

  if (fullUrl) {
    const baseUrl = import.meta.env.VITE_APP_URL
    return `${baseUrl}${newPath}`
  }
  return newPath
}

export const parseQueryParams = (url: string): Record<string, string> => {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [_, query] = url.split('?')
  if (!query) return {}

  const res: Record<string, string> = {}
  query.split('&').forEach(q => {
    const [k, v] = q.split('=')

    res[k] = v
  })

  return res
}

export const homePath = '/'
export const signInPath = '/sign-in'

export const organizationsPath = '/organizations/:id'
export const appointmentsPath = '/appointments'
export const leadsPath = '/leads'
export const aiBotCustomersPath = '/ai-bot-customers'
export const accessControlPath = '/access-control'
export const a2pPath = '/a2p'
export const a2pPathSection = '/:section'
export const registrationA2pPath = 'registration'
export const pendingA2pPath = 'pending'
export const dashboardV2Path = `/v2${organizationsPath}`
export const workflowPath = `${dashboardV2Path}/:workFlowId`
export const publicPaths: string[] = [signInPath]
export const superhumanPath = '/superhuman'
export const lockedLocationPath = '/locked-location'
export const inventoryPath = '/inventory'
export const userSettingsPath = '/settings'

export const navigationPaths: IMenu[] = [
  {
    label: 'Automation Console',
    path: '',
    access: [permissionType.AUTOMATIONS_READ],
    children: [
      {
        label: 'Dashboard',
        path: homePath,
        access: [permissionType.AUTOMATIONS_READ],
      },
      {
        label: 'Appointments',
        path: appointmentsPath,
        access: [permissionType.AUTOMATIONS_READ],
      },
      {
        label: 'Leads',
        path: leadsPath,
        access: [permissionType.AUTOMATIONS_READ],
      },
      {
        label: 'AI Bot Customers',
        path: aiBotCustomersPath,
        access: [permissionType.AUTOMATIONS_READ],
      },
    ],
  },
  {
    label: 'Superhuman',
    path: superhumanPath,
    access: [permissionType.SUPERHUMAN_READ],
  },
  {
    label: 'Access Control',
    path: accessControlPath,
    access: [permissionType.ADMIN_READ],
  },
  {
    label: 'A2P',
    access: [permissionType.ONBOARDING_READ, permissionType.ONBOARDING_WRITE],
    path: `${a2pPath}/${registrationA2pPath}`,
  },
  {
    label: 'Inventory',
    access: [permissionType.INVENTORY_ADMIN],
    path: inventoryPath,
  },
]

export const generateNavigationPathsByPermissions = (
  permissions: permissionValueType[],
) => {
  const paths: IMenu[] = []
  return navigationPaths.reduce((acc, item) => {
    if (
      item.path.includes(a2pPath) &&
      checkPermissions(permissions, [permissionType.ONBOARDING_READ]) &&
      !checkPermissions(permissions, [permissionType.ONBOARDING_WRITE])
    ) {
      item.path = `${a2pPath}/${pendingA2pPath}`
      acc.push(item)
      return acc
    }
    if (checkPermissions(permissions, item.access || [])) {
      if (item.children) {
        const children = item.children.filter(child =>
          checkPermissions(permissions, child.access || []),
        )
        if (children.length) {
          acc.push({ ...item, children })
        }
        return acc
      }
      acc.push(item)
    }
    return acc
  }, paths)
}

export const isValidUrl = (url: string): boolean => {
  const regex = /^(?!www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
  return regex.test(url)
}
