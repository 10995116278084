import React from 'react'

import { Button, Modal } from '@/components'
import { TFunc } from '@/types'

interface IAlertProps {
  onClose: TFunc
  onConfirm: TFunc
}
export const Alert: React.FC<IAlertProps> = ({ onClose, onConfirm }) => {
  return (
    <Modal
      isOpen
      showCloseButton={false}
      closeOnBackdrop={false}
      className="lg:px-0 px-5"
    >
      <div className="lg:w-[25vw] lg:mx-0 w-full bg-white rounded h-[200px] px-5 py-8 flex flex-col justify-between">
        <h3 className="font-semibold mb-2">Are you sure?</h3>
        <h5 className="font-medium">
          Note that any unsaved changes will be lost when you continue the
          operation.
        </h5>
        <div className="flex justify-end gap-5">
          <Button className="btn-primary" onClick={onConfirm}>
            Yes
          </Button>
          <Button className="btn-secondary" onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  )
}
