import { RQBJsonLogic } from 'react-querybuilder'

export const dynamicFields = [
  'customer_first_name',
  'customer_last_name',
  'vehicle_year',
  'vehicle_make',
  'vehicle_model',
  'vehicle_vin',
  'vehicle_odometer',
  'service_advisor_id',
  'service_advisor_first_name',
  'service_advisor_last_name',
  'price_amount',
  'opcode',
  'opcode_description',
  'technician_id',
  'technician_first_name',
  'technician_last_name',
  'technician_last_name',
  'repair_order_id',
]

export const initialQuery = { combinator: 'and', rules: [] }

export const unitOptions = [
  { value: 'minutes', label: 'Minutes' },
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
]

export const defaultCombinator: RQBJsonLogic = {
  and: [{ '==': [{}, ''] }],
}
