import React, { useCallback, useEffect, useState } from 'react'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button, PageLoadingWrapper } from '@/components'
import { OrganizationProvider } from '@/context'
import { TOrganizationTab } from '@/types'
import { useAuth, useOrganization } from '@/hooks'

import './styles.scss'

import {
  TasksList,
  LeadsList,
  SettingsTab,
  AutomationsTab,
  AddAutomationTab,
  AppointmentsList,
  OrganizationCard,
  OrganizationTabs,
  AiBotCustomersList,
  RepairOrdersTab,
  ServiceAppointmentsTab,
} from './components'
import { dashboardV2Path, generatePath } from '@/utils'
import { Stats } from '@/pages/Dashboard/components'
import LockedLocationBanner from '@/pages/Dashboard/components/lockedLocationBanner'

const OrganizationView: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { accesses } = useAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const { stats, organization, isOrganizationLoading, hasAccessV2 } =
    useOrganization()
  const [activeTab, setTab] = useState<TOrganizationTab>(
    TOrganizationTab.AUTOMATIONS,
  )
  useEffect(() => {
    const activeTab = searchParams.get('tab')
    const tabList = Object.values(TOrganizationTab)
    if (activeTab && tabList.includes(activeTab as TOrganizationTab)) {
      setTab(activeTab as TOrganizationTab)
    }
  }, [searchParams])

  const onTabChange = useCallback(
    (_tab: TOrganizationTab) => {
      setTab(_tab)
      setSearchParams()
    },
    [setSearchParams],
  )

  return (
    <PageLoadingWrapper>
      <div className="container mx-auto p-5">
        <LockedLocationBanner />
        <Button
          className="btn-outline flex items-center mb-2"
          onClick={() => navigate(-1)}
        >
          <ArrowUturnLeftIcon className="w-5 h-5 mr-2" />
          <span>Back to dashboard</span>
        </Button>
        <div className="flex gap-4 lg:flex-nowrap flex-wrap">
          <div className="lg:w-2/12 w-full flex flex-col gap-2">
            <OrganizationCard
              {...organization}
              isLoading={isOrganizationLoading}
              isReady={!!organization?.credentials.length}
            />
            {hasAccessV2 &&
              (accesses.WORKFLOWS_WRITE || accesses.WORKFLOWS_READ) && (
                <Button
                  className="btn-outline"
                  onClick={() =>
                    navigate(generatePath(dashboardV2Path, { id }))
                  }
                >
                  Access v2 dashboard
                </Button>
              )}
            <Stats isVertical stats={stats} isLoading={isOrganizationLoading} />
          </div>
          <div className="lg:w-10/12 w-full">
            <div className="mx-auto border border-gray-300 rounded-xl p-3">
              <OrganizationTabs
                activeTab={activeTab}
                onTabChange={onTabChange}
              />
              {activeTab === TOrganizationTab.AUTOMATIONS && <AutomationsTab />}
              {activeTab === TOrganizationTab.TASKS && <TasksList />}
              {activeTab === TOrganizationTab.APPOINTMENTS && (
                <AppointmentsList />
              )}
              {activeTab === TOrganizationTab.LEADS && <LeadsList />}
              {activeTab === TOrganizationTab.ADD_AUTOMATION &&
                accesses.AUTOMATIONS_WRITE && <AddAutomationTab />}
              {activeTab === TOrganizationTab.AI_BOT_CUSTOMERS && (
                <AiBotCustomersList />
              )}
              {activeTab === TOrganizationTab.SETTINGS &&
                accesses.AUTOMATIONS_WRITE && <SettingsTab />}
              {activeTab === TOrganizationTab.REPAIR_ORDERS && (
                <RepairOrdersTab />
              )}
              {activeTab === TOrganizationTab.SERVICE_APPOINTMENTS && (
                <ServiceAppointmentsTab />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLoadingWrapper>
  )
}

export const OrganizationPage: React.FC = () => (
  <OrganizationProvider>
    <OrganizationView />
  </OrganizationProvider>
)
