import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

import { IPageJumpButtonProps } from './types'


export const PageJumpButton: React.FC<IPageJumpButtonProps> = ({
                                                                 onClick,
                                                                 disabled,
                                                                 toNext,
                                                                 defaultBtn,
                                                               }) => {

  if (defaultBtn) {
    return <>{defaultBtn(onClick)}</>
  }
  return (
    <li
      onClick={onClick}
      className={classNames({
        disabled,
      })}>
      <span className="sr-only">{toNext ? 'Next' : 'Previous'}</span>
      {toNext ? <ChevronRightIcon className="h-5 w-5" /> : <ChevronLeftIcon className="h-5 w-5" />}
    </li>
  )
}