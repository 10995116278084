import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import {
  XMarkIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'

import { IEvent, ITask, ITaskParsedPayload, TagStatus, TFunc } from '@/types'
import { Accordion, DataTable, IDataTableHeader, Modal } from '@/components'
import { useGetTaskEventImg } from '@/apis'
import { DATE_FORMAT } from '@/constants'

const icons: Record<TagStatus, React.ReactNode> = {
  SUCCESS: <CheckCircleIcon className="w-7 h-7 text-green-600" />,
  ERROR: <ExclamationTriangleIcon className="w-7 h-7 text-red-500" />,
  DEFAULT: <InformationCircleIcon className="w-7 h-7 text-indigo-600" />,
  WARNING: <ExclamationTriangleIcon className="w-7 h-7 text-orange-500" />,
}

interface ITaskDetailsProps {
  task?: ITask
  onClose: TFunc
}

export const TaskDetails: React.FC<ITaskDetailsProps> = ({ task, onClose }) => {
  return (
    <Modal isOpen={!!task} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[75vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-semibold text-2xl">{task?.organization.name}</h1>
          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <h2 className="py-3 font-medium">
          Launched At: {moment(task?.createdAt).format(DATE_FORMAT)}
        </h2>
        <h3 className="pb-3">Events</h3>
        <Accordion
          items={(task?.events ?? []).map(_v => {
            const parsedPayload = useMemo(() => {
              return task?.automation?.type === 'automation' &&
                _v.type === 'TASK_PARSE' &&
                task?.parsedPayload?.length
                ? task.parsedPayload
                : undefined
            }, [task])
            return {
              header: (
                <TaskHeader
                  tag={_v.payload.tag}
                  title={_v.payload.message || _v.type}
                />
              ),
              body: <TaskBody {..._v} parsedPayload={parsedPayload} />,
            }
          })}
        />
      </div>
    </Modal>
  )
}

interface ITaskHeader {
  tag: TagStatus
  title: string
}

const TaskHeader: React.FC<ITaskHeader> = ({ title, tag }) => {
  return (
    <p className="flex gap-2 items-center">
      {icons[tag]} {title}
    </p>
  )
}

const TaskBody: React.FC<IEvent & { parsedPayload?: ITaskParsedPayload[] }> = ({
  createdAt,
  payload,
  parsedPayload,
}) => {
  const headers = useMemo(() => {
    const keys = parsedPayload?.reduce<string[]>((acc, curr) => {
      return [...acc, ...Object.keys(curr)]
    }, [])

    if (keys) {
      return Array.from(new Set(keys)).map(header => ({
        sortable: false,
        key: header,
        label: header,
        className: '!px-5',
      }))
    }
  }, [parsedPayload])

  const LambdaLogsView = (log: string) => (
    <React.Fragment>
      <h6>Error logs:</h6> {Buffer.from(log, 'base64').toString()}
    </React.Fragment>
  )

  return (
    <>
      <p>Event completed at {moment(createdAt).format(DATE_FORMAT)}</p>
      {!!payload?.fetchedRowsCount &&
        `${payload?.fetchedRowsCount} rows fetched`}
      {!!payload?.parsedRowsCount && `${payload.parsedRowsCount} rows parsed`}
      {!!payload?.lambdaLogs && LambdaLogsView(payload.lambdaLogs)}
      {!!payload?.content && payload?.content?.toString()}
      {!!payload?.screenshotLocation && (
        <ImageView path={payload?.screenshotLocation} />
      )}
      {parsedPayload && headers && (
        <DataTable data={parsedPayload} headers={headers}>
          {data =>
            data.map((datum, idx) => (
              <Raw key={idx} item={datum} headers={headers} />
            ))
          }
        </DataTable>
      )}
    </>
  )
}

const ImageView: React.FC<{ path: string }> = ({ path }) => {
  const [data, setData] = useState<string>()

  const onSuccess = useCallback((data: AxiosResponse<string>) => {
    setData(data.data)
  }, [])

  const { mutate: getImage, isPending } = useGetTaskEventImg(onSuccess)

  useEffect(() => {
    if (path) {
      getImage({
        s3Path: path,
      })
    }
  }, [getImage, path])

  if (isPending || data) return null

  return (
    <React.Fragment>
      <hr />
      <h6>Screenshot of the Browser instance at the time of failure</h6>
      <img
        alt=""
        className="w-3/4"
        style={{ border: '1px solid black' }}
        src={`data:image/png;base64, ${data}`}
      />
    </React.Fragment>
  )
}

const Raw: React.FC<{
  item: ITaskParsedPayload
  headers: IDataTableHeader<ITaskParsedPayload>[]
}> = ({ item, headers, ...rest }) => {
  return (
    <tr {...rest}>
      {headers.map((key, index) => (
        <td className="!px-5" key={index}>
          {item[key.label] || '-'}
        </td>
      ))}
    </tr>
  )
}
