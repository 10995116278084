import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ClipboardDocumentIcon } from '@heroicons/react/20/solid'

import {
  Badge,
  Button,
  Form,
  ModalDialog,
  Select,
  TextArea,
  TextInput,
} from '@/components'
import {
  ILocation,
  ISettings,
  TTaskStatus,
  ICredential,
  ICredentialMutationPayload,
  ICredentialMeta,
} from '@/types'
import { useLocation, useOrganization } from '@/hooks'
import { useCredentialsMutation, useRevealPassword } from '@/apis'

import { OTPGen, PasswordReveal } from './'
import { capitalize } from 'lodash'

interface CredentialsProps {
  location: ILocation
  credentials: ICredential[]
  isLocked: boolean
}
interface CredentialErrorProps {
  username: string
  password: string
  meta: {
    [key: string]: string
  }
  crm: string
}

interface ModalDialogProps {
  isOpen: boolean
  onClose: () => void
  locationId: string
}

const LockModal = ({ isOpen, onClose, locationId }: ModalDialogProps) => {
  const { onLockLocation, isLocking } = useLocation()
  const [reason, setReason] = useState<string>('')
  const [error, setError] = useState<string>('')
  const onConfirm = () => {
    if (!reason) {
      setError('Reason is required')
      return
    }
    onLockLocation(locationId, reason).then(() => onClose())
  }
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Lock Location"
      footer={
        <div className="flex gap-3 items-center">
          <Button onClick={onClose} className="btn btn-secondary">
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            loading={isLocking}
            className="btn btn-danger"
          >
            Confirm
          </Button>
        </div>
      }
    >
      <TextArea
        error={error}
        className={'w-full'}
        value={reason}
        rows={2}
        label="Reason"
        onChange={e => {
          setError('')
          setReason(e.target.value)
        }}
      />
    </ModalDialog>
  )
}

const UnlockModal = ({ isOpen, onClose, locationId }: ModalDialogProps) => {
  const { onUnlockLocation, isUnlocking } = useLocation()
  const onConfirm = () => {
    onUnlockLocation(locationId).then(() => onClose())
  }
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Unlock Location"
      footer={
        <div className="flex gap-3 items-center">
          <Button onClick={onClose} className="btn btn-secondary">
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            loading={isUnlocking}
            className="btn btn-danger"
          >
            Confirm
          </Button>
        </div>
      }
    >
      <h4 className="text-lg font-semibold mb-3">
        This action will unlock the location.
        <br />
        Are you sure you want to proceed?
      </h4>
    </ModalDialog>
  )
}

export const Credentials: React.FC<CredentialsProps> = ({
  location,
  credentials,
  isLocked,
}) => {
  const { mutateAsync: revealPassword } = useRevealPassword(location._id)
  const { organization, crmSettings } = useOrganization()
  const [errors, setErrors] = useState<Partial<CredentialErrorProps>>({})
  const [openLockConfirm, setOpenLockConfirm] = useState<boolean>(false)
  const [openUnlockConfirm, setOpenUnlockConfirm] = useState<boolean>(false)
  const [state, setState] = useState<
    Partial<ICredentialMutationPayload> & {
      error: boolean
      meta: Partial<ICredentialMeta>
    }
  >({
    error: false,
    meta: {},
  })
  const hasCredential = useMemo(
    () => credentials?.find(_v => _v.location === location._id),
    [credentials, location._id],
  )

  const credential = useMemo(() => {
    if (hasCredential && !state.crm) {
      return hasCredential
    } else {
      return credentials.find(
        _v => _v.location === location._id && _v.crm === state.crm,
      )
    }
  }, [hasCredential, location._id, state.crm])

  const selectMapper = useCallback((data: ISettings) => {
    return { value: data.name, label: capitalize(data.name) }
  }, [])

  const { mutateAsync: mutateCredentials, isPending: isCredentialsLoading } =
    useCredentialsMutation()

  useEffect(() => {
    setState(
      credential
        ? {
            error: false,
            crm: credential?.crm,
            meta: credential?.meta || {},
            username: credential?.username,
          }
        : {
            error: false,
            crm: state.crm,
            meta: {},
            username: '',
          },
    )
  }, [credential])

  const onChange = useCallback(
    (field: string) => (value: string) => {
      const isReset = field === 'crm'
      setState(prev => ({ ...prev, [field]: value }))
      setErrors({})
      if (isReset) {
        setState(prev => ({ ...prev, meta: {} }))
      }
    },
    [],
  )

  const onMetaChange = useCallback(
    (field: string) => (value: string) => {
      setState(prev => ({ ...prev, meta: { ...prev.meta, [field]: value } }))
    },
    [],
  )

  const fieldSettings: ISettings | undefined = useMemo(
    () => crmSettings.find(_v => _v.name === state.crm),
    [state.crm, crmSettings],
  )

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { crm, password, username, meta } = state
      const keys = fieldSettings?.parameters?.map(param => param.name)
      const mustKeys = ['username', 'crm']

      if (!credential) {
        mustKeys.push('password')
      }
      mustKeys.forEach(key => {
        if (!state[key as keyof ICredentialMutationPayload]) {
          setErrors(prev => ({ ...prev, [key]: 'This field is required' }))
        }
      })
      keys?.forEach(key => {
        if (!meta[key as keyof ICredentialMeta]) {
          setErrors(prev => ({
            ...prev,
            meta: { ...prev.meta, [key]: 'This field is required' },
          }))
        }
      })

      if (!organization || !crm || !username || (!credential && !password)) {
        return
      }

      if (keys?.length) {
        if (!keys.every(key => meta[key as keyof ICredentialMeta])) {
          return
        }
      }

      try {
        const data = {
          crm,
          meta,
          username,
          password,
          location: location._id,
          organization: organization?._id,
        }

        if (credential && !password) {
          const res = await revealPassword()
          data.password = res.data.password
          // @ts-ignore
          await mutateCredentials(data)
        } else {
          // @ts-ignore
          await mutateCredentials(data)
        }

        // TODO: Fix this
        window.location.reload()
      } catch (err) {
        // @ts-ignore
        setState(prev => ({ ...prev, error: true }))
      }
    },
    [state, organization, mutateCredentials, location._id],
  )

  const onLockConfirmModalOpen = useCallback(() => {
    setOpenLockConfirm(true)
  }, [])

  const onUnlockConfirmModalOpen = useCallback(() => {
    setOpenUnlockConfirm(true)
  }, [])

  return (
    <>
      <div className="flex justify-between gap-2">
        <h6 className="font-medium text-sm">
          Integration credentials for <b>{location.name}</b>
        </h6>
        {hasCredential ? (
          !isLocked ? (
            <Button
              className="btn btn-danger max-w-max"
              onClick={onLockConfirmModalOpen}
            >
              Lock Location
            </Button>
          ) : (
            <Button
              className="btn btn-primary max-w-max"
              onClick={onUnlockConfirmModalOpen}
            >
              Unlock Location
            </Button>
          )
        ) : null}
      </div>
      {state.error && (
        <div className="bg-red-400 rounded font-semibold w-full text-white text-xs py-2 text-center my-2">
          Failed to save for some reason. Reload the page and try again.
        </div>
      )}
      <Badge
        clickable={false}
        label={hasCredential ? 'Active' : 'No credentials found'}
        status={hasCredential ? TTaskStatus.COMPLETED : TTaskStatus.FAILED}
      />
      <Form onSubmit={onSubmit}>
        <Select
          required
          error={errors?.crm}
          name="crm"
          label="CRM"
          placeHolder="Select CRM..."
          className="mt-2 block"
          value={state.crm}
          onChange={_v => onChange('crm')(_v.value)}
          options={crmSettings.map(selectMapper)}
        />
        <TextInput
          required
          error={errors?.username}
          autoComplete="off"
          className="mt-2 block"
          value={state.username ?? ''}
          label={fieldSettings?.usernameFieldName ?? 'Username'}
          onChange={e => onChange('username')(e.target.value)}
        />
        <TextInput
          required
          error={errors?.password}
          type="password"
          autoComplete="off"
          className="mt-2 block"
          value={state.password ?? ''}
          label={fieldSettings?.passwordFieldName ?? 'Password'}
          onChange={e => onChange('password')(e.target.value)}
        />
        <PasswordReveal locationId={location._id} />
        {fieldSettings?.parameters?.map((param, idx) => (
          <React.Fragment key={idx}>
            <TextInput
              error={errors?.meta?.[param.name]}
              required={!(param.name === 'autogateId')}
              type={param.type}
              autoComplete="off"
              label={param.label}
              className="mt-2 block"
              // @ts-ignore
              value={state?.meta?.[param.name] ?? ''}
              onChange={e => onMetaChange(param.name)(e.target.value)}
            />
            {!!state?.meta?.otp_code &&
              param.name === 'otp_code' &&
              state?.meta?.otp_code && (
                <OTPGen otp_code={state?.meta?.otp_code} />
              )}
          </React.Fragment>
        ))}
        <div className="flex justify-end">
          <div className="w-1/6">
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={isCredentialsLoading}
            >
              <ClipboardDocumentIcon className="w-5 h-5 mr-2" />
              {credential ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </Form>
      <LockModal
        isOpen={openLockConfirm}
        onClose={() => setOpenLockConfirm(false)}
        locationId={location._id}
      />
      <UnlockModal
        isOpen={openUnlockConfirm}
        onClose={() => setOpenUnlockConfirm(false)}
        locationId={location._id}
      />
    </>
  )
}
