import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { Button, Select, TextArea, TextInput } from '@/components'
import { joinArray } from '@/utils'

import { useLocalWorkflow, CombinatorQueryBuilder } from './'
import { unitOptions } from './constants'

const defaultShow = {
  offsetField: true,
  messageField: true,
  combinatorField: true,
}

export const Combinators: React.FC = () => {
  const [shouldShow, setShouldShow] = useState<typeof defaultShow>(defaultShow)
  const [isRequired, setIsRequired] = useState<typeof defaultShow>(defaultShow)
  const { onSave, payload, users, onUpdatePayload, samples } =
    useLocalWorkflow()

  useEffect(() => {
    if (!payload?.template) return
    setShouldShow({
      offsetField: payload?.template?.shouldShow?.offsetField ?? true,
      messageField: payload?.template?.shouldShow?.messageField ?? true,
      combinatorField: payload?.template?.shouldShow?.combinatorField ?? true,
    })
    setIsRequired({
      offsetField: payload?.template?.isRequired?.offsetField ?? true,
      messageField: payload?.template?.isRequired?.messageField ?? true,
      combinatorField: payload?.template?.isRequired?.combinatorField ?? true,
    })
  }, [payload.template])

  const onSetActions = useCallback(
    (user: string) => () => {
      let assignMessage = (payload?.actions?.assignMessage || []) as string[]
      const isSelected = assignMessage?.includes(user)
      if (isSelected) {
        assignMessage = assignMessage.filter(u => u !== user)
      } else {
        assignMessage.push(user)
      }
      onUpdatePayload({ actions: { assignMessage } })
    },
    [onUpdatePayload, payload],
  )

  const disabled = useMemo(
    () =>
      (!payload.message && shouldShow.messageField) ||
      (!payload.combinator && isRequired.combinatorField) ||
      (!payload.sendAfter && shouldShow.offsetField),
    [
      payload.message,
      payload.sendAfter,
      payload.combinator,
      shouldShow.offsetField,
      shouldShow.messageField,
      isRequired.combinatorField,
    ],
  )

  return (
    <>
      <h5 className="font-semibold text-lg ml-2">Let's build the workflow.</h5>
      <div className="flex flex-col lg:flex-row pl-8 pt-2 gap-5">
        <div className="lg:w-1/2 w-full">
          {shouldShow?.messageField && (
            <>
              <h5 className="font-semibold py-2">Message script</h5>
              Supported dynamic fields are listed below. You can use them in
              your message script by wrapping them in curly braces.
              <b>(E.g. {'{{customer_name}}'})</b>
              <TextArea
                rows={3}
                canResize={false}
                className="mt-2 block"
                value={payload?.message}
                onChange={e => onUpdatePayload({ message: e.target.value })}
              />
            </>
          )}
          {shouldShow?.combinatorField && <CombinatorQueryBuilder />}
          {shouldShow?.offsetField && (
            <>
              <div className="flex items-end gap-1">
                <TextInput
                  type="number"
                  className="!w-1/2"
                  label="Send after"
                  value={payload?.sendAfter?.value}
                  labelClassName="text-lg !font-semibold py-2"
                  onChange={e =>
                    onUpdatePayload({
                      sendAfter: {
                        value: +e.target.value,
                        unit: payload?.sendAfter?.unit ?? '',
                      },
                    })
                  }
                />
                <Select
                  className="w-1/2"
                  options={unitOptions}
                  placeHolder="Select time unit"
                  value={payload?.sendAfter?.unit}
                  onChange={option =>
                    onUpdatePayload({
                      sendAfter: {
                        unit: option?.value,
                        value: payload?.sendAfter?.value ?? 0,
                      },
                    })
                  }
                />
              </div>
            </>
          )}
          <h5 className="font-semibold py-2">Assign users</h5>
          <h6>
            Users selected from the list will be assigned to any message
            triggered by this workflow.
          </h6>
          <div className="flex flex-col gap-2 mt-2">
            {users.map(user => {
              const isSelected = payload?.actions?.assignMessage?.includes?.(
                user._id,
              )
              return (
                <Button
                  key={user._id}
                  className={classNames('!w-full', {
                    'btn-primary': isSelected,
                    'btn-outline': !isSelected,
                  })}
                  onClick={onSetActions(user._id)}
                >
                  {joinArray(' ', user.firstName, user.lastName)}
                </Button>
              )
            })}
          </div>
          <hr className="my-2" />
          <Button
            onClick={onSave}
            disabled={disabled}
            className="btn-primary lg:!w-1/3"
          >
            Confirm and save workflow
          </Button>
        </div>
        <div className="lg:w-1/2 w-full">
          <h5 className="font-semibold py-2">Sample results</h5>
          {!samples.length && (
            <div className="w-5/6 flex mx-auto justify-center py-2 bg-indigo-600/20 rounded-lg ring-1 ring-indigo-600 text-indigo-600">
              Adjust your filters to see sample results.
            </div>
          )}
          {samples?.map?.((result, index) => {
            if (!result) return null
            return (
              <div
                key={index}
                className="mb-2 shadow rounded border px-5 py-3 w-1/2"
              >
                <h6 className="font-semibold text-md">
                  {joinArray(
                    ' ',
                    result.customer.firstName,
                    result.customer.lastName,
                  )}
                </h6>
                <h6 className="font-medium text-sm mb-2 text-muted">
                  {joinArray(
                    ' ',
                    result.vehicle.modelYear,
                    result.vehicle.make,
                    result.vehicle.model,
                    `(${result.vehicle.vin})`,
                  )}
                </h6>
                <p className="text-sm">
                  {joinArray(
                    ' ',
                    'Service advisor:',
                    result.serviceAdvisor.firstName,
                    result.serviceAdvisor.lastName,
                  )}
                </p>
                <p className="text-sm">
                  {joinArray(
                    ' ',
                    'Imported on',
                    moment(result.createdAt).format('MM/DD/YYYY'),
                  )}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
