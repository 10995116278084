import React from 'react'

import { PageLoadingWrapper } from '@/components'
import { useDashboard } from '@/hooks'

import { Stats, TasksList, AutomationTypeFilter } from './components'
import LockedLocationBanner from './components/lockedLocationBanner'
import UnusedFeedsBanner from './components/unusedFeedsBanner'

export const DashboardPage: React.FC = () => {
  const { onUpdateFilters, filters, isLoading, stats } = useDashboard()

  return (
    <PageLoadingWrapper>
      <div className="container mx-auto px-5">
        <LockedLocationBanner />
        <UnusedFeedsBanner />
        <div className="flex flex-col md:flex-row items-center justify-between gap-5">
          <div className="max-w-5xl w-full">
            <Stats isLoading={isLoading} stats={stats} />
          </div>
          <div className="flex justify-end">
            <AutomationTypeFilter
              filters={filters}
              onUpdate={onUpdateFilters}
            />
          </div>
        </div>
        <TasksList />
      </div>
    </PageLoadingWrapper>
  )
}
