import React from 'react'
import classNames from 'classnames'

import { LoadingSkeleton, WithSkeleton } from '@/components'
import { IOrganization } from '@/types'
import { rem } from '@/utils'

export const OrganizationCard: React.FC<
  Partial<IOrganization> & { isReady?: boolean; isLoading?: boolean }
> = ({ isReady, isLoading, ...organization }) => {
  return (
    <div className="organization-card">
      <h1 className="label">Automations for</h1>
      <div className="value">
        <WithSkeleton
          loading={isLoading}
          skeleton={<LoadingSkeleton height={rem(24)} width="100%" />}
        >
          {organization?.name}
        </WithSkeleton>
      </div>
      <hr className="divide-gray-500/10" />
      <h2 className="status-label">Automations status:</h2>
      <WithSkeleton
        loading={isLoading}
        skeleton={<LoadingSkeleton height={rem(24)} width="80%" />}
      >
        <div
          className={classNames('status', {
            success: isReady,
            danger: !isReady,
          })}
        >
          {isReady ? 'Set up' : 'Credentials required'}
        </div>
      </WithSkeleton>
    </div>
  )
}
