import React, { useMemo } from 'react'
import classNames from 'classnames'

import { ITab, TOrganizationTab } from '@/types'
import { Button } from '@/components'
import { useAuth } from '@/hooks'
import { useParams } from 'react-router-dom'

const tabs: ITab<TOrganizationTab>[] = [
  {
    label: 'Automations',
    key: TOrganizationTab.AUTOMATIONS,
  },
  {
    label: 'Tasks',
    key: TOrganizationTab.TASKS,
  },
  {
    label: 'Appointments',
    key: TOrganizationTab.APPOINTMENTS,
  },
  {
    label: 'Leads',
    key: TOrganizationTab.LEADS,
  },
  {
    label: 'AI Bot Customers',
    key: TOrganizationTab.AI_BOT_CUSTOMERS,
  },
  {
    label: 'Repair Orders',
    key: TOrganizationTab.REPAIR_ORDERS,
  },
  {
    label: 'Service Appointments',
    key: TOrganizationTab.SERVICE_APPOINTMENTS,
  },
  {
    label: 'Add a new automation',
    key: TOrganizationTab.ADD_AUTOMATION,
  },
  {
    label: 'Settings',
    key: TOrganizationTab.SETTINGS,
  },
]

interface IOrganizationTabsProps {
  activeTab: TOrganizationTab
  onTabChange: (_tab: TOrganizationTab) => void
}
export const OrganizationTabs: React.FC<IOrganizationTabsProps> = ({
  activeTab,
  onTabChange,
}) => {
  const { accesses, userPermissions } = useAuth()
  const tabList = useMemo(() => {
    if (accesses.AUTOMATIONS_WRITE) {
      return tabs
    }
    return tabs.filter(
      tab =>
        ![TOrganizationTab.ADD_AUTOMATION, TOrganizationTab.SETTINGS].includes(
          tab.key,
        ),
    )
  }, [accesses])

  return (
    <div className="tabs-container">
      <nav className="flex w-full lg:w-auto overflow-auto" aria-label="Tabs">
        {tabList.map(tab => (
          <Button
            key={tab.key}
            data-tab={tab.key}
            onClick={() => onTabChange(tab.key)}
            className={classNames('tab', {
              active: tab.key === activeTab,
            })}
          >
            {tab.label}
          </Button>
        ))}
      </nav>
    </div>
  )
}
