import { IDataTableHeader } from '@/components'
import { IListUser } from '@/types'
import { ParsedPermission } from '@matador/automations-lib/src/types/permissions'

export const headers: IDataTableHeader<IListUser>[] = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'action',
    label: 'Action',
  },
]

export const tableHeaders: IDataTableHeader<ParsedPermission>[] = [
  {
    key: 'resource',
    label: 'Resource',
  },
  {
    key: 'level',
    label: 'Level',
  },
  {
    key: 'organizations',
    label: 'Organizations',
  },
  {
    key: 'action',
    label: '',
  },
]
