import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import moment from 'moment-timezone'

import { ILead, ILeadsFilter, IDataContext } from '@/types'
import { useLeads } from '@/apis'
import { calculateStartEndDateWithTimezone } from '@/utils'

interface ILeadsContext
  extends IDataContext<Record<string, ILead[]>, ILeadsFilter> {}
export const LeadsContext = createContext({} as ILeadsContext)

const userTimezone = moment.tz.guess()
export const leadsDefaultFilters: Partial<ILeadsFilter> = {
  date: moment().startOf('day').toString(),
}
export const LeadsContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [refreshKey, onLeadsRefresh] = useState<string>(`${Date.now()}`)
  const [filters, setFilters] =
    useState<Partial<ILeadsFilter>>(leadsDefaultFilters)

  const queryOptions = useMemo(
    () => ({
      refreshKey,
      enabled: true,
      ...filters,
      date: calculateStartEndDateWithTimezone({
        start: filters.date,
        timezone: userTimezone,
      }).startDate,
    }),
    [filters, refreshKey],
  )

  const {
    onPageMetaChange,
    pageMeta,
    data,
    total: totalItems,
    loading: isLoading,
  } = useLeads(queryOptions)

  const onResetFilters = useCallback(() => {
    setFilters(leadsDefaultFilters)
  }, [])

  useEffect(() => {
    onLeadsRefresh(`${Date.now()}`)
  }, [filters])

  const onUpdateFilters = useCallback((_filters: Partial<ILeadsFilter>) => {
    setFilters(prev => ({ ...prev, ..._filters }))
  }, [])

  const value = useMemo(
    () => ({
      data,
      filters,
      pageMeta,
      isLoading,
      totalItems,
      onResetFilters,
      onUpdateFilters,
      onPageMetaChange,
    }),
    [
      data,
      filters,
      pageMeta,
      isLoading,
      totalItems,
      onResetFilters,
      onUpdateFilters,
      onPageMetaChange,
    ],
  )

  return <LeadsContext.Provider value={value}>{children}</LeadsContext.Provider>
}
