import { IDataTableHeader } from '@/components'
import {IAIBotCustomer} from "@/types";

export const headers: IDataTableHeader<IAIBotCustomer>[] = [
  {
    sortable: false,
    key: 'organization',
    label: 'Organization',
  },
  {
    sortable: false,
    key: 'phoneNumber',
    label: 'Phone number',
  },
  {
    label: 'Date',
    key: 'createdAt',
    sortable: false,
  },
]
