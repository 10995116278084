import React, { Fragment, useMemo } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { a2pPath, homePath, superhumanPath } from '@/utils'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Button, TextLink } from '@/components'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { checkSuperhuman } from '@/utils/permissions'
import { useAuth } from '@/hooks'
import { IMenu } from '@/types'

interface IMobileMenuProps {
  open: boolean
  setOpen: (open: boolean) => void
}
interface IMobileAccordionProps {
  label: string
  children: React.ReactNode
}
const MobileAccordion = ({ label, children }: IMobileAccordionProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <Disclosure.Button className="flex w-full justify-between px-2 py-2 text-left text-base font-semibold leading-6 text-gray-900">
            <span>{label}</span>
            <ChevronUpIcon
              className={`${
                !open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-700`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-2">{children}</Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

const MobileDropdownMenu = ({ label, children }: IMenu) => {
  return (
    <MobileAccordion label={label}>
      <div className="py-2 flex flex-col gap-2">
        {children?.map((item, idx) => (
          <NavLink
            key={idx}
            to={item.path}
            className={({ isActive }) =>
              classNames(
                'block rounded-lg px-4 text-sm font-semibold leading-7 text-gray-500',
                {
                  'text-indigo-600': isActive,
                },
              )
            }
          >
            {item.label}
          </NavLink>
        ))}
      </div>
    </MobileAccordion>
  )
}

const MobileMenu = ({ open, setOpen }: IMobileMenuProps) => {
  const { permissions, availableRoutes } = useAuth()
  const isSuperhuman = useMemo(
    () => checkSuperhuman(permissions),
    [permissions],
  )

  return (
    <div className="flex flex-col gap-3">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="flex items-center justify-between w-full">
                            <TextLink to={homePath} className="-m-1.5 p-1.5">
                              <img
                                alt=""
                                className="h-6 w-auto"
                                src="https://matador-crm.vercel.app/logo-01.png"
                              />
                            </TextLink>
                            <Button
                              type="button"
                              className="-m-2.5 rounded-md p-2.5 text-gray-700"
                              onClick={() => setOpen(false)}
                            >
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex flex-col gap-3 h-full px-4 sm:px-6">
                        {isSuperhuman ? (
                          <NavLink
                            to={superhumanPath}
                            className={({ isActive }) =>
                              classNames(
                                'text-base font-semibold leading-6 text-gray-900 px-2',
                                {
                                  'text-indigo-600': isActive,
                                },
                              )
                            }
                          >
                            Superhuman
                          </NavLink>
                        ) : (
                          availableRoutes.map(
                            ({ label, path, children }, idx) => {
                              if (children && children.length) {
                                return (
                                  <MobileDropdownMenu
                                    key={idx}
                                    label={label}
                                    path={path}
                                    children={children}
                                  />
                                )
                              }
                              return (
                                <NavLink
                                  key={idx}
                                  to={path}
                                  className={({ isActive }) =>
                                    classNames(
                                      'text-base font-semibold leading-6 text-gray-900 px-2',
                                      {
                                        'text-indigo-600':
                                          isActive ||
                                          (label === 'A2P' &&
                                            location.pathname.includes(
                                              a2pPath,
                                            )),
                                      },
                                    )
                                  }
                                >
                                  {label}
                                </NavLink>
                              )
                            },
                          )
                        )}
                        <div className="mt-auto">
                          <Button
                            // @ts-ignore
                            onClick={window.onSignOut}
                            className="btn-danger -mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Log out
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default MobileMenu
