import {
  ICheckAuthResponse,
  IListUser,
  IUserSettingsResponse,
  TLoginFormSchema,
} from '@/types'
import { usePost, endpoints, useLazyGet } from '@/apis'
import { AxiosResponse } from 'axios'
import { PermissionValueTypeWithOrg } from '@matador/automations-lib/src/types/permissions'

export const useAuth = () => {
  return usePost<TLoginFormSchema, string>(endpoints.auth, undefined, undefined)
}

export const useGoogleAuth = () => {
  return usePost<{ token: string }, string>(
    endpoints.googleAuth,
    undefined,
    undefined,
  )
}

export const useCheckAuth = () => {
  return usePost<undefined, ICheckAuthResponse>(
    endpoints.checkAuthStatus,
    undefined,
    undefined,
  )
}

export const useUserSettings = (
  onSuccess?: (_data: IUserSettingsResponse) => void,
) => {
  return useLazyGet<undefined, IUserSettingsResponse>(
    endpoints.userSettings,
    undefined,
    { onSuccess },
  )
}

export const usePermission = (
  onSuccess?: (_data: AxiosResponse<IListUser>) => void,
) => {
  return usePost<
    { permissions: PermissionValueTypeWithOrg[]; email: string },
    IListUser
  >(endpoints.permissions, undefined, {
    onSuccess,
  })
}
