import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { type ITextLinkProps } from './types'
import Icon from '../Icon'
import './styles.scss'

export const TextLink: React.FC<ITextLinkProps> = ({
  iconLeft,
  iconRight,
  disabled,
  className,
  children,
  ...rest
}) => {
  return (
    <Link
      {...rest}
      to={!disabled ? rest?.to || '' : ''}
      className={classNames('text-link', className)}
      onClick={!disabled && !rest.to ? rest.onClick : undefined}
    >
      {!!iconLeft && <Icon name={iconLeft} />}
      {children}
      {!!iconRight && <Icon name={iconRight} />}
    </Link>
  )
}

export default TextLink
