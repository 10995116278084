/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { GoogleLogin } from '@react-oauth/google'

import { Button, Form, TextInput } from '@/components'
import { useAuth, useHookForm } from '@/hooks'
import { TLoginFormSchema } from '@/types'
import { loginFormSchema } from '@/utils'

import './styles.scss'

export const SignInPage: React.FC = () => {
  const { onSignIn, isAuthLoading, isGoogleAuthLoading, onGoogleSignIn } =
    useAuth()

  const {
    handler: {
      trigger,
      getValues,
      handleSubmit,
      register,
      formState: { errors },
    },
  } = useHookForm(loginFormSchema)

  const onSubmit: SubmitHandler<TLoginFormSchema> = useCallback(
    async data => {
      await onSignIn(data)
    },
    [onSignIn],
  )

  const responseMessage = async (response: any) => {
    console.log(response)
    await onGoogleSignIn({
      token: response.credential,
    })
  }

  const errorMessage = () => {
    console.log('error')
  }

  const disabled = !getValues('email') || !getValues('password')

  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                alt="Matador AI"
                className="h-10 object-fit-contain w-auto"
                src="https://matador-crm.vercel.app/logo-01.png"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Automations & Workflows
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <Form className="form-group" onSubmit={handleSubmit(onSubmit)}>
                  <TextInput
                    required
                    type="email"
                    autoComplete="email"
                    label="Email address"
                    disabled={isAuthLoading || isGoogleAuthLoading}
                    error={errors.email?.message}
                    {...register('email', {
                      onChange: () => trigger('email'),
                    })}
                  />
                  <div>
                    <TextInput
                      required
                      label="Password"
                      type="password"
                      disabled={isAuthLoading || isGoogleAuthLoading}
                      autoComplete="current-password"
                      error={errors.password?.message}
                      {...register('password', {
                        onChange: () => trigger('password'),
                      })}
                    />
                  </div>

                  <Button
                    type="submit"
                    className="btn-primary"
                    disabled={disabled || isAuthLoading || isGoogleAuthLoading}
                  >
                    Sign in
                  </Button>
                  <GoogleLogin
                    onSuccess={responseMessage}
                    onError={errorMessage}
                    width={384}
                  />
                </Form>
              </div>
            </div>

            <div>
              <h6 className="mt-8 text-sm font-light leading-9 tracking-tight text-gray-500">
                Beta
              </h6>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1574790335676-2a2bb9d70d08?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
