import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useLockedLocations,
  useLockLocation,
  useUnlockLocation,
} from '@/apis/hooks/locations'
import { ILockedLocation, ILockedLocationsResponse } from '@/types'

interface ILocationContext {
  lockedLocations: ILockedLocation[]
  isLoading: boolean
  onLockLocation: (
    locationId: string,
    reason: string,
  ) => Promise<ILockedLocation>
  onUnlockLocation: (locationId: string) => Promise<{ locationId: string }>
  isLocking: boolean
  isUnlocking: boolean
  refetchLockedLocations: () => void
}
export const LocationContext = createContext({} as ILocationContext)

export const LocationContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [lockedLocations, setLockedLocations] = useState<ILockedLocation[]>([])
  const onLockLocationSuccess = (data: ILockedLocationsResponse) => {
    setLockedLocations(data.locations)
  }
  const [getLockedLocations, { isLoading }] = useLockedLocations(
    onLockLocationSuccess,
  )
  const { mutateAsync: lockLocation, isPending } = useLockLocation()
  const { mutateAsync: unlockLocation, isPending: isUnlocking } =
    useUnlockLocation()

  useEffect(() => {
    getLockedLocations()
  }, [getLockedLocations])

  const refetchLockedLocations = useCallback(() => {
    getLockedLocations()
  }, [getLockedLocations])

  const onLockLocation = useCallback(
    async (locationId: string, reason: string) => {
      return await lockLocation({ reason, params: { locationId } }).then(
        res => {
          if (res.data.success) {
            setLockedLocations(prev =>
              res.data.data ? [...prev, res.data.data] : prev,
            )
          }
          return res.data.data
        },
      )
    },
    [lockLocation],
  )

  const onUnlockLocation = useCallback(
    async (locationId: string) => {
      return await unlockLocation({ params: { locationId } }).then(res => {
        if (res.data.success) {
          setLockedLocations(prev =>
            prev.filter(({ location }) => location._id !== locationId),
          )
        }
        return res.data.data
      })
    },
    [unlockLocation],
  )

  const value = useMemo(
    () => ({
      lockedLocations,
      isLoading,
      onLockLocation,
      onUnlockLocation,
      isLocking: isPending,
      isUnlocking,
      refetchLockedLocations,
    }),
    [
      lockedLocations,
      isLoading,
      onLockLocation,
      isPending,
      onUnlockLocation,
      isUnlocking,
      refetchLockedLocations,
    ],
  )

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}
