import React, { useMemo } from 'react'

import { DataTable, TableLoadingSkeleton, WithPaginator } from '@/components'
import { useOrganization } from '@/hooks'
import { IAIBotCustomer } from '@/types'

import { Filters, headers, ListItem } from '@/pages/AIBotCustomers/components'

export const AiBotCustomersList: React.FC = () => {
  const {
    aiBotCustomersFilter,
    aiBotCustomersResponse,
    onResetAiBotCustomersFilter,
    onUpdateAiBotCustomersFilter,
  } = useOrganization()

  const {
    data,
    total,
    pageMeta,
    onPageMetaChange,
    loading: isLoading,
  } = aiBotCustomersResponse

  const fakeCustomers = useMemo(() => [...new Array(10).fill(1)], [])

  const pageData: IAIBotCustomer[] = useMemo(() => {
    if (isLoading) return fakeCustomers
    return data[pageMeta.page] ?? []
  }, [data, fakeCustomers, isLoading, pageMeta.page])

  return (
    <WithPaginator
      data={pageData}
      totalItems={total}
      loading={isLoading}
      initialPage={pageMeta.page}
      onPageMetaChange={onPageMetaChange}
      initialPageSize={pageMeta.pageSize}
    >
      {pagedData => (
        <>
          <Filters
            hideOrganization
            filters={aiBotCustomersFilter}
            onReset={onResetAiBotCustomersFilter}
            onUpdate={onUpdateAiBotCustomersFilter}
          />
          <DataTable data={pagedData} headers={headers}>
            {data =>
              isLoading ? (
                <TableLoadingSkeleton
                  rows={10}
                  className="py-4"
                  cols={headers.length}
                />
              ) : (
                data.map((datum, idx) => <ListItem key={idx} {...datum} />)
              )
            }
          </DataTable>
        </>
      )}
    </WithPaginator>
  )
}
