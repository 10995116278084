import React, { useCallback } from 'react'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'

import { generatePath, workflowPath } from '@/utils'
import { Badge, Button } from '@/components'
import { TTaskStatus } from '@/types'
import { useAuth, useWorkflow } from '@/hooks'
import { Workflows } from '@/pages'

export const DashboardV2Page: React.FC = () => {
  const { accesses } = useAuth()

  const navigate = useNavigate()
  const { organization, locations } = useWorkflow()

  const onAddWorkflow = useCallback(() => {
    navigate(
      generatePath(workflowPath, { id: organization?._id, workFlowId: 'new' }),
    )
  }, [organization?._id, navigate])

  return (
    <div className="container mx-auto">
      <div className="shadow rounded p-3 mt-5">
        <div className="flex items-center justify-between gap-3">
          <div className="w--full">
            <h2 className="font-semibold text-lg">
              {organization?.name || 'Company name'}
            </h2>
          </div>
          {accesses.WORKFLOWS_WRITE && (
            <div className="shrink-0">
              <Button
                onClick={onAddWorkflow}
                className="btn-primary flex gap-2 items-center float-right"
              >
                <PlusIcon className="w-5 h-5" /> Add Workflow
              </Button>
            </div>
          )}
        </div>
        <div className="flex items-center gap-3 mb-4">
          {locations.map(
            (location, index) =>
              location.crm && (
                <Badge
                  key={location.crm + index}
                  status={TTaskStatus.PENDING}
                  label={location.crm}
                />
              ),
          )}
        </div>

        <Workflows />
      </div>
    </div>
  )
}

export * from './components'
