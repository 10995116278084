import {
  IImportJob,
  IInventoryAdmin,
  IInventoryAdminActionPayload,
  IInventoryFile,
  InventoryStatusTypes,
  InventoryType,
  IResponse,
  ISuccessResponse,
  IUnusedFeed,
} from '@/types'
import { endpoints, useLazyGet, usePaginatedRequest, usePost } from '@/apis'
import { AxiosResponse } from 'axios'
import { IInventoryEvent } from '@matador/automations-lib/src/models/InventoryEvent'

export const useInventoryAdmin = ({
  enabled,
  refreshKey,
  ...rest
}: {
  enabled: boolean
  refreshKey?: string
}) => {
  const response = usePaginatedRequest<IInventoryAdmin>({
    enabled,
    params: {
      queryParams: Object.keys(rest)
        // @ts-ignore
        .filter(key => !!rest[key])
        // @ts-ignore
        .map(key => ({ [key]: `${rest[key]}` })),
    },
    refreshKey,
    path: endpoints.inventoryAdmin,
    initialPageMeta: { pageSize: 100, page: 1 },
    ...rest,
  })
  return response
}

export const useInventoryAdminToggle = (
  onSuccess?: (_data: AxiosResponse<ISuccessResponse>) => void,
) => {
  return usePost<
    { status: InventoryStatusTypes; params: { id: string } },
    ISuccessResponse
  >(endpoints.inventoryAdminToggle, undefined, {
    onSuccess,
  })
}

export const useInventoryAdminCreate = (
  onSuccess?: (_data: AxiosResponse<IResponse<IInventoryAdmin>>) => void,
) => {
  return usePost<
    Omit<IInventoryAdminActionPayload, 'id'>,
    IResponse<IInventoryAdmin>
  >(endpoints.inventoryAdminCreate, undefined, {
    onSuccess,
  })
}

export const useInventoryAdminUpdate = (
  onSuccess?: (_data: AxiosResponse<ISuccessResponse>) => void,
) => {
  return usePost<
    Omit<IInventoryAdminActionPayload, 'id'> & { params: { id: string } },
    ISuccessResponse
  >(endpoints.inventoryAdminUpdate, undefined, {
    onSuccess,
  })
}

export const useImportJobs = (
  onSuccess?: (_data: IResponse<IImportJob[]>) => void,
) => {
  return useLazyGet<{ id: string }, IResponse<IImportJob[]>>(
    endpoints.inventoryImportJobs,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useInventorySync = (
  onSuccess?: (_data: ISuccessResponse) => void,
) => {
  return useLazyGet<{ organization: string }, ISuccessResponse>(
    endpoints.inventorySync,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useUnusedFeedsRequest = (
  onSuccess?: (_data: IResponse<Record<InventoryType, IUnusedFeed[]>>) => void,
) => {
  return useLazyGet<undefined, IResponse<Record<InventoryType, IUnusedFeed[]>>>(
    endpoints.unusedFeeds,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useInventoryAccessCheck = (
  onSuccess?: (_data: IResponse<InventoryType | undefined>) => void,
) => {
  return useLazyGet<
    { organization: string },
    IResponse<InventoryType | undefined>
  >(endpoints.inventoryOrganizationAccess, undefined, {
    onSuccess,
  })
}

export const useInventoryDelete = (
  onSuccess?: (_data: AxiosResponse<ISuccessResponse>) => void,
) => {
  return usePost<
    {
      params: {
        inventoryId: string
      }
    },
    ISuccessResponse
  >(endpoints.inventoryDelete, undefined, {
    onSuccess,
  })
}

export const useOrganizationFiles = (
  onSuccess?: (_data: IResponse<IInventoryFile[]>) => void,
) => {
  return useLazyGet<
    { organizationId: string; dealer: string },
    IResponse<IInventoryFile[]>
  >(endpoints.inventoryOrganizationFiles, undefined, {
    onSuccess,
  })
}

export const useOrganizationFileUrl = (
  onSuccess?: (_data: IResponse<{ url: string }>) => void,
) => {
  return useLazyGet<
    { fileName: string; vendor: string },
    IResponse<{ url: string }>
  >(endpoints.inventoryOrganizationFileUrl, undefined, {
    onSuccess,
  })
}

export const useDealerFile = (
  onSuccess?: (_data: IResponse<{ url: string }>) => void,
) => {
  return useLazyGet<
    { dealer: string; vendor: InventoryType },
    IResponse<{ url: string }>
  >(endpoints.inventoryDealerFile, undefined, {
    onSuccess,
  })
}

export const useInventoryEvents = ({
  enabled,
  refreshKey,
  ...rest
}: {
  enabled: boolean
  refreshKey?: string
}) => {
  const response = usePaginatedRequest<IInventoryEvent>({
    enabled,
    params: {
      queryParams: Object.keys(rest)
        // @ts-ignore
        .filter(key => !!rest[key])
        // @ts-ignore
        .map(key => ({ [key]: `${rest[key]}` })),
    },
    refreshKey,
    path: endpoints.inventoryEvent,
    ...rest,
  })
  return response
}
