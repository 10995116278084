import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { DataTable, Modal } from '@/components'
import { IImportJob, IImportJobStatus } from '@/types'
import { importJobHeaders } from '@/pages/Inventory/components/constants'
import moment from 'moment'
import { DATE_FORMAT } from '@/constants'
import classNames from 'classnames'

interface ImportJobsProps {
  isOpen: boolean
  onClose: () => void
  importJobs: IImportJob[]
  title: string
}

const statusMap: Record<IImportJobStatus, string> = {
  success: 'fill-green-500',
  failed: 'fill-red-500',
  pending: 'fill-yellow-500',
}

const StatusIndicator = ({ status }: { status: IImportJobStatus }) => {
  return (
    <svg
      className={classNames('h-2 w-2', statusMap[status])}
      viewBox="0 0 6 6"
      aria-hidden="true"
    >
      <circle cx={3} cy={3} r={3} />
    </svg>
  )
}

const Raw = ({ item, ...rest }: { item: IImportJob }) => {
  return (
    <tr className="my-2" {...rest}>
      <td className="capitalize font-semibold">
        <div className="flex gap-2 items-center">
          <StatusIndicator status={item.status} />
          {item.status}
        </div>
      </td>
      <td>{item.message || '-'}</td>
      <td>{moment(item.created_at).format(DATE_FORMAT)}</td>
      <td>{moment(item.updated_at).format(DATE_FORMAT)}</td>
    </tr>
  )
}

const ImportJobs = ({
  isOpen,
  onClose,
  importJobs,
  title,
}: ImportJobsProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[75vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-semibold text-2xl">{title}</h1>
          <XMarkIcon
            onClick={onClose}
            className="w-7 h-7 text-black cursor-pointer"
          />
        </div>
        <div>
          <DataTable data={importJobs} headers={importJobHeaders}>
            {data => data.map((datum, idx) => <Raw key={idx} item={datum} />)}
          </DataTable>
        </div>
      </div>
    </Modal>
  )
}

export default ImportJobs
