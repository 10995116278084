import React, { useCallback, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

import { Button, LoadingSkeleton } from '@/components'
import { PhotoIcon, XCircleIcon } from '@heroicons/react/24/solid'

interface IAcceptFileProps {
  error?: boolean
  imageUrl?: string | null
  onRemove: () => void
  uploadImage: () => void
  setFile: (_files: File | null) => void
  file: File | null
  isUploading: boolean
}

interface FilePreviewProps {
  fileType: string
  previewUrl: string
  onRemove: () => void
}

const FilePreview = ({ fileType, previewUrl, onRemove }: FilePreviewProps) => {
  return (
    <div className="mt-10 w-full sm:w-1/2 relative">
      {fileType === 'image' ? (
        <img className="w-full h-auto" src={previewUrl} alt="Uploaded file" />
      ) : (
        <video className="w-full h-auto" src={previewUrl} controls />
      )}
      <XCircleIcon
        className="absolute top-0 -right-8 h-6 w-6 cursor-pointer text-red-500"
        onClick={onRemove}
      />
    </div>
  )
}

export const Uploader = ({
  file,
  setFile,
  error,
  imageUrl,
  onRemove,
  uploadImage,
  isUploading,
}: IAcceptFileProps) => {
  const [mediaLoading, setMediaLoading] = React.useState(!!imageUrl)
  const [urlFileType, setUrlFileType] = React.useState<string | null>(null)
  const fileType = useMemo(() => {
    return file?.type.split('/')[0] || ''
  }, [file])

  const previewUrl = useMemo(() => {
    return file ? URL.createObjectURL(file) : ''
  }, [file])

  const getTypeFromImageURL = useCallback((url: string) => {
    const img = new Image()
    img.src = url
    return new Promise<string>(resolve => {
      img.onerror = () => resolve('video')
      img.onload = () => resolve('image')
    }).then(result => {
      setUrlFileType(result)
      setMediaLoading(false)
    })
  }, [])

  useEffect(() => {
    imageUrl && getTypeFromImageURL(imageUrl)
  }, [getTypeFromImageURL, imageUrl])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFile: File = acceptedFiles[0]
      setFile(uploadedFile)
    },
    [setFile],
  )

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: { 'image/*': [], 'video/*': [] },
  })

  return (
    <div className="w-full pt-8 pb-12">
      <label
        {...getRootProps({ className: 'dropzone' })}
        className=" cursor-pointer mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
      >
        <div className="text-center">
          <PhotoIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <div className="relative rounded-md bg-white font-semibold text-indigo-600  hover:text-indigo-500">
              <span>Upload a file</span>
              <input
                type="file"
                hidden
                accept="image/*,video/*"
                onChange={e => {
                  setFile?.(e.target.files?.[0] || null)
                }}
                {...getInputProps()}
              />
            </div>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </label>
      {(fileRejections.length > 0 || error) && (
        <div className="py-2">
          <p className="text-red-500 text-xs">
            {error ? error : 'Please select a valid file, then try again.'}
          </p>
        </div>
      )}

      {file && previewUrl ? (
        <div>
          <FilePreview
            previewUrl={previewUrl}
            fileType={fileType}
            onRemove={() => setFile(null)}
          />
          <Button
            disabled={isUploading}
            loading={isUploading}
            type="button"
            onClick={uploadImage}
            className="btn btn-primary mt-2"
          >
            Save file
          </Button>
        </div>
      ) : imageUrl && urlFileType ? (
        <FilePreview
          previewUrl={imageUrl}
          fileType={urlFileType}
          onRemove={onRemove}
        />
      ) : null}
      {mediaLoading && (
        <LoadingSkeleton className="w-full sm:w-1/2 h-56 mt-10" />
      )}
    </div>
  )
}
