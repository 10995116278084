import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

import { IAutomationData } from '@/types'
import { Modal } from '@/components'

import { AutomationForm } from './automationForm'

interface IEditAutomationModalProps {
  onClose: () => void
  automation: IAutomationData
}
export const EditAutomationModal: React.FC<IEditAutomationModalProps> = ({
  automation,
  onClose,
}) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      showCloseButton={false}
      closeOnBackdrop={false}
    >
      <div className="edit-automation">
        <div className="header">
          <h1 className="title">Edit automation</h1>
          <XMarkIcon onClick={onClose} className="close-icon" />
        </div>
        <div className="info-container">
          <div className="px-4 py-2 sm:p-3">
            <div className="sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-indigo-800">
                <p>
                  The automation will be tested when the new settings are saved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-list">
          <AutomationForm automation={automation} onClose={onClose} />
        </div>
      </div>
    </Modal>
  )
}
