import { IUnionInventory, TPingTestType, VendorInventory } from '@/types'

export const mergeRef = (...refs: any[]) => {
  return (el: any) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(el)
      } else {
        ref.current = el
      }
    })
  }
}

export const joinArray = <T = Maybe<string>>(
  joinWith = ',',
  ...elements: T[]
): string => elements.filter(el => !!el).join(joinWith)

export const ellipsizeMiddle = (inputString?: string, maxLength = 14) => {
  if (!inputString) return ''
  if (inputString.length <= maxLength) {
    return inputString
  }

  const halfLength = Math.floor((maxLength - 3) / 2)
  const leftHalf = inputString.slice(0, halfLength)
  const rightHalf = inputString.slice(inputString.length - halfLength)

  return leftHalf + '...' + rightHalf
}

const stripUnit = (value: number): number => {
  if (!isNaN(value) && Number.isNaN(value)) {
    return value / (value * 0 + 1)
  }
  return value
}

export const rem = (value: number | string, fontSize = 16): string => {
  if (isNaN(+value)) return value.toString()
  return `${stripUnit(+value) / stripUnit(fontSize)}rem`
}

export const camelToFlat = (camelCaseString: string) => {
  return camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2')
}
export enum AutomationImg {
  failed = 'failed.svg',
  completed = 'success.gif',
  pending = 'loading.gif',
}

export const automationImg = (automation: TPingTestType) => {
  return `https://automations.matador.ai/${AutomationImg[automation]}`
}

export const getUserRenderNameSorted = (
  users: { firstName: string; lastName: string; _id: string }[],
  userIds: string[],
) => {
  const userMap: { text: string; userId: string }[] = []
  userIds.forEach(userId => {
    const user = users.find(user => user?._id === userId)
    if (user) {
      userMap.push({
        text: `${user.firstName} ${user.lastName}`,
        userId,
      })
    }
  })
  return userMap.sort((a, b) => a.text.localeCompare(b.text))
}

export const checkImage = (url: string) => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
    img.src = url
  })
}

export const generateInventoryItem = ({
  vendor,
  item,
}: VendorInventory): IUnionInventory => {
  let data = {}
  if (vendor === 'homenet') {
    const {
      'dealer name': dealername,
      'dealer address': dealeraddress,
      'dealer city': dealercity,
      'dealer state': dealerstate,
      'dealer zip': dealerzip,
      'dealer email': dealeremail,
      'dealer phone': dealerphone,
      wheelbase_code: wheelbasecode,
      engine_description: enginedescription,
      engine_aspiration_type: engineaspirationtype,
      fuel_type: fueltype,
      transmission_speed: transmissionspeed,
      transmission_description: transmissiondescription,
      ext_color_generic: extcolorgeneric,
      ...rest
    } = item
    data = {
      ...rest,
      dealername,
      dealeraddress,
      dealercity,
      dealerstate,
      dealerzip,
      dealeremail,
      dealerphone,
      wheelbasecode,
      enginedescription,
      engineaspirationtype,
      fueltype,
      transmissionspeed,
      transmissiondescription,
      extcolorgeneric,
    }
  }
  if (vendor === 'sm360') {
    const { passengercount: passengercapacity, ...rest } = item
    data = {
      ...rest,
      passengercapacity,
    }
  } else {
    data = item
  }
  return <IUnionInventory>data
}
