import React from 'react'

import { PageLoadingWrapper } from '@/components'
import { LeadsContextProvider } from '@/context'

import { LeadsList } from './components'

const LeadsView: React.FC = () => {
  return (
    <PageLoadingWrapper>
      <div className="container mx-auto px-5">
        <LeadsList />
      </div>
    </PageLoadingWrapper>
  )
}

export const LeadsPage: React.FC = () => (
  <LeadsContextProvider>
    <LeadsView />
  </LeadsContextProvider>
)
