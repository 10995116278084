import axios from 'axios'

import {
  API_ENDPOINT,
  AUTH_TOKEN_KEY,
  INTERNAL_API_ENDPOINT,
} from '@/constants'
import { getStorageValue } from '@/utils'
export const apiRequest = axios.create({
  baseURL: `${API_ENDPOINT}`,
})

apiRequest.interceptors.request.use(config => {
  const authToken = getStorageValue(AUTH_TOKEN_KEY)
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }
  return config
})

export const internalApiRequest = axios.create({
  baseURL: `${INTERNAL_API_ENDPOINT}`,
})

internalApiRequest.interceptors.request.use(config => {
  const authToken = getStorageValue(AUTH_TOKEN_KEY)
  if (authToken) {
    // TODO: Add the token to the headers
    config.headers.Authorization = `Bearer mMpkzW3wE8cQh34RLUcjUlmcsB1LFZHop5IroO-5AVWhiPSsyxOtUk1Ixt_FDp07ppwDCjq1uOhF1OUBACBsbb>`
  }
  return config
})
