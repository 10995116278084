import React, { useMemo } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, LoadingSkeleton, Modal } from '@/components'
import { useInventory } from '@/hooks'
import { rem } from '@/utils'
import FileViewer from '@/pages/Inventory/components/fileViewer'
import { IInventoryFile, InventoryType } from '@/types'
import moment from 'moment/moment'
import { DATE_FORMAT } from '@/constants'

interface FileListModalProps {
  isOpen: boolean
  onClose: () => void
  fileNames: IInventoryFile[] | null
  loading: boolean
  vendor: InventoryType | null
}
interface FileListProps {
  isOpen: boolean
  onClose: () => void
  vendor: InventoryType | null
}
const FileListModal = ({
  isOpen,
  onClose,
  fileNames,
  loading,
  vendor,
}: FileListModalProps) => {
  const { getOrganizationFileUrl, fileUrl } = useInventory()

  const data = useMemo(() => {
    return loading ? [...new Array(10).fill(1)] : fileNames || []
  }, [fileNames, loading])

  const onView = (fileName: string) => {
    vendor && getOrganizationFileUrl(fileName, vendor)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="lg:w-[90vw] w-full bg-white h-lvh overflow-y-auto overflow-x-hidden left-0 absolute top-0 py-5 px-10 flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <h2 className="font-semibold text-2xl">List of FTP files</h2>
          <div className="flex items-center gap-4">
            <XMarkIcon
              onClick={onClose}
              className="w-7 h-7 text-black cursor-pointer"
            />
          </div>
        </div>
        <div className="py-6 flex gap-8">
          <div className="flex flex-col w-1/4 h-full overflow-auto mt-14 pr-8 border-r border-gray-100 divide-y divide-gray-100">
            {data.map((file, index) => (
              <div
                key={index}
                className="flex items-center gap-4 justify-between py-3"
              >
                {loading ? (
                  <>
                    <LoadingSkeleton height={rem(24)} width="80%" />{' '}
                    <LoadingSkeleton height={rem(24)} width="10%" />
                  </>
                ) : (
                  <>
                    <div>
                      <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                        {file.name}
                      </p>
                      <p className="text-xs leading-5 text-gray-500 mt-1 truncate">
                        Last modified:{' '}
                        <span>
                          {moment(file.modifiedAt).format(DATE_FORMAT)}
                        </span>
                      </p>
                    </div>
                    <Button
                      className="btn-outline btn-sm max-w-max"
                      onClick={() => {
                        onView(file.name)
                      }}
                    >
                      View
                    </Button>
                  </>
                )}
              </div>
            ))}

            {!loading && fileNames?.length === 0 && (
              <div className="text-center text-gray-500">No files found</div>
            )}
          </div>
          <div className="w-3/4">
            <FileViewer url={fileUrl} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const FileList = ({ isOpen, onClose, vendor }: FileListProps) => {
  const { filesLoading, files, setFileUrl } = useInventory()

  return (
    <FileListModal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setFileUrl(null)
      }}
      fileNames={files}
      loading={filesLoading}
      vendor={vendor}
    />
  )
}

export default FileList
