import React from 'react'
import { useNavigate } from 'react-router-dom'

import { automationImg, dashboardV2Path, generatePath } from '@/utils'
import { useWorkflow } from '@/hooks'
import { Button } from '@/components'

import { useLocalWorkflow } from './'

export const SaveState: React.FC = () => {
  const navigate = useNavigate()
  const { saveState } = useLocalWorkflow()
  const { organization } = useWorkflow()

  return (
    <div className="text-center flex flex-col justify-center items-center">
      <img alt="" width="w-1/2 object-contain" src={automationImg(saveState)} />
      {saveState === 'completed' && (
        <>
          <h4 className="text-sm font-semibold">
            Workflow saved successfully! We'll notify you when the first action
            is performed.
          </h4>
          <Button
            className="btn-primary lg:!w-1/6 !w-1/2 mt-5"
            onClick={() =>
              navigate(generatePath(dashboardV2Path, { id: organization?._id }))
            }
          >
            Go to dashboard
          </Button>
        </>
      )}
    </div>
  )
}
