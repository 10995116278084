import React, { useEffect, useMemo, useState } from 'react'
import { Button, DataTable, ModalDialog } from '@/components'
import { tableHeaders } from './constants'
import {
  Level,
  ParsedPermission,
  PermissionValueTypeWithOrg,
  Resource,
} from '@matador/automations-lib/src/types/permissions'
import {
  levelList,
  parsePermission,
  resourceList,
} from '@matador/automations-lib/src/utils/permissions'
import classNames from 'classnames'
import { useDashboard } from '@/hooks'
import SearchBar from '@/components/SearchBar'
import { IOption, IOrganization } from '@/types'

const permissionLevelBadgeColors = {
  read: 'bg-blue-50 text-blue-700 ring-blue-700/10',
  write: 'bg-green-50 text-green-700 ring-green-600/20',
  admin: 'bg-red-50 text-red-700 ring-red-600/10',
}

const errorDefaultState = {
  resource: '',
  level: '',
  organizations: '',
}

const ListItem = ({
  resource,
  level,
  organizations,
  isDeleted,
  onDelete,
  onEdit,
  onDeleteCancel,
  onShowOrganizations,
  ...rest
}: ParsedPermission & {
  isDeleted: boolean
  onDelete: () => void
  onDeleteCancel: () => void
  onEdit: () => void
  onShowOrganizations: (org: IOrganization[]) => void
}) => {
  const { organizations: orgList } = useDashboard()
  const organizationName = useMemo(() => {
    let name: string | number = ''
    if (organizations[0] === '*') {
      name = 'All'
    } else if (organizations.length === 1) {
      name = orgList.find(org => org._id === organizations[0])?.name || ''
    } else {
      name = organizations.length
    }
    return name
  }, [orgList, organizations])

  const organizationNames = useMemo(() => {
    if (organizations.length > 1) {
      return organizations.map(org => orgList.find(o => o._id === org))
    }
    return []
  }, [orgList, organizations])

  return (
    <tr {...rest} className={classNames({ disabled: isDeleted })}>
      <td>
        <div className="font-mono text-sm leading-6 text-black font-semibold capitalize">
          {resource}
        </div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          <span
            className={classNames(
              'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset capitalize',
              permissionLevelBadgeColors[level],
            )}
          >
            {level}
          </span>
        </div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          {organizations.length > 1 ? (
            <span
              className="text-indigo-600 cursor-pointer"
              // @ts-ignore
              onClick={() => onShowOrganizations(organizationNames)}
            >
              {organizationName}
            </span>
          ) : (
            organizationName
          )}
        </div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          <div className="flex gap-2">
            {!isDeleted && (
              <Button type="button" className="btn-primary" onClick={onEdit}>
                Edit
              </Button>
            )}
            {isDeleted ? (
              <Button
                type="button"
                className="btn-secondary"
                onClick={onDeleteCancel}
              >
                Cancel
              </Button>
            ) : (
              <Button type="button" className="btn-danger" onClick={onDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </td>
    </tr>
  )
}

const AddListItem = ({
  form,
  setForm,
  onSave,
  resourceList,
  levelList,
  organizationList,
  onCancel,
  ...rest
}: {
  form: ParsedPermission
  setForm: React.Dispatch<React.SetStateAction<ParsedPermission | null>>
  onSave: () => void
  resourceList: IOption[]
  levelList: IOption[]
  organizationList: IOption[]
  onCancel: () => void
}) => {
  const [formError, setFormError] = useState(errorDefaultState)

  const onSelectChange = (value: string, key: keyof typeof form) => {
    setForm({
      ...form,
      [key]: value,
    })
  }
  const onOrgSelectChange = (value: string[]) => {
    let newValue = value.includes('*') ? ['*'] : value
    if (form.organizations.includes('*') && value.includes('*')) {
      const index = value.indexOf('*')
      value.splice(index, 1)
      newValue = value
    }
    setForm({
      ...form,
      organizations: newValue,
    })
  }

  const onSubmit = () => {
    setFormError({ ...errorDefaultState })
    const errors = { ...errorDefaultState }

    if (!form.resource) {
      errors.resource = 'Resource is required'
    }
    if (!form.level) {
      errors.level = 'Level is required'
    }
    if (!form.organizations.length) {
      errors.organizations = 'Organization is required'
    }
    if (Object.values(errors).some(val => val)) {
      setFormError(errors)
      return
    }
    onSave()
  }

  return (
    <tr {...rest}>
      <td>
        <SearchBar
          error={formError.resource}
          placeholder="Select resource..."
          options={resourceList}
          onChange={_v => {
            onSelectChange(_v?.value || '', 'resource')
          }}
          value={resourceList.find(r => r.value === form.resource)}
        />
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          <SearchBar
            error={formError.level}
            placeholder="Select level..."
            options={levelList}
            onChange={_v => {
              onSelectChange(_v?.value || '', 'level')
            }}
            value={levelList.find(r => r.value === form.level)}
          />
        </div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          <SearchBar
            isMulti
            error={formError.organizations}
            placeholder="Select Organization..."
            options={organizationList}
            onChange={_v => {
              onOrgSelectChange(_v?.map(r => r.value))
            }}
            value={
              organizationList.filter(r =>
                form.organizations.includes(r.value),
              ) || []
            }
          />
        </div>
      </td>
      <td>
        <Button
          className="btn-secondary max-w-max"
          type="button"
          onClick={() => {
            onCancel()
            setFormError({ ...errorDefaultState })
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary max-w-max"
          type="button"
          onClick={onSubmit}
        >
          Save
        </Button>
      </td>
    </tr>
  )
}

const PermissionFormTable = ({
  data,
  grantAccess,
  onSubmit,
}: {
  data: PermissionValueTypeWithOrg[]
  grantAccess?: string
  onSubmit: (data: ParsedPermission[]) => void
}) => {
  const { organizations: orgList } = useDashboard()

  const [organizationsToShow, setOrganizationsToShow] = useState<
    IOrganization[]
  >([])
  const [tableData, setTableData] = useState<ParsedPermission[]>([])
  const [addPermission, setAddPermission] = useState<ParsedPermission | null>(
    null,
  )
  const [editPermission, setEditPermission] = useState<number | null>(null)
  const [deletedPermissions, setDeletedPermissions] = useState<number[]>([])

  useEffect(() => {
    setTableData(data.map(parsePermission))
  }, [data])

  const onAddPermission = () => {
    setAddPermission({
      resource: '' as Resource,
      level: '' as Level,
      organizations: [],
    })
  }

  useEffect(() => {
    if (editPermission !== null) {
      setAddPermission(tableData[editPermission] as ParsedPermission)
    } else {
      setAddPermission(null)
    }
  }, [editPermission, tableData])
  const submitAddPermission = () => {
    if (editPermission !== null) {
      const newData = tableData.map((el, i) =>
        i === editPermission ? addPermission : el,
      )
      setTableData(newData as ParsedPermission[])
      setEditPermission(null)
      setAddPermission(null)
      return
    }
    setTableData(prev => [...prev, addPermission as ParsedPermission])
    setAddPermission(null)
  }

  const resources = useMemo(() => {
    return resourceList.map(resource => ({
      label: resource,
      value: resource,
      disabled:
        tableData.filter(el => el.resource === resource).length ===
        levelList.length,
    }))
  }, [tableData])

  const levels = useMemo(() => {
    return levelList.map(level => ({
      label: level,
      value: level,
      disabled:
        tableData.filter(el => el.level === level).length ===
          resourceList.length ||
        !!tableData.find(
          el => el.level === level && el.resource === addPermission?.resource,
        ),
    }))
  }, [addPermission?.resource, tableData])

  const organizationList = useMemo(() => {
    return [
      { label: 'All', value: '*' },
      ...orgList.map(org => ({
        label: org.name,
        value: org._id,
      })),
    ]
  }, [orgList])
  return (
    <div className="w-full">
      <div>
        <Button
          disabled={!!addPermission}
          type="button"
          className="btn-secondary max-w-max"
          onClick={() => onAddPermission()}
        >
          Add Permission
        </Button>
      </div>
      <DataTable
        className="!overflow-visible"
        data={tableData}
        headers={tableHeaders}
      >
        {data => (
          <>
            {addPermission && editPermission === null && (
              <AddListItem
                form={addPermission}
                setForm={setAddPermission}
                onSave={submitAddPermission}
                resourceList={resources}
                levelList={levels}
                organizationList={organizationList}
                onCancel={() => setAddPermission(null)}
              />
            )}
            {data.map((permission, index) => {
              return editPermission === index && addPermission ? (
                <AddListItem
                  form={addPermission}
                  setForm={setAddPermission}
                  onSave={submitAddPermission}
                  resourceList={resources}
                  levelList={levels}
                  organizationList={organizationList}
                  onCancel={() => setEditPermission(null)}
                  key={permission.resource + permission.level}
                />
              ) : (
                <ListItem
                  {...permission}
                  key={permission.resource + permission.level}
                  onDelete={() =>
                    setDeletedPermissions([...deletedPermissions, index])
                  }
                  onDeleteCancel={() =>
                    setDeletedPermissions(
                      deletedPermissions.filter(i => i !== index),
                    )
                  }
                  isDeleted={deletedPermissions.includes(index)}
                  onEdit={() => setEditPermission(index)}
                  onShowOrganizations={setOrganizationsToShow}
                />
              )
            })}
          </>
        )}
      </DataTable>
      <ModalDialog
        onClose={() => setOrganizationsToShow([])}
        isOpen={organizationsToShow.length > 0}
        title="Organizations"
      >
        <div className="flex flex-col gap-1">
          {organizationsToShow.map(org => (
            <div className="font-semibold">{org.name}</div>
          ))}
        </div>
      </ModalDialog>
      <Button
        type="button"
        className="btn-primary max-w-max mr-auto"
        onClick={() =>
          onSubmit(
            tableData.filter((_, index) => !deletedPermissions.includes(index)),
          )
        }
      >
        {grantAccess ? 'Create' : 'Save'}
      </Button>
    </div>
  )
}

export default PermissionFormTable
