import React from 'react'

import { Button } from '@/components'

import { useLocalWorkflow } from './'

export const Confirmation: React.FC = () => {
  const { onSave } = useLocalWorkflow()
  return (
    <>
      <h3 className="font-semibold text-lg pb-3">Appointment confirmations</h3>

      <div className="flex flex-col lg:flex-row justify-between">
        <h5>
          You can set up the appointment messages in the location's settings on
          Matador.
        </h5>

        <Button
          onClick={onSave}
          className="btn-primary mt-2 lg:!w-1/6 !w-1/2 self-end"
        >
          Confirm and save workflow
        </Button>
      </div>
    </>
  )
}
