import React, { useEffect, useMemo, useState } from 'react'
import HTTPClientInternal from '../httpClient'
import {
  Badge,
  Button,
  DataTable,
  Form,
  ModalDialog,
  Select,
  TableLoadingSkeleton,
  TextInput,
} from '@/components'
import { headers, modalFormFields } from '@/pages/A2p/components/constants'
import { TA2pStatus, TTaskStatus } from '@/types'
import moment from 'moment/moment'
import { DATE_FORMAT } from '@/constants'
import { useAuth, useToast } from '@/hooks'
import { onError } from '@/apis'
import { forEach } from 'lodash'

interface IA2pWorkflow {
  _id: string
  message: string
  name: string
  status: TA2pStatus
  createdAt: string
}

export default function PendingForms() {
  const [loading, setLoading] = useState(true)

  const [workflowForms, setWorkflowForms] = useState<IA2pWorkflow[]>([])
  const [errors, setErrors] = useState<any>({})
  const { addToast } = useToast()

  useEffect(() => {
    HTTPClientInternal(
      `/workflows?names[]=a2p_registration&status[]=paused&status[]=failed&status[]=created`,
      'GET',
    )
      .then(res => {
        setWorkflowForms(res.data)
      })
      .catch(err => {
        throw JSON.stringify(err)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
  }, [])

  const [workflow, setWorkflow] = useState<any>({})
  const [form, setForm] = useState<any>({})

  const onSelect = (workflow: any) => {
    setWorkflow(workflow)
  }

  const onSetForm = (form: any) => {
    setForm(form)
  }

  const onModalClose = () => {
    setForm({})
  }

  const { accesses } = useAuth()

  const headerList = useMemo(() => {
    if (!accesses.ONBOARDING_WRITE) {
      return headers.filter(h => h.key !== 'action')
    }
    return headers
  }, [accesses.ONBOARDING_WRITE])

  return (
    <div className={'card'}>
      <div className={'card-body'}>
        <DataTable data={workflowForms} headers={headerList}>
          {data =>
            loading ? (
              <TableLoadingSkeleton className="py-4" rows={10} cols={5} />
            ) : (
              data.map(datum => (
                <ListItem
                  onSelect={onSelect}
                  onSetForm={onSetForm}
                  key={datum._id}
                  {...datum}
                />
              ))
            )
          }
        </DataTable>
      </div>

      <ModalDialog
        title={workflow?.viewName}
        isOpen={!!Object.keys(form).length}
        onClose={onModalClose}
      >
        <div className="w-full left-0 top-0">
          <div className="modal-body">
            <Form
              id={'update-form'}
              onSubmit={e => {
                e.preventDefault()
                const keys = Object.keys(form)
                const errorList: any = {}
                forEach(keys, key => {
                  if (form[key] === '') {
                    errorList[key] = 'Field is required'
                  }
                })
                setErrors(errorList)
                const errorKeys = Object.keys(errorList)
                if (errorKeys.length) {
                  document
                    .querySelector(`[name="${errorKeys[0]}"]`)
                    ?.scrollIntoView()
                  return
                }
                HTTPClientInternal(`/workflows/${workflow._id}`, 'PUT', {
                  form,
                })
                  .then(() => {
                    addToast('success', 'Form Updated succesfully')
                  })
                  .catch(error => {
                    if (error?.message) addToast('error', error.message)
                    onError(error)
                  })
                  .finally(() => {
                    onModalClose()
                  })
              }}
            >
              {modalFormFields.map(field => {
                return field.allowedValues ? (
                  <div className={'form-group'} id="form-body" key={field.slug}>
                    <Select
                      error={errors[field.slug]}
                      placeHolder="Select"
                      className="w-full block mt-2"
                      allowEmpty
                      label={field.slug}
                      value={form[field.slug]}
                      onChange={option =>
                        setForm({
                          ...form,
                          [field.slug]: option?.value,
                        })
                      }
                      options={
                        field.allowedValues?.map(_v => ({
                          value: _v,
                          label: _v,
                        })) || []
                      }
                    />
                  </div>
                ) : (
                  <div className="form-group" key={field.slug}>
                    <TextInput
                      error={errors[field.slug]}
                      type="text"
                      label={field.slug}
                      className="block w-full mt-2"
                      onChange={e =>
                        setForm({
                          ...form,
                          [field.slug]: e.target.value,
                        })
                      }
                      value={form[field.slug] || ''}
                      placeholder={field.defaultValue || field.defaultValue}
                      required
                      name={field.slug}
                    />
                  </div>
                )
              })}
              <div className="flex items-center justify-end gap-5 mt-8">
                <Button
                  type="button"
                  className="btn btn-secondary max-w-max"
                  onClick={onModalClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-primary  max-w-max">
                  Save changes
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </ModalDialog>
    </div>
  )
}

export const ListItem: React.FC<
  IA2pWorkflow & {
    onSelect: (workflow: IA2pWorkflow) => void
    onSetForm: (form: IA2pWorkflow) => void
  }
> = ({ onSelect, onSetForm, message, _id, status, createdAt, ...rest }) => {
  const { accesses } = useAuth()
  const currentStatus = useMemo(() => {
    if (status === 'failed') {
      return TTaskStatus.FAILED
    }
    if (status === 'completed') {
      return TTaskStatus.COMPLETED
    }
    return TTaskStatus.PENDING
  }, [status])
  return (
    <tr className="!align-top" {...rest}>
      <td>{_id}</td>
      <td>
        <Badge label={status} status={currentStatus} clickable={false} />
      </td>
      <td className="max-w-xl !whitespace-normal">{message}</td>
      <td>{moment(createdAt).format(DATE_FORMAT)}</td>
      {accesses.ONBOARDING_WRITE && (
        <td>
          <Button
            className="btn btn-primary"
            onClick={() => {
              HTTPClientInternal(`/workflows/${_id}`, 'GET')
                .then(res => {
                  onSelect(res.data)
                  onSetForm(res.data.form)
                })
                .catch(err => {
                  console.log(err)
                  throw JSON.stringify(err)
                })
            }}
          >
            Update
          </Button>
        </td>
      )}
    </tr>
  )
}
