import React, { useMemo, useState } from 'react'
import { JSONTree } from 'react-json-tree'
import {
  Badge,
  Button,
  DataTable,
  TableLoadingSkeleton,
  WithPaginator,
  ModalDialog,
} from '@/components'
import { tableHeaders } from './'
import { useOrganization } from '@/hooks'
import { IRawPayload, IRepairOrder, TTaskStatus } from '@/types'
import { Filters } from './filters'
import classNames from 'classnames'
import moment from 'moment/moment'
import { DATE_FORMAT } from '@/constants'

interface IWorkflow {
  _id: string
  name: string
}

export const RepairOrdersTab = () => {
  const [payload, setPayload] = useState<IRawPayload>()
  const [workflows, setWorkflows] = useState<IWorkflow[]>()
  const showPayloadModal = (data?: IRawPayload) => {
    setPayload(data)
  }

  const hidePayloadModal = () => {
    setPayload(undefined)
  }

  const showWorkflowsModal = (workflows: IWorkflow[]) => {
    setWorkflows(workflows)
  }

  const hideWorkflowsModal = () => {
    setWorkflows(undefined)
  }
  const {
    repairOrdersResponse,
    onUpdateRepairOrdersFilter,
    onResetRepairOrdersFilter,
    repairOrdersFilter,
  } = useOrganization()

  const { data, pageMeta, total, onPageMetaChange, loading } =
    repairOrdersResponse

  const pageData: IRepairOrder[] = useMemo(() => {
    if (loading) return [...new Array(10).fill(1)]
    return data[pageMeta.page] ?? []
  }, [data, loading, pageMeta.page])
  return (
    <>
      <Filters
        onUpdate={onUpdateRepairOrdersFilter}
        onReset={onResetRepairOrdersFilter}
        filters={repairOrdersFilter}
      />
      <div className="tab-content">
        <WithPaginator
          data={pageData}
          totalItems={total}
          loading={loading}
          initialPage={pageMeta.page}
          initialPageSize={pageMeta.pageSize}
          onPageMetaChange={onPageMetaChange}
        >
          {pagedData => (
            <>
              <DataTable data={pagedData} headers={tableHeaders}>
                {data =>
                  loading ? (
                    <TableLoadingSkeleton
                      rows={10}
                      className="py-4"
                      cols={tableHeaders.length}
                    />
                  ) : (
                    data.map((datum, idx) => (
                      <ListItem
                        showPayloadModal={showPayloadModal}
                        showWorkflowsModal={showWorkflowsModal}
                        key={idx}
                        {...datum}
                      />
                    ))
                  )
                }
              </DataTable>
            </>
          )}
        </WithPaginator>
        <ModalDialog
          onClose={hidePayloadModal}
          isOpen={!!payload}
          title="Payload"
          footer={
            <Button
              className="btn-secondary max-w-max"
              onClick={hidePayloadModal}
            >
              Close
            </Button>
          }
        >
          <div className="p-4">
            {payload ? (
              <JSONTree
                data={payload}
                hideRoot
                shouldExpandNodeInitially={(keyPath, data, level) => {
                  return level < 2
                }}
              />
            ) : (
              'No payload found'
            )}
          </div>
        </ModalDialog>
        <ModalDialog
          onClose={hideWorkflowsModal}
          isOpen={!!workflows}
          title="Workflows Used"
          footer={
            <Button
              className="btn-secondary max-w-max"
              onClick={hideWorkflowsModal}
            >
              Close
            </Button>
          }
        >
          <div className="flex items-center gap-4">
            {workflows?.map(workflow => (
              <Badge
                status={TTaskStatus.PENDING}
                label={workflow.name}
                key={workflow._id}
              />
            ))}
          </div>
        </ModalDialog>
      </div>
    </>
  )
}

export const ListItem: React.FC<
  Partial<IRepairOrder> & {
    showPayloadModal: (data?: IRawPayload) => void
    showWorkflowsModal: (workflows: IWorkflow[]) => void
  }
> = ({ showPayloadModal, showWorkflowsModal, ...item }) => {
  return (
    <tr className="my-2">
      <td>
        <div className="flex items-center gap-x-4">{item?.externalId}</div>
      </td>
      <td>
        <Badge key={item.crm} status={TTaskStatus.PENDING} label={item.crm} />
      </td>
      <td>
        <span
          className={classNames('text-indigo-600 font-semibold', {
            'cursor-pointer': item.excludedWorkflows?.length,
          })}
          onClick={() =>
            item.excludedWorkflows?.length &&
            showWorkflowsModal(item.excludedWorkflows)
          }
        >
          {item.excludedWorkflows?.length ?? '-'}
        </span>
      </td>
      <td>{moment(item.createdAt).format(DATE_FORMAT)}</td>
      <td>
        <Button
          className="btn-primary max-w-max"
          onClick={() => showPayloadModal(item?.rawPayloads?.[0])}
        >
          View Payload
        </Button>
      </td>
    </tr>
  )
}
