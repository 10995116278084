import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { Button } from '@/components'

import { IAccordionProps } from './types'
import './styles.scss'

export const Accordion: React.FC<IAccordionProps> = ({
  items,
  multiselect = true,
  reset = undefined,
  defaultOpen,
}) => {
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const refs = useRef<HTMLHeadingElement[]>([])
  const onSelect = useCallback(
    (idx: number) => () => {
      setSelected(prev =>
        multiselect ? { ...prev, [idx]: !prev[idx] } : { [idx]: !prev[idx] },
      )
    },
    [multiselect],
  )

  useEffect(() => {
    if (reset) {
      setSelected({})
    }
  }, [reset])

  useEffect(() => {
    if (defaultOpen !== undefined) {
      setSelected(prev => ({ ...prev, [defaultOpen]: true }))
      setTimeout(() => {
        refs.current[defaultOpen]?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }, [defaultOpen])

  return (
    <div className="accordion-collapse">
      {items.map((item, idx, arr) => (
        <React.Fragment key={idx}>
          <h2 ref={ref => ref && (refs.current[idx] = ref)}>
            <Button
              type="button"
              onClick={onSelect(idx)}
              className={classNames('accordion-header', {
                active: selected[idx],
                'border-b-0': !!arr[idx + 1],
              })}
            >
              {item.header}
              <svg
                fill="none"
                className="icon"
                viewBox="0 0 10 6"
                aria-hidden="true"
                data-accordion-icon
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeWidth="2"
                  d="M9 5 5 1 1 5"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </h2>
          <div
            className={classNames({
              hidden: !selected[idx],
            })}
          >
            <div
              className={classNames('accordion-body', {
                active: !!arr[idx + 1],
              })}
            >
              {item.body}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
