import React, { useCallback, useMemo } from 'react'
import { capitalize } from 'lodash'
import moment from 'moment'

import {
  TFilters,
  TTaskStatus,
  IFilterProps,
  IOrganization,
  IFilterResponse,
} from '@/types'
import { DatePicker, Select, Button } from '@/components'
import { statusFilter } from '@/constants'
import { useDashboard } from '@/hooks'

import { filters as AutomationFilters } from './constants'

import classNames from 'classnames'
import SearchBar from '@/components/SearchBar'

import './styles.scss'

export const AutomationTypeFilter: React.FC<IFilterProps<TFilters>> = ({
  filters,
  onUpdate,
}) => {
  return (
    <Select
      options={AutomationFilters.map(tab => ({
        value: tab.key,
        label: tab.name,
      }))}
      value={filters.automationType}
      onChange={option => onUpdate({ automationType: option.value })}
    />
  )
}

export const Filters: React.FC<
  IFilterProps<TFilters> & { hideOrganization?: boolean }
> = ({ filters, onReset, onUpdate, hideOrganization = false }) => {
  const { crms, locations, organizations } = useDashboard()

  const onSelectFilter = useCallback(
    (field: keyof TFilters) => (value?: any) => {
      let _value = value
      if (['startDate', 'endDate'].includes(field) && _value) {
        _value = moment(_value).toISOString()
      }
      onUpdate({ [field]: _value })
    },
    [onUpdate],
  )

  const selectMapper = useCallback((data: IOrganization | IFilterResponse) => {
    return { value: data._id, label: capitalize(data.name) }
  }, [])

  const locationList = useMemo(() => {
    if (!filters.organizationIds?.length) return []
    else {
      return locations.filter(location =>
        filters.organizationIds?.includes(location._organization_id),
      )
    }
  }, [filters.organizationIds, locations])

  const organizationList = useMemo(() => {
    return organizations.map(selectMapper)
  }, [organizations, selectMapper])

  return (
    <div className="flex flex-col xl:flex-row gap-x-8 gap-y-4 py-5 items-end justify-between">
      <div
        className={classNames('grid w-full gap-5 items-center', {
          'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6':
            !hideOrganization,
          'sm:grid-cols-3 grid-col-1': hideOrganization,
        })}
      >
        {!hideOrganization && (
          <>
            <SearchBar
              isMulti
              placeholder="Select Organization..."
              className="w-full"
              label="Organizations"
              options={organizationList}
              onChange={_v => {
                // @ts-ignore
                onSelectFilter('organizationIds')(_v.map(d => d.value))
              }}
              value={
                organizationList.filter(r =>
                  filters?.organizationIds?.includes(r.value),
                ) || []
              }
            />
            <Select
              label="Locations"
              className="w-full"
              disabled={!filters.organizationIds?.length}
              value={filters.locationId}
              placeHolder="Select Location..."
              options={locationList.map(selectMapper)}
              onChange={option => onSelectFilter('locationId')(option.value)}
            />
            <Select
              label="CRMs"
              className="w-full"
              value={filters.crm}
              placeHolder="Select CRM..."
              options={crms.map(selectMapper)}
              onChange={option => onSelectFilter('crm')(option.value)}
            />
          </>
        )}
        <Select<TTaskStatus>
          label="Status"
          className="w-full"
          value={filters.status}
          options={statusFilter}
          placeHolder="Select Status..."
          onChange={option => onSelectFilter('status')(option.value)}
        />
        <DatePicker
          label="Start Date"
          className="w-full"
          placeholder="Select start date"
          onChange={_value => {
            onSelectFilter('startDate')(_value)
            const el = document.querySelectorAll(
              '.popover-trigger',
            )[1] as HTMLElement
            el?.click()
          }}
          maxDate={filters.endDate ? new Date(filters.endDate) : undefined}
          value={filters.startDate ? new Date(filters.startDate) : undefined}
        />
        <DatePicker
          label="End Date"
          className="w-full"
          placeholder="Select end date"
          onChange={onSelectFilter('endDate')}
          value={filters.endDate ? new Date(filters.endDate) : undefined}
          minDate={filters.startDate ? new Date(filters.startDate) : undefined}
        />
      </div>
      <div className="lg:w-1/6">
        <Button onClick={onReset} className="btn-primary !py-[9px]">
          Reset filters
        </Button>
      </div>
    </div>
  )
}
