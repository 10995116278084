import { endpoints, usePaginatedRequest } from '@/apis'
import { ILead, ILeadsFilter } from '@/types'

export const useLeads = ({
  enabled,
  refreshKey,
  ...rest
}: Partial<ILeadsFilter> & {
  enabled: boolean
  refreshKey?: string
}) => {
  const response = usePaginatedRequest<ILead>({
    params: {
      queryParams: Object.keys(rest)
        // @ts-ignore
        .filter(key => !!rest[key])
        // @ts-ignore
        .map(key => ({ [key]: `${rest[key]}` })),
    },
    enabled,
    refreshKey,
    path: endpoints.allLeads,
  })

  return response
}
