import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { PopoverContextProvider } from '@/context'
import { ConditionalWrapper } from '../Wrapper'

import { IPopoverProps, IPopoverAction } from './types'
import { SlideInOut } from './slideInOut'
import Trigger from './trigger'
import Content from './content'
import './styles.scss'

export const Popover = forwardRef<IPopoverAction, IPopoverProps>(
  (
    {
      className,
      contentClassName,
      disabled,
      initialOpen,
      button,
      placement,
      positioning = 'absolute',
      hasArrow = true,
      closeOnOutside = true,
      distance = 0,
      trigger = 'on-click',
      WrapEl = 'div',
      onOpened,
      onClosed,
      children,
    },
    ref,
  ) => {
    const [open, setOpen] = useState<boolean | undefined>()
    const [contentVisible, setContentVisibility] = useState<
      boolean | undefined
    >()

    useEffect(() => {
      if (initialOpen !== undefined) {
        setOpen(initialOpen)
        setContentVisibility(initialOpen)
      }
    }, [initialOpen])

    useEffect(() => {
      if (open === undefined) return

      if (open) {
        onOpened?.()
      } else {
        onClosed?.()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useImperativeHandle(ref, () => ({
      toggle: (_val: boolean) => {
        setOpen(_val)
      },
    }))

    const showPopover = useCallback(() => {
      if (open) return
      setContentVisibility(true)
      _.delay(setOpen, 50, true)
    }, [open])

    const hidePopover = useCallback(() => {
      setContentVisibility(false)
      _.delay(setOpen, 200, false)
    }, [])

    return (
      <PopoverContextProvider
        closeOnOutside={closeOnOutside}
        onClose={hidePopover}
        placement={placement}
        distance={distance}
      >
        <ConditionalWrapper
          className={classNames('popover-wrapper', className, { open: !!open })}
          WrapEl={WrapEl}
          {...(WrapEl
            ? {
                onMouseLeave: () =>
                  trigger === 'mouse-over' ? hidePopover() : null,
              }
            : {})}
        >
          <Trigger
            trigger={trigger}
            disabled={!!disabled}
            onTrigger={showPopover}
          >
            {button}
          </Trigger>

          {open && (
            <Content
              className={classNames(contentClassName, {
                visible: contentVisible,
              })}
              positioning={positioning}
            >
              <SlideInOut
                show={open && contentVisible}
                duration={200}
                placement={placement}
              >
                {hasArrow && <div className="arrow-container" />}
                {typeof children === 'function'
                  ? children(hidePopover)
                  : children}
              </SlideInOut>
            </Content>
          )}
        </ConditionalWrapper>
      </PopoverContextProvider>
    )
  },
)

export type IPopover = React.ElementRef<typeof Popover> | null
