export const vendors = [
  { label: 'Homenet', value: 'homenet' },
  { label: 'vAuto', value: 'vauto' },
  { label: 'SM360', value: 'sm360' },
  { label: 'Dealersync', value: 'dealersync' },
  { label: 'CarsForSales', value: 'carsforsales' },
  { label: 'DealerSpike', value: 'dealerspike' },
  { label: 'Edealer', value: 'edealer' },
  { label: 'Vincue', value: 'vincue' },
  { label: 'Serti', value: 'serti' },
  { label: 'AutoSweet', value: 'autosweet' },
].sort((a, b) => a.label.localeCompare(b.label))
