import React from 'react'
import { Feedback } from '@/components'
import { useLocation } from '@/hooks'
import { lockedLocationPath } from '@/utils'
import { useNavigate } from 'react-router-dom'

const LockedLocationBanner = () => {
  const navigate = useNavigate()

  const { lockedLocations } = useLocation()
  const onAction = () => {
    navigate(lockedLocationPath)
  }
  return lockedLocations?.length > 0 ? (
    <Feedback
      status="error"
      title={`You have ${lockedLocations.length} locked location${lockedLocations.length > 1 ? 's' : ''} and need action`}
      onAction={onAction}
    />
  ) : null
}

export default LockedLocationBanner
