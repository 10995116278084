import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { TextInput } from '@/components'

interface IDealerSocketUUIDProps {
  value: string
  label?: string
  onChange?: (_value: string) => void
}
export const DealerSocketUUID: React.FC<IDealerSocketUUIDProps> = ({
  value,
  label,
  onChange,
}) => {
  const [uuid, setUuid] = useState(value)

  useEffect(() => {
    if (value) setUuid(value)
    else {
      const id = uuidv4()
      setUuid(id)
      onChange?.(id)
    }
  }, [onChange, value])

  return (
    <TextInput label={label} className="block w-full" value={uuid} disabled />
  )
}
