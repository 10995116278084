import React, { useCallback, useEffect, useState } from 'react'

import { useGetWorkflows } from '@/apis'
import { Select } from '@/components'
import { IResponse, IWorkflowListItem } from '@/types'

interface IWorkflowSelectProps {
  value: string
  label?: string
  locationId?: string
  onChange: (_e: string) => void
}
export const WorkflowSelect: React.FC<IWorkflowSelectProps> = ({
  label,
  value,
  onChange,
  locationId,
}) => {
  const [workflows, setWorkflows] = useState<IWorkflowListItem[]>([])
  const onGetWorkflowsSuccess = useCallback(
    (data: IResponse<IWorkflowListItem[]>) => {
      setWorkflows(data.data ?? [])
    },
    [],
  )
  const [getWorkflows] = useGetWorkflows(onGetWorkflowsSuccess)

  const onGetWorkflows = useCallback(() => {
    if (!locationId) return
    getWorkflows({
      pathParams: {
        id: locationId,
      },
    })
  }, [getWorkflows, locationId])

  useEffect(() => {
    onGetWorkflows()
  }, [onGetWorkflows])

  const [workflowId, setWorkflowId] = useState<string | undefined>()

  useEffect(() => {
    if (value) setWorkflowId(value)
  }, [value])

  return (
    <Select
      label={label}
      options={
        workflows?.map((workflow: IWorkflowListItem) => ({
          label: workflow.name,
          value: workflow._id,
        })) || []
      }
      value={workflowId}
      allowEmpty={true}
      className="block w-full"
      placeHolder="Select Workflow"
      onChange={option => {
        onChange(option?.value || '')
        setWorkflowId(option?.value)
      }}
    />
  )
}
