import { internalApiRequest } from '@/apis'
import { INTERNAL_API_ENDPOINT } from '@/constants'

export default function HTTPClientInternal(
  path: string,
  method: 'GET' | 'POST' | 'PUT',
  data?: any,
  headers?: any,
) {
  return internalApiRequest({
    method,
    url: INTERNAL_API_ENDPOINT + path,
    data,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
  })
}
