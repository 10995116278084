import { AxiosResponse } from 'axios'

import {
  ITask,
  IResponse,
  IPBSDealsType,
  IAutomationData,
  ICheckAccessResponse,
  ISingOrganizationType,
  IRevealPasswordResponse,
  IOrganizationDataResponse,
  ISingleOrganizationPayload,
  IAutomationMutationPayload,
  ICredentialMutationPayload,
  ISettings,
  IPingTestResponse,
  IUserResponse,
  IOrganizationSettingsResponse,
  IMedia,
  IWorkflowListItem,
} from '@/types'
import { endpoints, useLazyGet, usePost, useUpload } from '@/apis'
import { generatePath } from '@/utils'
import { permissionType } from '@matador/automations-lib/src/types/permissions'

export const useCheckAccess = (
  onSuccess?: (_data: ICheckAccessResponse) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, ICheckAccessResponse>(
    endpoints.hasAccess,
    undefined,
    {
      onSuccess,
    },
  )
}
export const useSingleOrganization = () => {
  return usePost<ISingleOrganizationPayload, ISingOrganizationType>(
    endpoints.singleOrganization,
    undefined,
    undefined,
  )
}

export const useAutomations = (
  onSuccess?: (_data: AxiosResponse<IAutomationData[]>) => void,
) => {
  return usePost<ISingleOrganizationPayload, IAutomationData[]>(
    endpoints.automations,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useAppointments = (
  onSuccess?: (_data: AxiosResponse<IOrganizationDataResponse>) => void,
) => {
  return usePost<ISingleOrganizationPayload, IOrganizationDataResponse>(
    endpoints.organizationAppointments,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useTasks = (
  onSuccess?: (_data: AxiosResponse<ITask[]>) => void,
) => {
  return usePost<ISingleOrganizationPayload, ITask[]>(
    endpoints.organizationTasks,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useOrganizationLeads = (
  onSuccess?: (_data: AxiosResponse<IOrganizationDataResponse>) => void,
) => {
  return usePost<ISingleOrganizationPayload, IOrganizationDataResponse>(
    endpoints.organizationLeads,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useCredentials = (
  onSuccess?: (_data: AxiosResponse<IOrganizationDataResponse>) => void,
) => {
  return usePost<ISingleOrganizationPayload, IOrganizationDataResponse>(
    endpoints.organizationsCredentials,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useOrganizationWorkflows = (
  onSuccess?: (_data: IResponse<any[]>) => void,
) => {
  return useLazyGet<undefined, IResponse<any[]>>(
    endpoints.organizationWorkflows,
    undefined,
    { onSuccess },
  )
}

export const useDeleteAutomation = () => {
  return usePost<ISingleOrganizationPayload>(
    endpoints.deleteAutomation,
    undefined,
    undefined,
  )
}

export const useToggleAutomation = () => {
  return usePost<ISingleOrganizationPayload>(
    endpoints.toggleAutomation,
    undefined,
    undefined,
  )
}

export const useGetLocationCredentials = (
  onSuccess?: (_data: IPBSDealsType) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, IPBSDealsType>(
    endpoints.getLocationCredentials,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useGetWorkflows = (
  onSuccess?: (_data: IResponse<IWorkflowListItem[]>) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, IResponse<IWorkflowListItem[]>>(
    endpoints.getWorkflow,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useUploadImage = () => {
  return useUpload<{ image: File | null }, IResponse<IMedia | null>>(
    endpoints.uploadImage,
    undefined,
    undefined,
  )
}

export const useAddNewAutomation = () => {
  return usePost<IAutomationMutationPayload, string>(
    endpoints.addAutomation,
    undefined,
    undefined,
    false,
  )
}

export const useEditAutomation = () => {
  return usePost<IAutomationMutationPayload, string>(
    endpoints.editAutomation,
    undefined,
    undefined,
    false,
  )
}

export const useCredentialsMutation = () => {
  return usePost<ICredentialMutationPayload>(
    endpoints.credentialsMutation,
    undefined,
    undefined,
  )
}

export const useRevealPassword = (id: string) => {
  return usePost<ISingleOrganizationPayload, IRevealPasswordResponse>(
    generatePath(endpoints.revealPassword, { id }),
    undefined,
    undefined,
  )
}

export const useCRMSettings = (
  onSuccess?: (_data: IResponse<ISettings[]>) => void,
) => {
  return useLazyGet(endpoints.crmSettings, undefined, {
    onSuccess,
  })
}

export const usePingStatus = () => {
  return usePost<ISingleOrganizationPayload, IPingTestResponse>(
    endpoints.automationPingTest,
    undefined,
    undefined,
  )
}

export const useUsersByPermissions = () => {
  return usePost<
    ISingleOrganizationPayload & { permissions: permissionType[] },
    IUserResponse
  >(endpoints.usersByPermissions, undefined)
}

export const useOrganizationSettings = (
  onSuccess?: (_data: IResponse<IOrganizationSettingsResponse>) => void,
) => {
  return useLazyGet<
    ISingleOrganizationPayload,
    IResponse<IOrganizationSettingsResponse>
  >(endpoints.organizationSettings, undefined, { onSuccess })
}

export const useAssignUsers = () => {
  return usePost<
    { assignedUsers: string[]; params: { organization: string } },
    IOrganizationSettingsResponse
  >(endpoints.organizationAssignUsers, undefined, undefined)
}
